/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setArExportDialog,
  setArViewType,
  setMultiPaymentItems,
} from "../../../store/ArAging/actions";
import { buttons } from "./utils/displayColumns";
import { formatAmount } from "../../../utils/helper/cellFormatter";

export const ArFilters = ({
  multiSelect,
  setMultiSelect,
  setDate,
  setSelectedCorporation,
  corporation,
  date,
  dateType,
  setDateType,
  selectFilter,
  setSelectFilter,
}: any) => {
  const corporations = useSelector(
    (state: any) => state.arAgingReducer.corporations
  );
  const dispatch = useDispatch();
  const multiPaymentItems = [
    { label: "0-30 days", value: "1-30 Days" },
    { label: "31-60 days", value: "31-60 Days" },
    { label: "61-90 days", value: "61-90 Days" },
    { label: "Over 90 days", value: "Over 90 Days" },
  ];
  const arData = useSelector((state: any) => state.arAgingReducer.arData);
  const [balance, setBalance] = useState("$0");

  useEffect(() => {
    const total = arData
      .reduce((acc: any, curr: any) => {
        return acc + (curr["Balance"] ?? 0);
      }, 0)
      .toFixed(2);
    setBalance(formatAmount(total));
  }, [arData]);

  const changeDateType = (val: string) => {
    setDateType(val);
  };

  const handleSetMultiSelect = async (val: string) => {
    if (hasMultiSelect(val)) {
      setMultiSelect(multiSelect.filter((v: string) => v !== val));
    } else {
      setMultiSelect([...multiSelect, val]);
    }
  };

  const hasMultiSelect = (val: string) => multiSelect.includes(val);

  useEffect(() => {
    dispatch(setArViewType(selectFilter));
  }, [selectFilter]);

  return (
    <section
      className={
        "ar-filter-section xl:h-[70px] h-fit flex flex-col xl:flex-row gap-1 justify-between xl:items-center my-2"
      }
    >
      <div className="flex md:w-[42%] w-full">
        <div className="ar-selects w-[75%]">
          <div className="corporation-select ar-select w-[49%]">
            <label>Corporation</label>
            <select
              value={corporation.toString()}
              name="ar-corporation"
              onChange={(e) => setSelectedCorporation(e.target.value)}
            >
              {corporations.map((corp: any, index: number) => (
                <option key={index} value={corp.organization}>
                  {corp.organization}
                </option>
              ))}
            </select>
          </div>
          <div className="corporation-select ar-select w-[49%]">
            <div className="flex justify-between align-center">
              <label>As of date</label>
              <div
                className={"pointer ar-date-reset-button"}
                onClick={() => setDate(new Date())}
              >
                <abbr title={"Reset Date"}>
                  {" "}
                  <i className="material-icons" style={{ fontSize: "14px" }}>
                    restart_alt
                  </i>
                </abbr>
              </div>
            </div>
            <input
              type="date"
              value={moment(date).format("YYYY-MM-DD")}
              onChange={(e) => setDate(e.target.value)}
              max={moment(new Date()).format("YYYY-MM-DD")}
              pattern="\d{4}-\d{2}-\d{2}"
            />
          </div>
        </div>
        <div className="ar-date-type-selects w-[25%]">
          {["Invoice Date", "Due Date"].map((type, index) => (
            <button
              key={index}
              className={
                "date-type-select " + (dateType === type ? "active" : "")
              }
              onClick={() => changeDateType(type)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full xl:w-[58%]">
        <div className="ar-column-types-buttons w-[90%]">
          {buttons.map((button, index) => (
            <div
              className={`ar-column-types-button pointer ${
                selectFilter === button ? "active" : ""
              } ${
                button.toLowerCase() === "multi payment terms" ? "dropdown" : ""
              }`}
              onClick={() => setSelectFilter(button)}
              key={index}
            >
              {button}

              {button.toLowerCase() === "multi payment terms" && (
                <div className="dropdown-content">
                  {multiPaymentItems.map((item, index) => (
                    <div
                      className={`dropdown-content-item ${
                        hasMultiSelect(item.value) ? "active" : ""
                      }`}
                      onClick={() => handleSetMultiSelect(item.value)}
                      key={index}
                    >
                      <div className="dropdown-content-icon">
                        <i className="material-icons">check_circle</i>
                      </div>
                      <label>{item.label}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className="total-balance-box text-center">
            <div className="total-balance-label">Total Balance</div>
            <div className="total-balance-amount">{balance}</div>
          </div>
        </div>
        <div className="export-data-section w-[10%]">
          <button onClick={() => dispatch(setArExportDialog(true))}>
            Export Data
          </button>
        </div>
      </div>
    </section>
  );
};
