/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { PostRequest } from "../plugins/https";

export const APIGetWeeklyData = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}`, data, {
    params,
  });
};
export const APIGetWeeklyDataSecond = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/second`, {}, { params });
};

export const APIGetWeeklyDataThird = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/third`, {}, { params });
};

export const APIGetWeeklyManagers = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/manager`, data, {
    params,
  });
};

//ADA Level
export const APIGetADAWeeklyData = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/ada`, data, {
    params,
  });
};
export const APIGetADAWeeklyDataSecond = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/ada-second`, {}, { params });
};

export const APIGetADAWeeklyDataThird = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/ada-third`, {}, { params });
};

export const APIGetADAWeeklyManagers = (data: any, params?: any) => {
  return PostRequest(`/weekly-report/${data.type}/ada-manager`, data, {
    params,
  });
};
