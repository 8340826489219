import { SegmentedControl, Table } from "@mantine/core";
import TableRow from "./TableRow";
import { groupByKey } from "../../../utils/group";
import { useEffect, useState } from "react";
import { ContentLoader } from "../../common/Loader";

const GPTable = (props: any) => {
  const { selectedYear, setSelectedYear, filteredGpData, months } = props;
  const [formattedData, setFormattedData] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any>(formattedData);
  const tableHeaders = [
    "Sales Manager",
    "Branch(s)",
    "Tenure (Year,Month,Day)",
  ];
  const tableMonthlyHeaders = [
    { label: "MTD", value: "MTD", bgColor: "bg-[#bfeebe]" },
    { label: "QGP", value: "QGP", bgColor: "bg-[#c0b1fc]" },
    { label: "NQGP", value: "NQGP", bgColor: "bg-[#d9cafc]" },
    // { label: "13 Weeks", value: "13 weeks GP", bgColor: "bg-[#d8d7d9]" },
    { label: "YTD", value: "YTD", bgColor: "bg-[#c4e3ff]" },
  ];
  const [table1Loading, setTable1Loading] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    month: string;
    direction: string;
  } | null>({
    key: "YTD",
    month: months[months.length - 1],
    direction: "desc",
  });

  // useEffect(() => {
  //   setTable1Loading(true);
  //   APIFetchClientsData(
  //     selectedYear,
  //     regionSelected
  //       ? regionSelected
  //       : subAreaSelected
  //       ? subAreaSelected
  //       : areaSelected
  //       ? areaSelected
  //       : "All"
  //   )
  //     .then((res: any) => {
  //       setClientsData(res.data);
  //     })
  //     .finally(() => setTable1Loading(false));
  // }, [selectedYear, regionSelected, subAreaSelected, areaSelected]);

  useEffect(() => {
    setSortConfig((prev: any) => ({
      ...prev,
      month: months[months.length - 1],
    }));
  }, [months]);

  const sortByKey = (
    array: any,
    sortVal: any,
    month: string,
    isReverse = false
  ) => {
    return [...array].sort((a: any, b: any) => {
      const x = a.months[month]?.[sortVal] ?? 0;
      const y = b.months[month]?.[sortVal] ?? 0;
      if (isReverse) {
        return x < y ? 1 : x > y ? -1 : 0;
      } else {
        return x < y ? -1 : x > y ? 1 : 0;
      }
    });
  };

  useEffect(() => {
    setFormattedData(getFormattedData(filteredGpData));
  }, [filteredGpData]);

  const requestSort = (key: any, direction: any, month: any) => {
    if (direction === "asc") {
      const sorted = sortByKey(formattedData, key, month, false);
      setSortedData(sorted);
    } else {
      const sorted = sortByKey(formattedData, key, month, true);
      setSortedData(sorted);
    }
  };

  useEffect(() => {
    requestSort(sortConfig?.key, sortConfig?.direction, sortConfig?.month);
  }, [sortConfig, formattedData]);

  const getIcon = (label: string, month: string, sortConfig: any) => {
    return sortConfig?.key !== label || sortConfig?.month !== month
      ? "unfold_more"
      : sortConfig?.direction === "asc"
      ? "arrow_drop_up"
      : "arrow_drop_down";
  };

  //Format raw data to show it in table
  const getFormattedData = (data: any) => {
    const managerWiseData = groupByKey(data, "ManagerName");
    const formattedArray = () =>
      Object.values(managerWiseData).map((managerData: any) => {
        const day =
          managerData[0].Days + (managerData[0].Days === 1 ? "Day" : "Days");
        const month =
          managerData[0].Month === 0
            ? day
            : managerData[0].Month +
              (managerData[0].Month === 1 ? "Month " : "Months ") +
              day;
        const tenure =
          managerData[0].Year === 0
            ? month
            : managerData[0].Year +
              (managerData[0].Year === 1 ? "Year " : "Years ") +
              month;
        const months = groupByKey(managerData, "MonthName");

        //Add keys of all arrays in each month to get total values for each month
        const transformedMonths = Object.keys(months).reduce(
          (acc: any, monthName: any) => {
            const monthData = months[monthName];

            const totals = monthData.reduce(
              (totalsAcc: any, item: any) => {
                totalsAcc.MTD += item.MTD;
                totalsAcc["13 weeks GP"] += item["13 weeks GP"];
                totalsAcc.QGP += item.QGP;
                totalsAcc.NQGP += item.NQGP;
                totalsAcc.YTD += item.YTD;
                totalsAcc.clientNames.add(item.ClientName);
                return totalsAcc;
              },
              {
                MTD: 0,
                "13 weeks GP": 0,
                QGP: 0,
                NQGP: 0,
                YTD: 0,
                clientNames: new Set(),
              }
            );

            acc[monthName] = {
              MTD: totals.MTD,
              "13 weeks GP": totals["13 weeks GP"],
              QGP: totals.QGP,
              NQGP: totals.NQGP,
              YTD: totals.YTD,
              clientNames: Array.from(totals.clientNames),
              data: monthData,
            };
            return acc;
          },
          {}
        );

        return {
          "Sales Manager": managerData[0].ManagerName,
          Branch: managerData[0].Branches,
          Tenure: tenure,
          months: transformedMonths,
        };
      });

    return formattedArray();
  };

  return (
    <div className="flex flex-col gap-2 relative">
      <div className="bg-[#2c90c3] text-white text-[0.7rem] font-bold px-10 py-[0.2rem] rounded-md absolute left-[46%] top-1">
        Gross Profit
      </div>
      <div className="flex justify-between">
        <div className="flex text-[0.7rem] font-semibold items-center">
          <div className="mr-2">Font Color Logic:</div>
          <div className="py-1 px-2 bg-green-500 text-white text-[0.65rem] font-semibold">
            PATH TO SUCCESS
          </div>
          <div className="py-1 px-2 bg-yellow-500 text-white text-[0.65rem] font-semibold">
            VERBAL
          </div>
          <div className="py-1 px-2 bg-[#ff0000] text-white text-[0.65rem] font-semibold">
            WRITTEN
          </div>
          <div className="py-1 px-2 bg-black text-white text-[0.65rem] font-semibold">
            TERMINATION
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex gap-2">
          <div className="border-[1px] border-stone-500 flex items-center gap-1 p-1">
            <i className="material-icons text-xs">info</i>
            <div className="text-[0.6rem]">
              <div>QGP(Qualified GP)</div>
              <div>
                This is GP earned within the 13 weeks of 1st Invoice date. This
                field will have value if GP earned ${">"}= $2500
              </div>
            </div>
          </div>
          <div className="border-[1px] border-stone-500 flex items-center gap-1 p-1">
            <i className="material-icons text-xs">info</i>
            <div className="text-[0.6rem]">
              <div>NQGP(Not Qualified GP)</div>
              <div>
                Accounts that took longer to hit the $2,500 in the first 13
                weeks of billing are added to this column
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="text-[0.7rem] font-bold flex gap-2 items-center">
          Year:
          <SegmentedControl
            value={selectedYear}
            onChange={setSelectedYear}
            data={["2022", "2023", "2024"]}
            size="xs"
            radius="xs"
            color="green"
            className="border-[1px] border-stone-400 mt-1"
          />
        </div>
      </div>
      <div className="relative">
        <div className="h-fit min-h-[250px] overflow-auto bg-white">
          <ContentLoader loading={table1Loading} />
          <Table verticalSpacing="1" fontSize={10} withColumnBorders>
            <thead className="sticky top-0  bg-[#80b7dc] z-20">
              <tr>
                <td className="bg-[#e6f1f1] sticky left-0" colSpan={3}></td>
                {months.map((month: any) => (
                  <td
                    className="text-[0.65rem] font-semibold border-x-[1px]"
                    key={month}
                    style={{
                      textAlign: "center",
                      padding: 2,
                      color: "white",
                    }}
                    colSpan={4}
                  >
                    {month}
                  </td>
                ))}
                <td className="bg-[#e6f1f1]"></td>
              </tr>
              <tr className="bg-[#2c90c3] ">
                {tableHeaders.map((header: any, index: number) => (
                  <th
                    key={index}
                    style={{
                      position: index < 3 ? "sticky" : "static",
                      left: index === 0 ? 0 : index === 1 ? 100 : 200,
                      zIndex: index < 3 ? 99 : 1,
                      backgroundColor: index < 3 ? "#2c90c3" : "transparent",
                      color: "white",
                      padding: 2,
                      textAlign:
                        header === "Tenure (Year,Month,Day)"
                          ? "center"
                          : "left",
                      minWidth:
                        header === "Tenure (Year,Month,Day)"
                          ? "130px"
                          : "100px",
                    }}
                  >
                    {header}
                  </th>
                ))}
                {months.map((month: any) => (
                  <>
                    {tableMonthlyHeaders.map((header: any) => (
                      <th
                        key={header.value}
                        style={{
                          color: "white",
                          textAlign: "center",
                          padding: 1,
                        }}
                      >
                        <div className="flex items-center">
                          <span className="pl-2 flex-grow">{header.label}</span>
                          <i
                            className="material-icons cursor-pointer self-end"
                            style={{ fontSize: "14px" }}
                            onClick={() =>
                              setSortConfig({
                                key: header.value,
                                month: month,
                                direction:
                                  sortConfig === null ||
                                  sortConfig.direction === "desc"
                                    ? sortConfig?.key === header.value &&
                                      sortConfig?.month === month
                                      ? "asc"
                                      : "desc"
                                    : "desc",
                              })
                            }
                          >
                            {getIcon(header.value, month, sortConfig)}
                          </i>
                        </div>
                      </th>
                    ))}
                  </>
                ))}
              </tr>
            </thead>
            <tbody className="-z-20 ">
              {sortedData &&
                sortedData.map((data: any) => {
                  return (
                    <TableRow
                      data={data}
                      tableMonthlyHeaders={tableMonthlyHeaders}
                      months={months}
                      formattedData={formattedData}
                    />
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default GPTable;
