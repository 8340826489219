/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useRef, useState, useEffect } from "react";
import {
  APIAddNotes,
  APIDeleteNote,
  APIFetchNotesByLocation,
  APIUpdateNotes,
} from "../../../api/pip-summary";
import { notifications } from "@mantine/notifications";
import { useMsal } from "@azure/msal-react";
import { Modal } from "@mantine/core";

const PipSummaryNotes = (props) => {
  const { accounts } = useMsal();
  const noteRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [branchNote, setBranchNote] = useState(props.notes || "");
  const [currentNote, setCurrentNote] = useState(props.notes || "");
  const [currentNoteId, setCurrentNoteId] = useState(props.noteId || "");
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    setCurrentNote(props.notes || "");
    setBranchNote(props.notes || "");
  }, [props.notes]);

  useEffect(() => {
    setCurrentNoteId(props.noteId || "");
  }, [props.noteId]);

  const saveNote = async (e) => {
    try {
      e.preventDefault();
      if (currentNote.trim() === "" || branchNote === currentNote.trim())
        return false;
      setLoading(true);
      setShowButtons(false);
      const noteObject = {
        locationType: props.location.type.toUpperCase(),
        locationName: props.location.name,
        note: currentNote.trim(),
        user: accounts[0].username,
      };
      currentNoteId
        ? await APIUpdateNotes(currentNoteId, noteObject)
        : await APIAddNotes(noteObject);
      const { data } = await APIFetchNotesByLocation(props.location.name);
      setCurrentNoteId(data.id);
      setBranchNote(data.Notes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      notifications.show({
        title: "Save Note",
        message: "Failed to save branch notes",
        color: "red",
      });
      setLoading(false);
    }
  };

  const cancelNote = () => {
    setShowButtons(false);
    setCurrentNote(branchNote);
  };

  const deleteNote = async () => {
    try {
      setDeleting(true);
      setModalOpened(false);
      await APIDeleteNote(currentNoteId);
      setCurrentNoteId("");
      setBranchNote("");
      setCurrentNote("");
    } catch (error) {
      console.log(error);
      notifications.show({
        title: "Delete Note",
        message: "Failed to delete branch notes",
        color: "red",
      });
    }
    setDeleting(false);
  };
  return (
    <div className="flex-1 relative z-0 bg-transparent border-t border-slate-400 px-2">
      {(loading || deleting) && (
        <div className="bg-gray-200/25 text-gray-600 py-1 absolute w-full text-center text-xs flex items-center justify-center">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-[#01B8AA]"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>{loading ? "Saving..." : "Deleting..."}</span>
        </div>
      )}
      <form onSubmit={saveNote} action="" autoComplete="off" className="flex">
        <input
          type="text"
          id="floating_standard"
          className="block py-1 pl-0 w-full text-xs bg-transparent appearance-none
                focus:outline-none focus:ring-0 peer text-gray-600"
          placeholder="Add Notes"
          maxLength={160}
          ref={noteRef}
          required
          disabled={loading}
          onFocus={() => {
            setShowButtons(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setShowButtons(false);
            }, 300);
          }}
          value={currentNote}
          onChange={(e) => {
            setCurrentNote(e.target.value);
          }}
        />
        {showButtons && (
          <span id="actions" className="flex gap-1">
            <button
              type="submit"
              onClick={saveNote}
              className="disabled:cursor-not-allowed text-green px-1"
              disabled={
                !currentNote.trim() || branchNote === currentNote.trim()
              }
            >
              <i className="material-icons text-base">check</i>
            </button>
            <button onClick={cancelNote} className="text-red px-1">
              <i className="material-icons text-base">close</i>
            </button>
          </span>
        )}
        {!showButtons && branchNote && (
          <button
            onClick={() => setModalOpened(true)}
            className="text-red px-1 text-center"
            type="button"
          >
            <i className="material-icons text-base">delete_outline</i>
          </button>
        )}
      </form>
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={<span className="font-medium">Delete Note</span>}
        centered
      >
        <div className="text-slate-700 text-sm">
          Are you sure you want to delete note for {props.location.name}?
        </div>
        <div className="flex gap-3 justify-end mt-4">
          <button
            className="w-28 text-sm border rounded bg-red-600 font-semibold text-white py-2"
            onClick={deleteNote}
          >
            Confirm
          </button>
          <button
            className="w-28 text-sm border rounded bg-slate-200 font-semibold text-slate-500 py-2"
            onClick={() => setModalOpened(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PipSummaryNotes;
