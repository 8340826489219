import HomeButton from "../components/common/HomeButton";
import { useEffect, useState } from "react";
import {
  QUARTER_HEADERS,
  TABLE_MONTH_HEADERS,
  YTD_HEADERS,
} from "../utils/mock-data/financial-report";
import GoalSettingTable from "../components/modules/goalSetting/GoalSettingTable";
import {
  APIBulkUpdate,
  APIClearAll,
  APIGoalSettingData,
} from "../api/goal-setting";
import { groupByKey, groupByMultipleKeys } from "../utils/group";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { sortByKeyDailyNumbers } from "../utils/helper/cellFormatter";
import {
  quarterPercKey,
  quarterSumKey,
} from "../components/modules/goalSetting/utils/constants";
import { notifications } from "@mantine/notifications";
import { Loader as MantineLoader } from "@mantine/core";
import { HOUSTON_BRANCHES } from "../utils/helper/constants";

const GoalSetting = () => {
  const [tableHeaders, setTableHeaders] = useState<any>([
    ...TABLE_MONTH_HEADERS(),
  ]);
  const [branchesList, setBranchesList] = useState<
    { name: string; location: string }[]
  >([]);
  const [branchSelected, setBranchSelected] = useState<{
    name: string;
    location: string;
  }>(branchesList[0] ?? { name: "All", location: "Company" });
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear().toString()
  );
  const [data, setData] = useState([]);
  const [filteredGoalData, setFilteredGoalData] = useState<{}[]>([]);
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state["filterReducer"].loading);
  const [opened, { open, close }] = useDisclosure(false);
  const [clearLoading, setClearLoading] = useState<boolean>(false);
  const [activeCell, setActiveCell] = useState<{
    branch: string | null;
    category: string | null;
    item: string | null;
    header: string[] | null;
  }>({ branch: null, category: null, item: null, header: null });
  const [highlight, setHighlight] = useState<{
    branch: string | null;
    category: string | null;
    item: string | null;
    header: string | null;
  }>({ branch: null, category: null, item: null, header: null });
  const [calcData, setCalcData] = useState<{}[]>([]);
  const [isSaving, setisSaving] = useState(false);

  useEffect(() => {
    document.title = "Ascend Custom Goal Setting Dashboard";
  }, []);

  useEffect(() => {
    setTableHeaders(() => {
      return branchSelected.location === "Branch"
        ? [...TABLE_MONTH_HEADERS(), ...YTD_HEADERS, "", ...QUARTER_HEADERS]
        : [...TABLE_MONTH_HEADERS(), ...YTD_HEADERS];
    });
  }, [branchSelected.location]);

  useEffect(() => {
    fetchTableData();
  }, [branchSelected.name]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Escape") {
        setActiveCell({
          branch: null,
          category: null,
          item: null,
          header: null,
        });
        setHighlight({
          branch: null,
          category: null,
          item: null,
          header: null,
        });
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const hierarchy = [
    "Company",
    "Areaname",
    "Sub Area Name",
    "Region",
    "District",
    "Branch",
  ];

  const createHierarchy = (data: any, hierarchy: any) => {
    const result: any = [];
    const buildLevel = (data: any, level: any) => {
      if (level >= hierarchy.length) return;
      const levelKey = hierarchy[level];
      const grouped: any = {};

      data.forEach((item: any) => {
        const key = item[levelKey];
        if (!grouped[key]) grouped[key] = [];
        grouped[key].push(item);
      });
      const definedKeys = Object.keys(grouped).filter((key) => key !== "null");
      const nullKeys = Object.keys(grouped).filter((key) => key === "null");
      if (levelKey === "District") {
        nullKeys.forEach((key) => {
          result.push({ name: key, location: levelKey });

          buildLevel(grouped[key], level + 1);
        });
        definedKeys.forEach((key) => {
          result.push({ name: key, location: levelKey });
          buildLevel(grouped[key], level + 1);
        });
      } else {
        definedKeys.forEach((key) => {
          result.push({ name: key, location: levelKey });
          buildLevel(grouped[key], level + 1);
        });
        nullKeys.forEach((key) => {
          result.push({ name: key, location: levelKey });

          buildLevel(grouped[key], level + 1);
        });
      }
    };
    buildLevel(data, 0);
    return result;
  };

  const fetchTableData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await APIGoalSettingData();
      setData(response.data);
      const transformedData = createHierarchy(response.data, hierarchy);
      setBranchesList(transformedData);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleClearAll = async () => {
    const updatedGoalData = filteredGoalData.map((d: any) => {
      if (d.Branch === branchSelected.name && d.flags === 1) {
        TABLE_MONTH_HEADERS().map((month) => {
          d[month] = 0;
        });
      }
      if (d.Branch === branchSelected.name && d.QuarterflagQ === 1) {
        Object.values(quarterSumKey).map((quarter: any) => {
          d[quarter] = 0;
        });
      }
      if (d.Branch === branchSelected.name && d.Quarterflagperc === 1) {
        Object.values(quarterPercKey).map((percent: any) => {
          d[percent] = 0;
        });
      }
      return d;
    });
    //API call to set all keys to 0
    try {
      setClearLoading(true);
      const res: any = await APIClearAll(branchSelected.name);
      if (res.status === "success") {
        setFilteredGoalData(updatedGoalData);
      }
    } catch (err) {
      notifications.show({
        title: "Error",
        message: `Oops! Cannot clear data for ${branchSelected.name} `,
        color: "red",
      });
    } finally {
      setClearLoading(false);
    }
    close();
  };

  const aggregrateData = (data: any) => {
    const result: any = {};
    for (const itemName in data) {
      const branches = data[itemName];
      const itemSums: any = {};
      const branchNames = new Set();
      const areaNames = new Set();
      const subAreaNames = new Set();
      const regions = new Set();
      const districts = new Set();

      branches.forEach((branchData: any) => {
        for (const key in branchData) {
          // Skip non-numeric fields and specific excluded fields
          if (["flags", "Quarterflagperc", "QuarterflagQ"].includes(key)) {
            continue;
          }
          if (key === "Branch") {
            branchNames.add(branchData[key]);
          } else if (key === "Areaname") {
            areaNames.add(branchData[key]);
          } else if (key === "Sub Area name") {
            subAreaNames.add(branchData[key]);
          } else if (key === "Region") {
            regions.add(branchData[key]);
          } else if (key === "District") {
            districts.add(branchData[key]);
          } else if (
            ["State", "Categories", "sorting", "items"].includes(key)
          ) {
            itemSums[key] = branchData[key];
          } else {
            itemSums[key] = (itemSums[key] || 0) + branchData[key];
          }
        }
      });
      itemSums["Branch"] = Array.from(branchNames);
      itemSums["Areaname"] = Array.from(areaNames);
      itemSums["Sub Area name"] = Array.from(subAreaNames);
      itemSums["Region"] = Array.from(regions);
      itemSums["District"] = Array.from(districts);
      result[itemName] = itemSums;
    }

    return result;
  };

  useEffect(() => {
    if (data && data.length !== 0) {
      let filteredData;
      if (branchSelected.location === "Branch") {
        filteredData = data.filter(
          (d: any) => d.Branch === branchSelected.name
        );
        setFilteredGoalData(
          sortByKeyDailyNumbers(filteredData, "sorting", false)
        );
      } else {
        if (branchSelected.location === "District") {
          filteredData = data.filter(
            (d: any) => d.District === branchSelected.name
          );
        } else if (branchSelected.location === "Region") {
          filteredData = data.filter(
            (d: any) => d.Region === branchSelected.name
          );
        } else if (branchSelected.location === "Sub Area Name") {
          filteredData = data.filter(
            (d: any) => d["Sub Area name"] === branchSelected.name
          );
        } else if (branchSelected.location === "Areaname") {
          filteredData = data.filter(
            (d: any) => d.Areaname === branchSelected.name
          );
        } else {
          filteredData = data;
        }
        const groupedData = groupByMultipleKeys(filteredData, [
          "items",
          "Categories",
        ]);
        const result = aggregrateData(groupedData);
        setFilteredGoalData(
          sortByKeyDailyNumbers(Object.values(result), "sorting", false)
        );
      }
    }
  }, [branchSelected, data]);

  const handleSaveAll = async () => {
    try {
      if (calcData && calcData.length !== 0) {
        if (branchSelected.location === "Branch") {
          setisSaving(true);
          const res: any = await APIBulkUpdate(calcData);
          if (res.status === "success") {
            notifications.show({
              title: "",
              message: `Successfully saved data for ${branchSelected.name} `,
              color: "green",
            });
            setActiveCell({
              branch: null,
              category: null,
              item: null,
              header: null,
            });
            setHighlight({
              branch: null,
              category: null,
              item: null,
              header: null,
            });
          }
        }
      }
    } catch (error) {
      notifications.show({
        title: "",
        message: `Oops! Cannot save data for ${branchSelected.name}. Try again later. `,
        color: "red",
      });
    } finally {
      setisSaving(false);
    }
  };

  const getBranchAlignment = (branch: { name: string; location: string }) => {
    return HOUSTON_BRANCHES.includes(branch.name)
      ? "font-normal pl-4"
      : branch.name === "QCSA"
      ? "font-normal pl-6"
      : branch.location === "Branch"
      ? "font-normal pl-10"
      : branch.location === "District"
      ? "pl-9"
      : branch.location === "Region"
      ? "pl-6"
      : branch.location === "Sub Area Name"
      ? "pl-4"
      : branch.location === "Areaname"
      ? "pl-2"
      : "";
  };

  return (
    <>
      {isSaving && <div className="fixed inset-0 "></div>}

      <main className="h-[100vh] px-2 overflow-auto  flex flex-col gap-2 lg:gap-10 pb-2">
        <HomeButton adaOnly={false} />
        <Loader />
        <header className="flex flex-col lg:flex-row lg:justify-between lg:items-start flex-1 ">
          <div className="text-md font-semibold ml-14 mt-1">
            Ascend Custom Goal Setting Dashboard
          </div>
          <div className="mx-auto flex flex-col gap-1 items-center ">
            <div className="text-sm w-[200px] font-medium p-1 bg-tertiary text-center text-white">
              {branchSelected.name ?? "Ascend Staffing"}
            </div>
          </div>
          <div className="flex w-full lg:w-[25%] justify-end items-end pt-2  gap-2">
            {" "}
            {/* <div className="w-30 lg:w-40">
            <Select
              value={selectedYear}
              onChange={setSelectedYear}
              data={Array.from({ length: 3 }, (_, i) =>
                (new Date().getFullYear() - i).toString()
              )}
              size="xs"
              label="Year"
            />
          </div> */}
            {branchSelected.location === "Branch" && (
              <>
                <Button
                  variant="filled"
                  color="green"
                  size="xs"
                  onClick={handleSaveAll}
                >
                  {isSaving ? (
                    <div className="flex gap-2 items-center w-[70px]">
                      {" "}
                      <MantineLoader color="white" size={"xs"} />
                      Saving...
                    </div>
                  ) : (
                    <div className="w-[70px] text-center">Save</div>
                  )}
                </Button>
                <Button variant="light" color="red" size="xs" onClick={open}>
                  Clear All
                </Button>
              </>
            )}
          </div>
        </header>
        {!loading && (
          <section className="flex gap-2 text-[0.7rem] overflow-hidden">
            <div className="branches-list  items-center flex flex-col gap-2 overflow-y-auto border border-gray">
              <div className="font-bold sticky top-0 bg-white w-full text-center p-1">
                Select Branch
              </div>
              <div className="branches flex flex-col w-full h-full">
                <div
                  className={`branch-menu font-bold cursor-pointer border border-1 border-gray py-1 px-1 ${
                    branchSelected.name === "All"
                      ? "bg-tertiary text-white"
                      : "hover:bg-stone-50"
                  }`}
                  onClick={() =>
                    setBranchSelected({ name: "All", location: "Company" })
                  }
                >
                  All
                </div>
                {branchesList
                  .filter(
                    (branch: { name: string; location: string }) =>
                      branch.name !== "null" && branch.name !== "undefined"
                  )
                  .map(
                    (
                      branch: { name: string; location: string },
                      index: number
                    ) => {
                      return (
                        <div
                          key={index}
                          className={`branch-menu  font-bold cursor-pointer border border-1 border-gray py-1 px-1 ${
                            branchSelected.name === branch.name
                              ? "bg-tertiary text-white"
                              : "hover:bg-stone-50"
                          } ${getBranchAlignment(branch)}  `}
                          onClick={() =>
                            isSaving ? null : setBranchSelected(branch)
                          }
                        >
                          <div>{branch.name}</div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <GoalSettingTable
              tableHeaders={tableHeaders}
              filteredGoalData={filteredGoalData}
              setFilteredGoalData={setFilteredGoalData}
              branchSelected={branchSelected.name}
              level={branchSelected.location}
              calcData={calcData}
              setCalcData={setCalcData}
              activeCell={activeCell}
              setActiveCell={setActiveCell}
              highlight={highlight}
              setHighlight={setHighlight}
            />
          </section>
        )}
      </main>
      <Modal
        opened={opened}
        onClose={close}
        title={`Are you sure you want to clear all the fields for ${branchSelected.name} ?`}
        centered
        size="auto"
        overlayProps={{
          opacity: 0.4,
        }}
        withCloseButton={false}
      >
        <div className="flex gap-2 items-center">
          <Button variant="filled" color="red" onClick={handleClearAll}>
            {clearLoading ? (
              <MantineLoader color="white" size={"sm"} />
            ) : (
              "Clear"
            )}
          </Button>
          <Button variant="outline" color="gray" onClick={close}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default GoalSetting;
