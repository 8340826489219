/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";

const MonthWiseBarChart = (props: any) => {
  const { data, months } = props;

  //Format data by mapping month number to month
  let barchartData: any = {};
  data.map((d: any) => {
    const monthName = months[d.MonthNumber];
    barchartData[monthName] = d.Total_for_selected_period;
  });

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "bar",

      toolbar: {
        show: false,
      },
    },

    grid: {
      show: false,
    },

    fill: {
      // colors: ["#7ac8ff"],
      colors: ["#66d995"],
      opacity: 1,
    },
    title: {
      text: "",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        dataLabels: {
          position: "top",
        },
      },
    },
    series: [
      {
        data: [],
      },
    ],
    stroke: {
      width: 0.5,
      colors: ["#ddd"],
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#444"],
        fontSize: "10px",
      },
      offsetY: -40,
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: "8px",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },

    tooltip: {
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
  });

  useEffect(() => {
    if (barchartData && Object.keys(barchartData)?.length > 0) {
      const seriesBarData = Object.keys(barchartData).map((key: any) => ({
        x: key,
        y: barchartData[key],
      }));

      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        series: [{ data: seriesBarData }],
      }));
    }
  }, []);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartOptions.series}
      type="bar"
      height={80}
      width={350}
    />
  );
};

export default MonthWiseBarChart;
