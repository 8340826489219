import { Popover, Table } from "@mantine/core";
import { useEffect, useRef, useState } from "react";

const TableRow = (props: any) => {
  const { data, months, tableMonthlyHeaders } = props;
  const [activeManager, setActiveManager] = useState<any>(null);
  const [activeMonth, setActiveMonth] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<any>(null);
  const [showClientsTable, setShowClientsTable] = useState(false);
  const buttonRef = useRef<any>();
  const dropdownRef = useRef<any>();

  // const clientHeaders = ["Client Name", "Client ID", "QGP", "NQGP"];

  const formatValue = (val: number) => {
    return val
      ? val > 0
        ? "$" + Math.round(val).toLocaleString()
        : "$" + "(" + Math.round(Math.abs(val)).toLocaleString() + ")"
      : "$0";
  };

  // const maxValue = (month: string, key: string) => {
  //   return Math.max(
  //     ...formattedData
  //       .map((data: any) => data.months[month]?.[0]?.[key])
  //       .filter((value: any) => value !== undefined)
  //   );
  // };

  const showDropDown = (
    tableManager: string,
    tableMonth: string,
    tableKey: string
  ) => {
    if (
      tableManager === activeManager &&
      tableMonth === activeMonth &&
      tableKey === activeKey
    ) {
      setShowClientsTable(false);
      setActiveManager(null);
      setActiveMonth(null);
      setActiveKey(null);
    } else {
      setShowClientsTable(true);
      setActiveManager(tableManager);
      setActiveMonth(tableMonth);
      setActiveKey(tableKey);
    }
  };

  useEffect(() => {
    if (showClientsTable) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showClientsTable]);

  const handleClickOutside = (e: any) => {
    if (
      !buttonRef.current?.contains(e.target) &&
      !dropdownRef.current?.contains(e.target)
    ) {
      setShowClientsTable(false);
      setActiveManager(null);
    }
  };

  return (
    <tr className={`last:border-b-2 `}>
      <td
        className={`flex items-center min-w-[10px] text-green-500 p-[0.2rem] sticky left-0 ${
          activeManager === data["Sales Manager"] ? "bg-stone-200" : "bg-white"
        } z-20`}
      >
        {data["Sales Manager"]}
        {/* <Popover
          position="bottom-start"
          width={400}
          opened
          zIndex={100}
          offset={-2}
          shadow="lg"
        >
          {client.length !== 0 ? (
            <Popover.Target>
              <i
                className="material-icons cursor-pointer text-stone-600"
                onClick={() => {
                  showDropDown(data["Sales Manager"]);
                }}
                ref={buttonRef}
              >
                arrow_drop_down
              </i>
            </Popover.Target>
          ) : (
            <div className="w-6 h-5"></div>
          )}
          <span className="text-green-500">{data["Sales Manager"]}</span>
          {activeManager === currentManager && showClientsTable && (
            <Popover.Dropdown style={{ padding: 0 }}>
              <div
                className="w-full max-h-[150px] h-fit overflow-auto border-[1px]"
                ref={dropdownRef}
              >
                <Table verticalSpacing="1" fontSize={10} withColumnBorders>
                  <thead className="bg-[#be7091] text-white font-semibold z-20 sticky top-0 ">
                    <tr>
                      {clientHeaders.map((header: any) => (
                        <td>
                          <div className="flex w-full items-center">
                            <span
                              className={`flex-grow ${
                                header === "Client Name"
                                  ? "text-left"
                                  : "text-center"
                              }`}
                            >
                              {header}
                            </span>
                            {header === "QGP" && (
                              <i
                                className="material-icons cursor-pointer self-end"
                                style={{ fontSize: "14px" }}
                                onClick={() =>
                                  setSortConfig({
                                    key: header,
                                    direction:
                                      sortConfig === null ||
                                      sortConfig.direction === "desc"
                                        ? "asc"
                                        : "desc",
                                  })
                                }
                              >
                                {getIcon(header, sortConfig)}
                              </i>
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedClientData &&
                      sortedClientData.length !== 0 &&
                      sortedClientData.map((data: any) => {
                        return (
                          <tr>
                            <td className="p-1">{data["ClientName"]}</td>
                            <td className="text-center p-1">
                              {data["ClientID"]}
                            </td>
                            <td className="text-center p-1">
                              {formatValue(data["QGP"])}
                            </td>
                            <td className="text-center p-1">
                              {formatValue(data["NQGP"])}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Popover.Dropdown>
          )}
        </Popover> */}
      </td>
      <td
        className={`min-w-[60px] sticky left-[100px] ${
          activeManager === data["Sales Manager"] ? "bg-stone-200" : "bg-white"
        } z-20`}
      >
        {data.Branch}
      </td>
      <td
        className={`min-w-[100px] text-center sticky left-[200px] ${
          activeManager === data["Sales Manager"] ? "bg-stone-200" : "bg-white"
        } z-20`}
      >
        {data.Tenure}
      </td>

      {months.map((month: string) => (
        <>
          {tableMonthlyHeaders.map(({ label, value, bgColor }: any) => {
            const monthValue = data.months[month]?.[value];
            const currentManager = data["Sales Manager"];
            const currentMonth = month;
            const currentKey = label;

            return (
              <td
                key={value}
                className={`min-w-[70px]  ${bgColor} text-center`}
              >
                <Popover
                  position="bottom-start"
                  width={"200"}
                  opened={
                    activeManager === currentManager &&
                    activeMonth === currentMonth &&
                    currentKey === activeKey &&
                    showClientsTable
                  }
                  zIndex={100}
                  offset={-1}
                  shadow="lg"
                >
                  <Popover.Target>
                    <div className="flex  items-center cursor-pointer w-full ">
                      <span className="text-center flex-grow">
                        {" "}
                        {formatValue(monthValue)}
                      </span>
                      {monthValue && monthValue !== 0 ? (
                        <i
                          className={`material-icons cursor-pointer text-stone-600 text-sm ${
                            activeManager === currentManager &&
                            activeMonth === currentMonth &&
                            currentKey === activeKey &&
                            showClientsTable
                              ? "font-extrabold text-black text-[1rem]"
                              : ""
                          }`}
                          onClick={() => {
                            showDropDown(data["Sales Manager"], month, label);
                          }}
                          ref={buttonRef}
                        >
                          expand_more
                        </i>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown style={{ padding: 0 }}>
                    <div
                      className="h-fit max-h-[130px] overflow-y-auto overflow-hidden bg-[#eaf9f9] text-[0.6rem]"
                      ref={dropdownRef}
                    >
                      <Table
                        fontSize={10}
                        verticalSpacing={1}
                        withColumnBorders
                      >
                        <thead className="bg-[#a2c0c0]">
                          <tr>
                            <td className="min-w-[50px]">Client ID</td>
                            <td>Client Name</td>
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {data.months[month]?.data
                            ?.filter((d: any) => d[value] !== 0)
                            .map((d: any) => (
                              <tr>
                                <td>{d.ClientId}</td>
                                <td>{d.ClientName}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Popover.Dropdown>
                </Popover>

                {/* <div
                  className={`${bgColor} h-5`}
                  style={{
                    width:
                      maxMonthValue === 0
                        ? "0%"
                        : monthValue
                        ? ` ${(monthValue / maxMonthValue) * 100}%`
                        : "0%",
                  }}
                >
                 
                  <div className="absolute w-full text-center">
                    {formatValue(monthValue)}
                  </div>
                </div> */}
              </td>
            );
          })}
        </>
      ))}
    </tr>
  );
};

export default TableRow;
