import {
  setShowBranchClientsRanking,
  setShowClientsRanking,
} from "../../../store/CompanySummary/actions";
import { CS_BRANCHES_TOP } from "../companySummary/utils/constants";
import { groupByKey } from "../../../utils/group";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { APIFetchRegions } from "../../../api/sales-manager";

const getHierarchy = (data: any) => {
  let subAreaHierarchy = {};
  Object.keys(data).forEach((v: any) => {
    let temp = groupByKey(data[v], "Sub Area Name");
    let subHier = {};
    for (let v in temp) {
      subHier = {
        ...subHier,
        [v]: Object.values(temp[v]).map((v: any) => ({
          District: v.District,
          Region: v.Region,
          SubAreaName: v["Sub Area Name"],
          Area: v.Areaname,
        })),
      };
    }
    subAreaHierarchy = {
      ...subAreaHierarchy,
      [v]: subHier,
    };
  });
  return subAreaHierarchy;
};

const BranchTopFocusSelector = (props: any) => {
  const dispatch = useDispatch();
  const {
    level,
    setLevel,
    hierarchy,
    setHierarchy,
    // setBranchesList,
    areaList,
    setAreaList,
    subAreaList,
    setSubAreaList,
    regionsList,
    setRegionsList,
    districtList,
    setDistrictList,
    selectedLevelBranch,
    setSelectedLevelBranch,
    areaSelected,
    setAreaSelected,
    setSubAreaSelected,
    setRegionSelected,
    setDistrictSelected,
    setSelectedBranch,
    setSelectedClientName,
    chartAreaSelected,
    chartRegionSelected,
    setChartAreaSelected,
    setChartRegionSelected,
    setChartSubAreaSelected,
    setChartDistrictSelected,
    adaOnly,
    isAscendAdaAccess,
  } = props;
  const [rawData, setRawData] = useState([]);
  const [showAllRegions, setShowAllRegions] = useState<string | null>(
    "Company"
  );
  const [showDistricts, setShowDistricts] = useState<string | null>("Company");
  const [showUngroupedRegions, setShowUngroupedRegions] =
    useState<boolean>(false);
  const [region, setRegion] = useState<string | null>(null); //state for showing regions in filter
  const [subarea, setSubarea] = useState<string | null>(null); //state for showing subareas in filter

  const loadRegions = async () => {
    const res: any = await APIFetchRegions();
    const resWithFixesSubArea = res.data.map((v: any) => ({
      ...v,
      "Sub Area Name": v["Sub Area Name"] ?? "Ungrouped",
    }));
    setRawData(resWithFixesSubArea);
    const regionTemp = Object.values(
      groupByKey(resWithFixesSubArea, "Region")
    ).map((v: any) => ({
      Region: v[0].Region,
      AreaName: v[0]["Areaname"],
      SubAreaName: v[0]["Sub Area Name"],
    }));
    const districtTemp = Object.values(
      groupByKey(resWithFixesSubArea, "District")
    ).map((v: any) => ({
      District: v[0].District,
      Region: v[0].Region,
      AreaName: v[0].Areaname,
      SubAreaName: v[0]["Sub Area Name"],
    }));
    const hierarchyTemp = groupByKey(resWithFixesSubArea, "Areaname");
    let subAreaHierarchy = getHierarchy(hierarchyTemp);
    setHierarchy(subAreaHierarchy);
    const areaTemp: any = Object.keys(hierarchyTemp) ?? [];
    setAreaList(areaTemp);
    setSubAreaList(
      Object.keys(groupByKey(resWithFixesSubArea, "Sub Area Name"))
    );
    setRegionsList(regionTemp ?? []);
    setDistrictList(districtTemp ?? []);
    // setBranchesList(res.data.map((v: any) => v.branch));
  };

  useEffect(() => {
    loadRegions();
  }, []);

  const setBranch = (level: string, branch: any) => {
    setLevel(level);
    setSelectedLevelBranch(branch.Location);
    dispatch(setShowClientsRanking(false));
    dispatch(setShowBranchClientsRanking(false));
  };

  const setAreaLevel = (branch: string) => {
    setChartRegionSelected(null);
    setChartDistrictSelected(null);
    setChartSubAreaSelected(null);
    setSelectedClientName("All");
    setChartAreaSelected(branch);

    setAreaSelected(branch);
    setSubAreaSelected(null);
    setRegionSelected(null);
    setSubarea(null);
    setRegion(null);
    setDistrictSelected(null);
    setSelectedBranch("All");
    setShowUngroupedRegions(true);
    setShowAllRegions(null);
    setShowDistricts(null);
    setLevel("Area");
    setBranch("Area", { Location: branch, Title: "Area" });
    setSubAreaList(
      Object.keys(hierarchy[branch]).filter((v) => v !== "Ungrouped")
    );
    setRegionsList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "Region")
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
    );
    setDistrictList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "District")
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
    );
    // setBranchesList(
    //   rawData.filter((v) => v["Areaname"] === branch).map((v: any) => v.branch)
    // );
  };

  const setSubareaLevel = (branch: string) => {
    setChartRegionSelected(null);
    setSelectedClientName("All");
    setChartSubAreaSelected(branch);
    setChartDistrictSelected(null);
    setChartAreaSelected(null);

    setSubAreaSelected(branch);
    setSubarea(branch);
    setAreaSelected(null);
    setRegionSelected(null);
    setRegion(null);
    setDistrictSelected(null);
    setSelectedBranch("All");
    setShowDistricts(null);
    setShowUngroupedRegions(false);
    setShowAllRegions(branch);
    setBranch("Subarea", { Location: branch, Title: "Subarea" });
    setRegionsList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "Region"
        )
      ).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setDistrictList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "District"
        )
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Areaname,
          SubAreaName: v[0]["Sub Area Name"],
        })) ?? []
    );
    // setBranchesList(
    //   rawData
    //     .filter((v) => v["Sub Area Name"] === branch)
    //     .map((v: any) => v.branch)
    // );
  };

  const setRegionLevel = (branch: any) => {
    setChartAreaSelected(null);
    setChartDistrictSelected(null);
    setChartSubAreaSelected(null);
    setChartRegionSelected(branch.Region);
    setSelectedClientName("All");

    setRegionSelected(branch.Region);
    setRegion(branch.Region);
    setSelectedBranch("All");
    setAreaSelected(null);
    setSubAreaSelected(null);
    setSubarea(branch.SubAreaName);
    setDistrictSelected(null);
    setLevel("Region");
    setDistrictList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v.Region === branch.Region),
          "District"
        )
      ).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setBranch("Region", { Location: branch.Region, Title: "Region" });
    // setBranchesList(
    //   rawData
    //     .filter((v) => v["Location"] === branch.Location)
    //     .map((v: any) => v.branch)
    // );
  };

  const setDistrictLevel = (branch: any) => {
    setChartDistrictSelected(branch.District);
    setChartSubAreaSelected(null);
    setChartAreaSelected(null);
    setChartRegionSelected(null);

    setDistrictSelected(branch.District);
    setLevel("District");
    setSelectedBranch("All");
    const districtTemp: any = rawData.find(
      (v: any) => v.District === branch.District
    );
    setAreaSelected(null);
    setRegionSelected(null);
    setSubAreaSelected(null);
    setRegion(districtTemp ? districtTemp.Region : "");
    setSubarea(districtTemp ? districtTemp["Sub Area Name"] : "");
    setBranch("District", { Location: branch.District, Title: "Area Branch" });
  };

  const setCompanyLevel = () => {
    setChartAreaSelected(null);
    setChartRegionSelected(null);
    setChartDistrictSelected(null);
    setChartSubAreaSelected(null);
    setSelectedClientName("All");
    setShowAllRegions("Company");
    setSelectedBranch("All");
    setShowDistricts("Company");
    setSubAreaSelected(null);
    setRegionSelected(null);
    setSubarea(null);
    setRegion(null);
    setDistrictSelected(null);
    setShowUngroupedRegions(false);
    setLevel("Company");
    setBranch("Company", { Location: "Company", Title: "Company" });
    setSubAreaList(Object.keys(groupByKey(rawData, "Sub Area Name")));
    setRegionsList(
      Object.values(groupByKey(rawData, "Region")).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      }))
    );
    setDistrictList(
      Object.values(groupByKey(rawData, "District")).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Area,
        SubAreaName: v[0].SubAreaName,
      })) ?? []
    );
    // setBranchesList(rawData.map((v: any) => v.branch));
  };

  const setCompanyLevelADA = () => {
    setShowAllRegions(null);
    setShowDistricts(null);
    setSubAreaSelected(null);
    setRegionSelected(null);
    setShowUngroupedRegions(false);
    setSelectedBranch("All");
    setSelectedClientName("All");
    setLevel("ADA");
    setBranch("ADA", { Location: "ADA_Branch", Title: "ADASTAFF, Inc." });
    setSubAreaList([]);
    setRegionsList([]);
    setDistrictList([]);
  };

  return (
    <div
      className={`flex flex-col gap-[0.4rem] h-fit ${
        adaOnly ? "min-h-[8vh]" : "min-h-[16vh]"
      } z-20`}
    >
      <div
        className={`top-hierarchy flex justify-center gap-4 ${
          isAscendAdaAccess ? "-ml-[7rem]" : ""
        }`}
      >
        {(isAscendAdaAccess || adaOnly) && (
          <div
            className={` px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-[0.8rem] ${
              selectedLevelBranch === "ADA_Branch"
                ? "bg-[#01b8aa] text-white"
                : "bg-white hover:bg-green-50"
            } ${adaOnly ? "" : "cursor-pointer"}`}
            onClick={() => setCompanyLevelADA()}
          >
            ADASTAFF, Inc.
          </div>
        )}
        {!adaOnly &&
          CS_BRANCHES_TOP.map(
            (v, key) =>
              v.label && (
                <div
                  key={key}
                  className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-[0.8rem]  ${
                    selectedLevelBranch === v.label
                      ? "bg-[#01b8aa] text-white"
                      : "bg-white hover:bg-green-50"
                  }`}
                  onClick={() => setCompanyLevel()}
                >
                  Ascend Staffing
                </div>
              )
          )}
      </div>
      <div className="mid-hierarchy flex gap-4 justify-center">
        {level !== "ADA" &&
          areaList.map(
            (v: any, key: number) =>
              v && (
                <div
                  key={key}
                  className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-[0.8rem]  ${
                    areaSelected === v.Location ? "bg-gray-500 " : ""
                  } ${
                    selectedLevelBranch === v
                      ? "bg-[#01b8aa] text-white"
                      : "bg-white hover:bg-green-50"
                  }`}
                  onClick={() => setAreaLevel(v)}
                >
                  {v}
                </div>
              )
          )}
      </div>
      <div className="mid-hierarchy flex justify-center gap-2">
        {level !== "ADA" &&
          subAreaList.map(
            (v: any, key: number) =>
              v &&
              v !== "Ungrouped" && (
                <div
                  key={key}
                  className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-[0.8rem]  ${
                    selectedLevelBranch === v
                      ? "bg-[#01b8aa] text-white"
                      : "bg-white hover:bg-green-50"
                  }`}
                  onClick={() => setSubareaLevel(v)}
                >
                  {v}
                </div>
              )
          )}
        {!adaOnly &&
          showUngroupedRegions &&
          regionsList
            .filter((region: any) => region.SubAreaName === "Ungrouped")
            .map(
              (v: any, key: any) =>
                v.Region && (
                  <div
                    key={key}
                    className={`cursor-pointer px-[0.2rem] lg:px-[0.6rem] py-[0.2rem] border-[1px] rounded-md  text-[0.8rem]  ${
                      selectedLevelBranch === v.Region
                        ? "bg-[#01b8aa] text-white"
                        : "bg-white hover:bg-green-50"
                    }`}
                    onClick={() => {
                      setRegionLevel(v);
                      setShowDistricts(null);
                      setShowAllRegions(null);
                    }}
                  >
                    {v.Region}
                  </div>
                )
            )}
      </div>
      {!adaOnly && (
        <>
          <div className="low-hierarchy flex gap-2 justify-center">
            {showAllRegions &&
              regionsList
                .filter(
                  (region: any) =>
                    region.SubAreaName !== "Ungrouped" &&
                    (!subarea || region.SubAreaName === subarea)
                )
                .map(
                  (v: any, key: any) =>
                    v.Region && (
                      <div
                        key={key}
                        className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-[0.8rem]                       
                     ${
                       selectedLevelBranch === v.Region
                         ? "bg-[#01b8aa] text-white"
                         : "bg-white hover:bg-green-50"
                     }`}
                        onClick={() => {
                          setRegionLevel(v);
                          setShowDistricts(v.Region);
                          setShowUngroupedRegions(false);
                        }}
                      >
                        {v.Region}
                      </div>
                    )
                )}
            {showAllRegions &&
              regionsList
                .filter(
                  (region: any) =>
                    region.SubAreaName === "Ungrouped" &&
                    (!subarea || region.SubAreaName === subarea)
                )
                .map(
                  (v: any, key: any) =>
                    v.Region && (
                      <div
                        key={key}
                        className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-[0.8rem]   ${
                          selectedLevelBranch === v.Region
                            ? "bg-[#01b8aa] text-white"
                            : "bg-white hover:bg-green-50"
                        }`}
                        onClick={() => {
                          setRegionLevel(v);
                          setShowUngroupedRegions(
                            v.SubAreaName === "Ungrouped" ? true : false
                          );
                          setShowAllRegions(
                            v.SubAreaName === "Ungrouped" ? null : v.Region
                          );
                        }}
                      >
                        {v.Region}
                      </div>
                    )
                )}
          </div>
          <div className="lowest-hierarchy flex justify-center gap-2">
            {showDistricts &&
              districtList
                .filter((v: any) => (region ? v.Region === region : true))
                .map(
                  (v: any, key: any) =>
                    v.District && (
                      <div
                        key={key}
                        className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md  text-xs lg:text-[0.8rem]   
                      ${
                        selectedLevelBranch === v.District
                          ? "bg-[#01b8aa] text-white"
                          : "bg-white hover:bg-green-50"
                      }
                    `}
                        onClick={() => setDistrictLevel(v)}
                      >
                        {v.District}
                      </div>
                    )
                )}
          </div>
        </>
      )}
    </div>
  );
};

export default BranchTopFocusSelector;
