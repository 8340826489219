/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchEmployeeRedeployment = (
  startdate: any,
  enddate: any,
  branch: any,
  client: any,
  level: any
) => {
  return GetRequest(`/employee-redeployment`, {
    params: { startdate, enddate, branch, client, level },
  });
};

export const APIFetchEndReasons = () => {
  return GetRequest(`/employee-redeployment/end-reasons`);
};
