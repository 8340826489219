/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./rootReducer";

export const store = createStore(rootReducer, applyMiddleware(thunk));
