/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import {
  APIGetADAComparison,
  APIGetADANetStarts,
  APIGetADARevPerEmployeeDay,
  APIGetAreaAvgDailyNumbersArea,
  APIGetAreaAvgDailyNumbersVp,
  APIGetAreaComparison,
  APIGetAreaNetStarts,
  APIGetAreaRevPerEmployeeDay,
  APIGetAvgDailyNumbersADA,
  APIGetCompanyAvgDailyNumbersCompany,
  APIGetCompanyComparison,
  APIGetCompanyNetStarts,
  APIGetCompanyRevPerEmployeeDay,
  APIGetDistrictComparison,
  APIGetDistrictNetStarts,
  APIGetDistrictRevPerEmployeeDay,
  APIGetRegionAvgDailyNumbersDistrict,
  APIGetRegionAvgDailyNumbersRegion,
  APIGetRegionComparison,
  APIGetRegionNetStarts,
  APIGetRegionRevPerEmployeeDay,
  APIGetVpComparison,
  APIGetVpNetStarts,
  APIGetVpRevPerEmployeeDay,
} from "../../../api/dailyNumber";
import {
  dailyNumberFormatter,
  getAvgDailyNumbers,
  getComparison,
  getNetStarts,
  getRevGPData,
} from "./utils/dailyNumberbranch";
import moment from "moment";
import { ContentLoader, Loader } from "../../common/Loader";
import { useSelector } from "react-redux";

export const DailyNumberBranchTable = (props: any) => {
  const [revLoader, setRevLoader] = useState(false);
  const [netStatLoader, setNetStatLoader] = useState(false);
  const [avgLoader, setAvgLoader] = useState(false);
  const [comparisonLoader, setComparisonLoader] = useState(false);
  const [headers, setHeaders] = useState([] as string[]);
  const [yearComparisonValue, setYearComparisonValue] = useState(
    (+moment().year() - 1).toString()
  );
  const [revenueData, setRevenueData] = useState({
    revenuePerDay: {
      CompanyTotal: 0,
    },
    gpPerDay: {
      CompanyTotal: 0,
    },
  } as any);
  const [netStats, setNetStats] = useState({
    CompanyTotal: 0,
  } as any);
  const [avgDailyNumbers, setAvgDailyNumbers] = useState({
    CurrDailyNumbers: {
      CompanyTotal: 0,
    },
    Difference: {
      CompanyTotal: 0,
    },
    LastDailyNumbers: {
      CompanyTotal: 0,
    },
  } as any);
  const [comparison, setComparison] = useState({
    CurrDailyNumbers: {
      CompanyTotal: 0,
    },
    Difference: {
      CompanyTotal: 0,
    },
    LastDailyNumbers: {
      CompanyTotal: 0,
    },
  } as any);

  const sortOrder = useSelector(
    (state: any) => state.filterReducer.locationOrder
  );

  useEffect(() => {
    if (sortOrder && props.rawHeaders) sortHeaders();
  }, [sortOrder, props.rawHeaders]);

  const loadRevenueData = async () => {
    setRevLoader(true);
    let revRes: any;
    if (props.level === "Company") {
      revRes = await APIGetCompanyRevPerEmployeeDay();
    } else if (props.level === "Area") {
      revRes = await APIGetAreaRevPerEmployeeDay(props.selectedBranch);
    } else if (props.level === "Subarea") {
      revRes = await APIGetVpRevPerEmployeeDay(props.selectedBranch);
    } else if (props.level === "Region") {
      revRes = await APIGetRegionRevPerEmployeeDay(props.selectedBranch);
    } else if (props.level === "District") {
      revRes = await APIGetDistrictRevPerEmployeeDay(props.selectedBranch);
    } else if (props.level === "ADA") {
      revRes = await APIGetADARevPerEmployeeDay();
    }
    setRevenueData({ ...getRevGPData(revRes?.data) });
    setRevLoader(false);
  };

  const sortHeaders = () => {
    const headersTemp = props.rawHeaders.filter(
      (v: any) =>
        ![
          "CheckCuts",
          "CompanyTotal",
          "Region Total",
          "Area Total",
          "VP Total",
          "ADA Company Total",
          "Area Branch Total",
        ].includes(v?.trim())
    );
    headersTemp.sort(
      (x: any, y: any) => sortOrder.indexOf(x) - sortOrder.indexOf(y)
    );
    setHeaders(headersTemp);
  };

  const loadNetStats = async () => {
    setNetStatLoader(true);
    let netStatsRes: any;
    if (props.level === "Company") {
      netStatsRes = await APIGetCompanyNetStarts();
    } else if (props.level === "Area") {
      netStatsRes = await APIGetAreaNetStarts(props.selectedBranch);
    } else if (props.level === "Subarea") {
      netStatsRes = await APIGetVpNetStarts(props.selectedBranch);
    } else if (props.level === "Region") {
      netStatsRes = await APIGetRegionNetStarts(props.selectedBranch);
    } else if (props.level === "District") {
      netStatsRes = await APIGetDistrictNetStarts(props.selectedBranch);
    } else if (props.level === "ADA") {
      netStatsRes = await APIGetADANetStarts();
    }
    setNetStats(getNetStarts(netStatsRes?.data) ?? {});
    setNetStatLoader(false);
  };

  const loadAvgNumbers = async () => {
    setAvgLoader(true);
    let avgDailyRes: any;
    if (props.level === "Company") {
      avgDailyRes = await APIGetCompanyAvgDailyNumbersCompany();
    } else if (props.level === "Area") {
      avgDailyRes = await APIGetAreaAvgDailyNumbersArea(props.selectedBranch);
    } else if (props.level === "Subarea") {
      avgDailyRes = await APIGetAreaAvgDailyNumbersVp(props.selectedBranch);
    } else if (props.level === "Region") {
      avgDailyRes = await APIGetRegionAvgDailyNumbersRegion(
        props.selectedBranch
      );
    } else if (props.level === "District") {
      avgDailyRes = await APIGetRegionAvgDailyNumbersDistrict(
        props.selectedBranch
      );
    } else if (props.level === "ADA") {
      avgDailyRes = await APIGetAvgDailyNumbersADA();
    }
    setAvgDailyNumbers({ ...getAvgDailyNumbers(avgDailyRes?.data) });
    setAvgLoader(false);
  };

  const loadData = async () => {
    loadRevenueData();
    loadNetStats();
    loadAvgNumbers();
  };

  const loadComparisonData = async () => {
    setComparisonLoader(true);
    let comparisonRes: any;
    if (props.level === "Company") {
      comparisonRes = await APIGetCompanyComparison({
        date: yearComparisonValue,
      });
    } else if (props.level === "Area") {
      comparisonRes = await APIGetAreaComparison({
        date: yearComparisonValue,
        area: props.selectedBranch,
      });
    } else if (props.level === "Subarea") {
      comparisonRes = await APIGetVpComparison({
        date: yearComparisonValue,
        vp: props.selectedBranch,
      });
    } else if (props.level === "Region") {
      comparisonRes = await APIGetRegionComparison({
        date: yearComparisonValue,
        region: props.selectedBranch,
      });
    } else if (props.level === "District") {
      comparisonRes = await APIGetDistrictComparison({
        date: yearComparisonValue,
        district: props.selectedBranch,
      });
    } else if (props.level === "ADA") {
      comparisonRes = await APIGetADAComparison({
        date: yearComparisonValue,
      });
    }
    setComparison({ ...getComparison(comparisonRes?.data) });
    setComparisonLoader(false);
  };
  useEffect(() => {
    const interval = setInterval(() => loadData().then((_) => {}), 900000);
    return () => {
      clearInterval(interval);
    };
  }, [props.level, props.selectedBranch]);

  useEffect(() => {
    loadData().then((_) => {});
  }, [props.level, props.selectedBranch]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadComparisonData().then((_) => {});
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, [props.level, props.selectedBranch]);

  useEffect(() => {
    loadComparisonData().then((_) => {});
  }, [yearComparisonValue, props.level, props.selectedBranch]);

  // useEffect(() => {
  //     loadData().then(_ => {
  //     });
  // }, [props.level, props.selectedBranch]);

  return (
    <section className={"daily-number-branch-table"}>
      <div className="table-area overflow-auto">
        <div className="dnb-table">
          <div className="dnb-row dnb-header relative bold w-fit lg:w-full">
            <div className={`dnb-col`}>Branch</div>
            {headers.map((v: any, key: number) => (
              <div className={`dnb-col`} key={key + "dnb-col"}>
                {v}
              </div>
            ))}
            {props.totalType === "Region" &&
              props.rawHeaders.includes("Region Total") && (
                <div className={`dnb-col`}>Region Total</div>
              )}
            {props.totalType === "Area" &&
              props.rawHeaders.includes("Area Total") && (
                <div className={`dnb-col`}>Area Total</div>
              )}
            {props.totalType === "Subarea" &&
              props.rawHeaders.includes("VP Total") && (
                <div className={`dnb-col`}>VP Total</div>
              )}
            {props.totalType === "Area Branch" &&
              props.rawHeaders.includes("Area Branch Total") && (
                <div className={`dnb-col`}>Area Branch Total</div>
              )}

            <div className={`dnb-col`}>
              {props.level === "ADA" ? "ADA Company Total" : "Company Total"}
            </div>
          </div>
        </div>
        <div className="dnb-data w-fit lg:w-full">
          <div className="dnb-section relative">
            <ContentLoader loading={revLoader} />
            <div className="dnb-header-title">
              Revenue vs GP Per Employee Per Day
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Revenue/Emp</div>
              {headers.map((v: any, key: any) => (
                <div className={`dnb-col`} key={key + "dnb-col"}>
                  {dailyNumberFormatter(
                    revenueData?.revenuePerDay[`${v}`] ?? 0
                  )}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(
                      revenueData?.revenuePerDay?.RegionTotal ?? 0
                    )}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(
                      revenueData?.revenuePerDay?.AreaTotal ?? 0
                    )}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(
                      revenueData?.revenuePerDay?.["Sub Area Total"] ?? 0
                    )}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(
                      revenueData?.revenuePerDay?.["Area Branch Total"] ?? 0
                    )}
                  </div>
                )}
              <div className={`dnb-col`}>
                {dailyNumberFormatter(
                  revenueData?.revenuePerDay?.CompanyTotal ?? 0
                )}
              </div>
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>GP/Emp</div>
              {headers.map((v: any, key: any) => (
                <div className={`dnb-col`} key={key + "dnb-col"}>
                  {dailyNumberFormatter(revenueData?.gpPerDay[`${v}`] ?? 0)}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(revenueData?.gpPerDay?.RegionTotal)}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(revenueData?.gpPerDay?.AreaTotal)}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(
                      revenueData?.gpPerDay?.["Sub Area Total"] ?? 0
                    )}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div className={`dnb-col`}>
                    {dailyNumberFormatter(
                      revenueData?.gpPerDay?.["Area Branch Total"] ?? 0
                    )}
                  </div>
                )}
              <div className={`dnb-col`}>
                {dailyNumberFormatter(revenueData?.gpPerDay?.CompanyTotal)}
              </div>
            </div>
          </div>
          <div className="dnb-section relative">
            <ContentLoader loading={netStatLoader} />
            <div className="dnb-header-title">Net Starts (Daily Numbers)</div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Difference</div>
              {headers.map((v: any, key: any) => (
                <div
                  className={`dnb-col text-${
                    netStats[`${v}`] < 0 ? "red" : "green"
                  }`}
                  key={key + "dnb-col"}
                >
                  {netStats[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div
                    className={`dnb-col text-${
                      netStats?.RegionTotal < 0 ? "red" : "green"
                    }`}
                  >
                    {netStats?.RegionTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div
                    className={`dnb-col text-${
                      netStats?.AreaTotal < 0 ? "red" : "green"
                    }`}
                  >
                    {netStats?.AreaTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div
                    className={`dnb-col text-${
                      netStats?.["Sub Area Total"] < 0 ? "red" : "green"
                    }`}
                  >
                    {netStats?.["Sub Area Total"] ?? 0}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div
                    className={`dnb-col text-${
                      netStats?.["Area Branch Total"] < 0 ? "red" : "green"
                    }`}
                  >
                    {netStats?.["Area Branch Total"] ?? 0}
                  </div>
                )}
              <div
                className={`dnb-col text-${
                  netStats?.RegionTotal < 0 ? "red" : "green"
                }`}
              >
                {netStats?.CompanyTotal ?? 0}
              </div>
            </div>
          </div>
          <div className="dnb-section relative">
            <ContentLoader loading={avgLoader} />
            <div className="dnb-header-title">
              Current Week Vs Last Week (Avg. Daily Numbers)
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Current Week</div>
              {headers.map((v: any, key: any) => (
                <div className={`dnb-col`} key={key + "dnb-col"}>
                  {avgDailyNumbers.CurrDailyNumbers[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.CurrDailyNumbers?.RegionTotal}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.CurrDailyNumbers?.AreaTotal}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.CurrDailyNumbers?.["Sub Area Total"]}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.CurrDailyNumbers?.["Area Branch Total"]}
                  </div>
                )}
              <div className={`dnb-col`}>
                {avgDailyNumbers.CurrDailyNumbers?.CompanyTotal}
              </div>
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Last Week</div>
              {headers.map((v: any, key: any) => (
                <div className={`dnb-col`} key={key + "dnb-col"}>
                  {avgDailyNumbers.LastDailyNumbers[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.LastDailyNumbers?.RegionTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.LastDailyNumbers?.["Area Branch Total"] ??
                      0}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.LastDailyNumbers?.AreaTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div className={`dnb-col`}>
                    {avgDailyNumbers.LastDailyNumbers?.["Sub Area Total"] ?? 0}
                  </div>
                )}
              <div className={`dnb-col`}>
                {avgDailyNumbers.LastDailyNumbers?.CompanyTotal ?? 0}
              </div>
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Difference</div>
              {headers.map((v: any, key: any) => (
                <div
                  className={`dnb-col text-${
                    avgDailyNumbers.Difference[`${v}`] < 0 ? "red" : "green"
                  }`}
                  key={key + "dnb-col"}
                >
                  {avgDailyNumbers.Difference[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div
                    className={`dnb-col text-${
                      avgDailyNumbers.Difference?.RegionTotal < 0
                        ? "red"
                        : "green"
                    }`}
                  >
                    {avgDailyNumbers.Difference?.RegionTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div
                    className={`dnb-col text-${
                      avgDailyNumbers.Difference?.AreaTotal < 0
                        ? "red"
                        : "green"
                    }`}
                  >
                    {avgDailyNumbers.Difference?.AreaTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div
                    className={`dnb-col text-${
                      avgDailyNumbers.Difference?.["Sub Area Total"] < 0
                        ? "red"
                        : "green"
                    }`}
                  >
                    {avgDailyNumbers.Difference?.["Sub Area Total"] ?? 0}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div
                    className={`dnb-col text-${
                      avgDailyNumbers.Difference?.["Area Branch Total"] < 0
                        ? "red"
                        : "green"
                    }`}
                  >
                    {avgDailyNumbers.Difference?.["Area Branch Total"] ?? 0}
                  </div>
                )}
              <div
                className={`dnb-col text-${
                  avgDailyNumbers.Difference?.CompanyTotal < 0 ? "red" : "green"
                }`}
              >
                {avgDailyNumbers.Difference?.CompanyTotal ?? 0}
              </div>
            </div>
          </div>
          <div className="dnb-section relative">
            <ContentLoader loading={comparisonLoader} />
            <div className="dnb-header-title year-comparison-title">
              <label>Select Year</label>
              <div className="comparison-select-box">
                <select
                  value={yearComparisonValue}
                  onChange={(e) => setYearComparisonValue(e.target.value)}
                >
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
              </div>
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Current Week({moment().year()})</div>
              {headers.map((v: any, key: any) => (
                <div className={`dnb-col`} key={key + "dnb-col"}>
                  {comparison.CurrDailyNumbers[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div className={`dnb-col`}>
                    {comparison.CurrDailyNumbers?.RegionTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div className={`dnb-col`}>
                    {comparison.CurrDailyNumbers?.AreaTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div className={`dnb-col`}>
                    {comparison.CurrDailyNumbers?.["Sub Area Total"] ?? 0}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div className={`dnb-col`}>
                    {comparison.CurrDailyNumbers?.["Area Branch Total"] ?? 0}
                  </div>
                )}
              <div className={`dnb-col`}>
                {comparison.CurrDailyNumbers?.CompanyTotal ?? 0}
              </div>
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>
                Current Week ({yearComparisonValue})
              </div>
              {headers.map((v: any, key: any) => (
                <div className={`dnb-col`} key={key + "dnb-col"}>
                  {comparison.LastDailyNumbers[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div className={`dnb-col`}>
                    {comparison.LastDailyNumbers?.RegionTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div className={`dnb-col`}>
                    {comparison.LastDailyNumbers?.AreaTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div className={`dnb-col`}>
                    {comparison.LastDailyNumbers?.["Sub Area Total"] ?? 0}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div className={`dnb-col`}>
                    {comparison.LastDailyNumbers?.["Area Branch Total"] ?? 0}
                  </div>
                )}
              <div className={`dnb-col`}>
                {comparison.LastDailyNumbers?.CompanyTotal ?? 0}
              </div>
            </div>
            <div className="dnb-row">
              <div className={`dnb-col`}>Difference</div>
              {headers.map((v: any, key: any) => (
                <div
                  className={`dnb-col text-${
                    comparison.Difference[`${v}`] < 0 ? "red" : "green"
                  }`}
                  key={key + "dnb-col"}
                >
                  {comparison.Difference[`${v}`] ?? 0}
                </div>
              ))}
              {props.totalType === "Region" &&
                props.rawHeaders.includes("Region Total") && (
                  <div
                    className={`dnb-col text-${
                      comparison.Difference?.RegionTotal < 0 ? "red" : "green"
                    }`}
                  >
                    {comparison.Difference?.RegionTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Area" &&
                props.rawHeaders.includes("Area Total") && (
                  <div
                    className={`dnb-col text-${
                      comparison.Difference?.AreaTotal < 0 ? "red" : "green"
                    }`}
                  >
                    {comparison.Difference?.AreaTotal ?? 0}
                  </div>
                )}
              {props.totalType === "Subarea" &&
                props.rawHeaders.includes("VP Total") && (
                  <div
                    className={`dnb-col text-${
                      comparison.Difference?.["Sub Area Total"] < 0
                        ? "red"
                        : "green"
                    }`}
                  >
                    {comparison.Difference?.["Sub Area Total"] ?? 0}
                  </div>
                )}
              {props.totalType === "Area Branch" &&
                props.rawHeaders.includes("Area Branch Total") && (
                  <div
                    className={`dnb-col text-${
                      comparison.Difference?.["Area Branch Total"] < 0
                        ? "red"
                        : "green"
                    }`}
                  >
                    {comparison.Difference?.["Area Branch Total"] ?? 0}
                  </div>
                )}
              <div
                className={`dnb-col text-${
                  comparison.Difference?.CompanyTotal < 0 ? "red" : "green"
                }`}
              >
                {comparison.Difference?.CompanyTotal ?? 0}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-area"></div>
    </section>
  );
};
