/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const MONTH_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const REPORT_NAMES = {
  WEEKLY_REPORT: "weekly-report",
  COMPANY_SUMMARY_REPORT: "company-summary",
  AR_AGING_REPORT: "ar-aging",
  FINANCIAL_REPORT: "financial-report",
  DAILY_NUMBERS: "daily-numbers",
  PIP_SUMMARY: "executive-pip-summary",
  SALES_MANAGER: "sales-manager",
  ADASTAFF_INC: "ADASTAFF,Inc.",
  ASCEND_ADA_REPORTS: "Executive Reports",
};

export const LOCATION_LEVEL: any = {
  Company: 6,
  Area: 5,
  VP: 4,
  Region: 3,
  AreaBranch: 2,
  Branch: 1,
};
// export const LOCATION_LEVEL: any = {
//   Company: 5,
//   Area: 4,
//   VP: 3,
//   Region: 2,
//   Branch: 1,
// };

export const HOUSTON_BRANCHES = ["Houston CPI", "Houston PSG"];
