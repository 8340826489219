/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterByActiveHeader, headers } from "./utils/displayColumns";
import { Loader } from "../../common/Loader";
import { groupByKey } from "../../../utils/group";
import { setSortBy } from "../../../store/ArAging/actions";
import { sortByKey } from "../../../utils/helper/cellFormatter";
import { setLoading } from "../../../store/Filter/actions";
import {
  getSummedArData,
  sortGroupedData,
} from "../../../utils/helper/arAgingUtils";
import { ArRowGrandtotal } from "./ArRowGrandtotal";
import { ArRowSubtotal } from "./ArRowSubtotal";
import { ArRow } from "./ArRow";

export const ArTable = ({ multiSelect }: any) => {
  const arData = useSelector((state: any) => state.arAgingReducer.arData);
  const activeHeader = useSelector(
    (state: any) => state.arAgingReducer.viewType
  );
  const [groupedArData, setGroupedArData] = useState([] as any);
  const [isReverseSort, setIsReverseSort] = useState(false);
  const SORTING_HEADERS = [
    "Client Name",
    "Current",
    "Invoice Amount",
    "Payment Amount",
    "0-30 days",
    "31-60 days",
    "61-90 days",
    "Over 90 days",
    "Balance",
  ];
  const sortVal = useSelector((state: any) => state.arAgingReducer.sortBy);
  const [grandTotal, setGrandTotal] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setTableDatas();
  }, [activeHeader, arData, sortVal]);

  useEffect(() => {
    dispatch(
      setSortBy({
        type: "Client Name",
        isReverse: false,
      })
    );
    setTableDatas();
  }, []);

  useEffect(() => {
    setTableDatas();
  }, [isReverseSort]);

  const setTableDatas = () => {
    dispatch(setLoading(true));
    const rawGroupedData = Object.values(
      groupByKey(sortByKey(arData, sortVal), "OrganizationID")
    );
    const sortedRawGroupedData = sortGroupedData(rawGroupedData, sortVal);
    setGroupedArData(sortedRawGroupedData);
    if (arData) {
      setGrandTotal({
        ...getSummedArData(arData),
        Organization: "Total balance",
        OrganizationID: 0,
        DO: 0,
        DueDate: "",
        InvoiceDate: "",
        InvoiceID: 0,
        InvoiceNumber: "",
        LastInvoicePaymentDate: 0,
        LastPaymentDateClient: "",
        PaymentTerms: "",
      });
    }

    dispatch(setLoading(false));
  };

  const clientSummaryHidden = [
    "Invoice Number",
    "Invoice Date",
    "Due Date",
    "Invoice Amount",
    "Payment Amount",
  ];

  const isClientSummary = (activeHeader: string, header: string) => {
    if (activeHeader?.trim() === "Client Summary" && header === "Client Name") {
      return "is-summary";
    } else if (
      activeHeader?.trim() === "Client Summary" &&
      clientSummaryHidden.includes(header)
    ) {
      return "hidden";
    } else {
      return "";
    }
  };

  const changeReverseOrder = (header: string) => {
    if (SORTING_HEADERS.includes(header)) {
      dispatch(
        setSortBy({
          type: header,
          isReverse: header === sortVal.type ? !sortVal.isReverse : false,
        })
      );
      if (header === "Client Name") {
        setIsReverseSort(!isReverseSort);
      }
    }
  };

  const getIcon = (label: string) => {
    return sortVal.type !== label
      ? "remove"
      : sortVal.isReverse
      ? "arrow_drop_down"
      : "arrow_drop_up";
  };
  return (
    <section className={"ar-table"}>
      <Loader />
      <div className="ar-table-headers ar-bg-primary text-white flex flex-1 sticky-top w-fit lg:w-full">
        {headers.map((header, index: number) => (
          <div
            className={`flex align-center ar-table-header ar-table-col ${
              SORTING_HEADERS.includes(header.label) ? "pointer" : ""
            } ${isClientSummary(activeHeader, header.label)}`}
            key={index}
            onClick={() => changeReverseOrder(header.label)}
          >
            {SORTING_HEADERS.includes(header.label) && (
              <i className="material-icons">{getIcon(header.label)}</i>
            )}
            {header.label}
          </div>
        ))}
      </div>
      {groupedArData.map((valArr: any, index: number) => {
        return (
          <div className="w-fit lg:w-full">
            {activeHeader !== "Client Summary" &&
              filterByActiveHeader(activeHeader, valArr, multiSelect).map(
                (header: any, index: number) => (
                  <ArRow
                    key={index}
                    isClientSummary={isClientSummary}
                    activeHeader={activeHeader}
                    header={header}
                    multiSelect={multiSelect}
                  />
                )
              )}
            {filterByActiveHeader(
              activeHeader,
              [getSummedArData(valArr)],
              multiSelect
            ).map((header: any, index: number) => (
              <ArRowSubtotal
                key={index}
                isClientSummary={isClientSummary}
                activeHeader={activeHeader}
                header={header}
                multiSelect={multiSelect}
              />
            ))}
          </div>
        );
      })}
      {filterByActiveHeader(activeHeader, [grandTotal], multiSelect).map(
        (header: any, index: number) => (
          <ArRowGrandtotal
            key={index}
            isClientSummary={isClientSummary}
            activeHeader={activeHeader}
            header={header}
            multiSelect={multiSelect}
          />
        )
      )}
    </section>
  );
};
