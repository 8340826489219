/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { PostRequest } from "../plugins/https";

export const graphConfig = {
  graphGroupEndpoint:
    "https://graph.microsoft.com/v1.0/me/memberOf/$/microsoft.graph.group?$select=id,displayName&$top=999",
};

export async function getUserGroups(accessToken: any) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };
  let allGroups: any = [];
  let nextLink = graphConfig.graphGroupEndpoint;
  while (nextLink) {
    try {
      const response = await fetch(nextLink, options);
      const data = await response.json();

      if (response.status === 200 || response.status === 201) {
        allGroups = allGroups.concat(data.value);
        nextLink = data["@odata.nextLink"] || null;
      } else {
        console.log("Error fetching groups");
        break;
      }
    } catch (error: any) {
      console.log("Error fetching groups");
      break;
    }
  }
  return { value: allGroups };
}

export async function getAllUserReports(params: any) {
  return PostRequest("/filter/reports", params);
}
