/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { displayColumns, headers } from "./utils/displayColumns";
import { formatArAGingCell } from "../../../utils/helper/cellFormatter";
import { useDispatch } from "react-redux";
import {
  setArCommentBranch,
  setArCommentDialog,
} from "../../../store/ArAging/actions";

export const ArRowGrandtotal = (props: any) => {
  const dispatch = useDispatch();
  const openCommentDialog = (
    header: { label: string; value: string },
    branch: string
  ) => {
    if (header.label === "Client Name") {
      dispatch(setArCommentBranch(branch));
      dispatch(setArCommentDialog(true));
    }
  };
  return (
    <div
      className={`ar-table-row flex grand-total w-fit lg:w-full`}
      style={{
        marginBottom: props.activeHeader === "Client Summary" ? "0" : "12px",
      }}
    >
      {headers.map((v: any, key: number) => (
        <div
          className={`ar-table-col ${props.isClientSummary(
            props.activeHeader,
            v.label
          )} ${v.value === "Organization" ? "pointer" : ""}`}
          key={key}
        >
          {formatArAGingCell(
            key,
            displayColumns(
              "SubTotal",
              v.value,
              props.header[v.value],
              props.multiSelect
            )
          )}
        </div>
      ))}
    </div>
  );
};
