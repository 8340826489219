/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import axios, { sageAxios } from "./axios";

export const PostRequest = (url: string, params: any, config = {}) => {
  return axios.post(url, params, config);
};

export const GetRequest = (url: string, config: any = {}) => {
  return axios.get(url, config);
};

export const DeleteRequest = (url: string, config = {}) => {
  return axios.delete(url, config);
};

export const PutRequest = (url: string, params: any, config = {}) => {
  return axios.put(url, params, config);
};

export const SagePostRequest = (url: string, params: any, config = {}) => {
  return sageAxios.post(url, params, config);
};

export const SageGetRequest = (url: string, config: any = {}) => {
  return sageAxios.get(url, config);
};

export const SageDeleteRequest = (url: string, config = {}) => {
  return sageAxios.delete(url, config);
};
export const SagePutRequest = (url: string, params: any, config = {}) => {
  return sageAxios.put(url, params, config);
};
