/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const SET_FINANCIAL_BRANCHES = "SET_FINANCIAL_BRANCHES";
export const SET_FINANCIAL_YEARS = "SET_FINANCIAL_YEARS";
export const SET_REPORT_DATA = "SET_REPORT_DATA";
export const SET_REPORT_HEADER = "SET_REPORT_HEADER";
export const SET_YEAR = "SET_YEAR";
export const SET_MONTH = "SET_MONTH";
export const SET_STRUCTURE = "SET_STRUCTURE";
export const SET_FINANCIAL_LOCATION = "SET_FINANCIAL_LOCATION";
