/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { displayColumns, headers } from "./utils/displayColumns";
import { formatArAGingCell } from "../../../utils/helper/cellFormatter";

// @ts-ignore
export const ArRow = (props) => {
  return (
    <div className={`ar-table-row flex`}>
      {headers.map((v: any, key: number) => (
        <div
          className={`ar-table-col ${props.isClientSummary(
            props.activeHeader,
            v.label
          )}`}
          key={key}
        >
          {formatArAGingCell(
            key,
            displayColumns(
              props.activeHeader,
              v.value,
              props.header[v.value],
              props.multiSelect
            )
          )}
        </div>
      ))}
    </div>
  );
};
