/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Dispatch } from "redux";
import { groupByKey } from "../../utils/group";
import {
  SET_PIP_BRANCHES,
  SET_PIP_BRANCHES_LIST,
  SET_PIP_BRANCH_DATA,
  SET_PIP_DISTRICT_DATA,
  SET_PIP_MILESTONE_DATA,
  SET_PIP_REGIONS,
  SET_PIP_REGION_DATA,
} from "./actionTypes";
import {
  APIGetPipMilestonesByBranches,
  APIGetPipMilestonesByRegion,
  APIGetPipSummaryByBranches,
  APIGetPipSummaryByRegion,
} from "../../api/pip-summary";
import { setLoading } from "../Filter/actions";

export const setPipMilestoneData = (payload: any) => {
  return {
    type: SET_PIP_MILESTONE_DATA,
    payload: payload,
  };
};
export const setPipRegionsData = (payload: any) => {
  return {
    type: SET_PIP_REGION_DATA,
    payload: payload,
  };
};
export const setPipDistrictsData = (payload: any) => {
  return {
    type: SET_PIP_DISTRICT_DATA,
    payload: payload,
  };
};
export const setPipBranchesData = (payload: any) => {
  return {
    type: SET_PIP_BRANCH_DATA,
    payload: payload,
  };
};
export const setPipRegions = (payload: any) => {
  return {
    type: SET_PIP_REGIONS,
    payload: payload,
  };
};
export const setPipBranches = (payload: any) => {
  return {
    type: SET_PIP_BRANCHES,
    payload: payload,
  };
};
//Branch Slicer
export const setPipBranchesList = (payload: any) => {
  return {
    type: SET_PIP_BRANCHES_LIST,
    payload: payload,
  };
};

export const loadPipSummaryData =
  (executive: boolean, regions?: any, branches?: any) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    const res =
      executive && !(regions.length > 0)
        ? await Promise.all([
            APIGetPipSummaryByBranches(),
            APIGetPipMilestonesByBranches(),
          ])
        : regions.length > 0
        ? await Promise.all([
            APIGetPipSummaryByRegion(regions), //if there is access for regions(Region manager)
            APIGetPipMilestonesByRegion(regions),
          ])
        : await Promise.all([
            APIGetPipSummaryByRegion(branches), //if there is access for only branches and not regions(Branch manager)
            APIGetPipMilestonesByRegion(branches),
          ]);
    const sumamryData = res[0].data;
    const milestoneData = res[1].data;

    dispatch(
      setPipMilestoneData({
        region: milestoneData.region,
        district: milestoneData.district,
        legacy_branch: milestoneData.branch.filter(
          (b: any) => b.BranchType === "Legacy"
        ),
        new_branch: milestoneData.branch.filter(
          (b: any) => b.BranchType === "New"
        ),
      })
    );
    dispatch(setPipRegionsData(groupByKey(sumamryData.region, "Region")));
    dispatch(setPipDistrictsData(groupByKey(sumamryData.district, "District")));
    dispatch(setPipBranchesData(groupByKey(sumamryData.branch, "Branch")));
    dispatch(
      setPipBranchesList(milestoneData.branch.map((d: any) => d.Location))
    );
    dispatch(setLoading(false));
  };
