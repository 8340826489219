/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import moment from "moment";
import {
  SET_AR_DATA,
  SET_AR_AS_OF_DATE,
  SET_AR_COLUMNS_DISPLAYED,
  SET_AR_VIEW_TYPE,
  SET_CORPORATION,
  SET_SORT_BY,
  SET_AR_COMMENT_BRANCH,
  SET_AR_COMMENT_DIALOG,
  SET_AR_EXPORT_DIALOG,
} from "./actionTypes";
import { SET_MULTI_PAYMENT_TERMS } from "../Filter/actionTypes";

const initialState = {
  corporations: [],
  viewType: "",
  columns: [],
  arData: [],
  dueDate: moment(new Date()).format("YYYY-MM-DD"),
  sortBy: {
    type: "Client Name",
    reverse: true,
  },
  commentDialog: false,
  exportDialog: false,
  arCommentBranch: "Foster farms",
  multiPaymentTerms: ["1-30 Days"],
};

export const arAgingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_AR_AS_OF_DATE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_AR_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_AR_COMMENT_BRANCH:
      return {
        ...state,
        arCommentBranch: action.payload,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case SET_CORPORATION:
      return {
        ...state,
        corporations: action.payload,
      };
    case SET_AR_COLUMNS_DISPLAYED:
      return {
        ...state,
        columns: [...action.payload],
      };
    case SET_MULTI_PAYMENT_TERMS:
      return {
        ...state,
        multiPaymentTerms: [...action.payload],
      };
    case SET_AR_DATA:
      return {
        ...state,
        arData: action.payload,
      };
    case SET_AR_COMMENT_DIALOG:
      return {
        ...state,
        commentDialog: action.payload,
      };
    case SET_AR_EXPORT_DIALOG:
      return {
        ...state,
        exportDialog: action.payload,
      };
    default:
      return state;
  }
};
