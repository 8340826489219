/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  SET_AR_AS_OF_DATE,
  SET_AR_COMMENT_BRANCH,
  SET_AR_COMMENT_DIALOG,
  SET_AR_DATA,
  SET_AR_EXPORT_DIALOG,
  SET_AR_VIEW_TYPE,
  SET_CORPORATION,
  SET_SORT_BY,
} from "./actionTypes";
import {
  APIGetADADueDateData,
  APIGetADAInvoiceDateData,
  APIGetAllADACorporations,
  APIGetAllCorporations,
  APIGetAllDueDateData,
  APIGetAllInvoiceDateData,
  APIGetDueDateData,
  APIGetInvoiceDateData,
} from "../../api/ar-aging";
import { SET_MULTI_PAYMENT_TERMS } from "../Filter/actionTypes";

const setArAsOfDate = (payload: string) => {
  return {
    type: SET_AR_AS_OF_DATE,
    payload,
  };
};

export const setArCommentBranch = (payload: string) => {
  return {
    type: SET_AR_COMMENT_BRANCH,
    payload,
  };
};

export const setArCommentDialog = (payload: boolean) => {
  return {
    type: SET_AR_COMMENT_DIALOG,
    payload,
  };
};

export const setArExportDialog = (payload: boolean) => {
  return {
    type: SET_AR_EXPORT_DIALOG,
    payload,
  };
};

export const setArViewType = (payload: string) => {
  return {
    type: SET_AR_VIEW_TYPE,
    payload,
  };
};

const setArCorporation = (payload: any) => {
  return {
    type: SET_CORPORATION,
    payload,
  };
};

const setArData = (payload: string) => {
  return {
    type: SET_AR_DATA,
    payload,
  };
};

export const setSortBy = (payload: { type: string; isReverse: boolean }) => {
  return {
    type: SET_SORT_BY,
    payload,
  };
};

export const setMultiPaymentItems = (payload: string[]) => {
  return {
    type: SET_MULTI_PAYMENT_TERMS,
    payload,
  };
};

export const loadCorporations = (selectedLevel: string) => {
  return async (dispatch: any) => {
    const res =
      selectedLevel === "ADA"
        ? await APIGetAllADACorporations()
        : await APIGetAllCorporations();
    dispatch(
      setArCorporation([
        { organization: "All", office: "All", organizationid: -1 },
        ...res.data,
      ])
    );
  };
};

export const loadArAgingData = (
  date: any,
  organizationid: any,
  selectedLevel: string
) => {
  return async (dispatch: any) => {
    const res =
      selectedLevel === "ADA"
        ? await APIGetADAInvoiceDateData(date, organizationid)
        : ["", "all"].includes(organizationid.toLowerCase())
        ? await APIGetAllInvoiceDateData(date)
        : await APIGetInvoiceDateData(date, organizationid);
    dispatch(setArData(res.data));
  };
};

export const loadArAgingDueDateData = (
  date: any,
  organizationid: any,
  selectedLevel: string
) => {
  return async (dispatch: any) => {
    const res =
      selectedLevel === "ADA"
        ? await APIGetADADueDateData(date, organizationid)
        : ["", "all"].includes(organizationid.toLowerCase())
        ? await APIGetAllDueDateData(date)
        : await APIGetDueDateData(date, organizationid);
    dispatch(setArData(res.data));
  };
};
