/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  setShowBranchClientsRanking,
  setShowClientsRanking,
} from "../../../store/CompanySummary/actions";
import { CS_BRANCHES_TOP } from "../companySummary/utils/constants";
import { groupByKey } from "../../../utils/group";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { APIGetCompanySummaryRegions } from "../../../api/company-summary";

const getHierarchy = (data: any) => {
  let subAreaHierarchy = {};
  Object.keys(data).forEach((v: any) => {
    let temp = groupByKey(data[v], "Sub Area Name");
    let subHier = {};
    for (let v in temp) {
      subHier = {
        ...subHier,
        [v]: Object.values(temp[v]).map((v: any) => ({
          Location: v.Location,
          Title: v.Title,
        })),
      };
    }
    subAreaHierarchy = {
      ...subAreaHierarchy,
      [v]: subHier,
    };
  });
  return subAreaHierarchy;
};

const SalesActivitySelector = (props: any) => {
  const dispatch = useDispatch();
  const {
    setLevel,
    hierarchy,
    setHierarchy,
    setBranchesList,
    areaList,
    setAreaList,
    subAreaList,
    setSubAreaList,
    regionsList,
    setRegionsList,
    selectedLevelBranch,
    setSelectedLevelBranch,
    areaSelected,
    setAreaSelected,
    setRegionSelected,
    subAreaSelected,
    setTotalType,
    setSelectedBranchName,
    setSelectedClientName,
    setSelectedDate,
  } = props;
  const [rawData, setRawData] = useState([]);

  const loadRegions = async () => {
    const res: any = await APIGetCompanySummaryRegions();
    const resWithFixesSubArea = res.data.map((v: any) => ({
      ...v,
      "Sub Area Name": v["Sub Area Name"] ?? "Ungrouped",
    }));
    setRawData(resWithFixesSubArea);
    const regionTemp = Object.values(
      groupByKey(resWithFixesSubArea, "Location")
    ).map((v: any) => ({
      Location: v[0].Location,
      Title: v[0].Title,
    }));
    const hierarchyTemp = groupByKey(resWithFixesSubArea, "Areaname");
    let subAreaHierarchy = getHierarchy(hierarchyTemp);
    setHierarchy(subAreaHierarchy);
    const areaTemp: any = Object.keys(hierarchyTemp) ?? [];
    setAreaList(areaTemp);
    setSubAreaList(
      Object.keys(groupByKey(resWithFixesSubArea, "Sub Area Name"))
    );
    setRegionsList(regionTemp ?? []);
    setBranchesList(res.data.map((v: any) => v.branch));
  };

  useEffect(() => {
    loadRegions();
  }, []);

  const setBranch = (level: string, branch: any) => {
    setLevel(level);
    setTotalType(branch.Title === "Subarea" ? "VP" : branch.Title);
    setSelectedLevelBranch(branch.Location);
    dispatch(setShowClientsRanking(false));
    dispatch(setShowBranchClientsRanking(false));
  };

  const setAreaLevel = (branch: string) => {
    setAreaSelected(branch);
    setLevel("Area");
    setBranch("Area", { Location: branch, Title: "Area" });
    setSubAreaList(
      Object.keys(hierarchy[branch]).filter((v) => v !== "Ungrouped")
    );
    setRegionsList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "Location")
      ).map((v: any) => ({
        Location: v[0].Location,
        Title: v[0].Title,
      })) ?? []
    );
    setBranchesList(
      rawData.filter((v) => v["Areaname"] === branch).map((v: any) => v.branch)
    );
    setSelectedBranchName("All");
    setSelectedClientName("All");
    setSelectedDate({
      startdate: null,
      enddate: null,
    });
  };

  const setSubareaLevel = (branch: string) => {
    setBranch("Subarea", { Location: branch, Title: "Subarea" });
    setRegionsList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "Location"
        )
      ).map((v: any) => ({
        Location: v[0].Location,
        Title: v[0].Title,
      })) ?? []
    );
    setBranchesList(
      rawData
        .filter((v) => v["Sub Area Name"] === branch)
        .map((v: any) => v.branch)
    );
    setSelectedBranchName("All");
    setSelectedClientName("All");
    setSelectedDate({
      startdate: null,
      enddate: null,
    });
  };

  const setRegionLevel = (branch: any) => {
    setRegionSelected(branch);

    setLevel("Region");
    setBranch("Region", { Location: branch.Location, Title: branch.Title });
    setBranchesList(
      rawData
        .filter((v) => v["Location"] === branch.Location)
        .map((v: any) => v.branch)
    );
    setSelectedBranchName("All");
    setSelectedClientName("All");
    setSelectedDate({
      startdate: null,
      enddate: null,
    });
  };

  const setCompanyLevel = () => {
    setLevel("Company");
    setBranch("Company", { Location: "Company", Title: "Company" });
    setSubAreaList(Object.keys(groupByKey(rawData, "Sub Area Name")));
    setRegionsList(
      Object.values(groupByKey(rawData, "Location")).map((v: any) => ({
        Location: v[0].Location,
        Title: v[0].Title,
      }))
    );
    setBranchesList(rawData.map((v: any) => v.branch));
    setSelectedBranchName("All");
    setSelectedClientName("All");
    setSelectedDate({
      startdate: null,
      enddate: null,
    });
  };

  return (
    <div className="flex flex-col 2xl:gap-2 gap-1 h-fit pb-2 2xl:mt-2 border-b-[1px]  items-center ">
      {" "}
      <div className="top-hierarchy flex justify-center">
        {CS_BRANCHES_TOP.map(
          (v, key) =>
            v.label && (
              <div
                key={key}
                className={`cursor-pointer px-[0.7rem] py-[0.2rem] 2xl:py-[0.4rem]  border-[1px] rounded-md text-[0.6rem] sm:text-[0.8rem] shadow-sm  ${
                  selectedLevelBranch === v.label
                    ? "bg-[#80b5d6] text-white"
                    : "bg-white hover:bg-[#eff8fe]"
                }`}
                onClick={() => setCompanyLevel()}
              >
                {v.label}
              </div>
            )
        )}
      </div>
      <div className="mid-hierarchy flex gap-4 justify-center">
        {areaList.map(
          (v: any, key: number) =>
            v && (
              <div
                key={key}
                className={`cursor-pointer px-[0.7rem] py-[0.2rem] 2xl:py-[0.4rem] border-[1px] rounded-md text-[0.6rem] sm:text-[0.8rem] shadow-sm  ${
                  areaSelected === v.Location ? "bg-gray-500 " : ""
                } ${
                  selectedLevelBranch === v
                    ? "bg-[#80b5d6] text-white"
                    : "bg-white hover:bg-[#eff8fe]"
                }`}
                onClick={() => setAreaLevel(v)}
              >
                {v}
              </div>
            )
        )}
      </div>
      {/* {subAreaList.length > 0 && (
        <div className="mid-hierarchy flex justify-center">
          {subAreaList.map(
            (v: any, key: number) =>
              v &&
              v !== "Ungrouped" && (
                <div
                  key={key}
                  className={`cursor-pointer px-[0.6rem] py-[0.2rem] 2xl:py-[0.4rem] border-[1px] rounded-md text-[0.6rem] sm:text-[0.8rem]  ${
                    subAreaSelected === v ? "bg-gray-500 " : ""
                  } ${
                    selectedLevelBranch === v
                      ? "bg-[#80b5d6] text-white"
                      : "bg-white hover:bg-green-50"
                  }`}
                  onClick={() => setSubareaLevel(v)}
                >
                  {v}
                </div>
              )
          )}
        </div>
      )} */}
      <div className="low-hierarchy flex gap-2 justify-center">
        {regionsList.map(
          (v: any, key: any) =>
            v.Location && (
              <div
                key={key}
                className={`cursor-pointer px-[0.7rem] py-[0.2rem] 2xl:py-[0.4rem] border-[1px] rounded-md text-[0.6rem] sm:text-[0.8rem] shadow-sm  ${
                  selectedLevelBranch === v.Location
                    ? "bg-[#80b5d6] text-white"
                    : "bg-white hover:bg-[#eff8fe]"
                }`}
                onClick={() => setRegionLevel(v)}
              >
                {v.Location}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default SalesActivitySelector;
