/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Table } from "@mantine/core";
import { useSelector } from "react-redux";

export const Top20ClientQBR = ({ tableData }: any) => {
  const loading = useSelector((state: any) => state.filterReducer.loading);

  return (
    <Table fontSize="xs" verticalSpacing={2} className="w-full">
      <thead className="sticky top-0 bg-red-50">
        <tr>
          <th className="w-[10rem]">Date</th>
          <th className="w-[10rem]">User</th>
          <th className="w-[10rem]">Client</th>
          <th className="w-[10rem]">Comment Details</th>
        </tr>
      </thead>
      <tbody>
        {!loading &&
          (tableData?.length > 0 ? (
            tableData.map((data: any, ind: any) => {
              return (
                <tr key={data.OrganizationId + ind + Math.random()}>
                  <td>{data.Date}</td>
                  <td>{data.User}</td>
                  <td>{data.Organization}</td>
                  <td>QBR</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5}>
                <div className="flex items-center justify-center text-sm">
                  No data available
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
