/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import * as actions from "./actionTypes";

export const initialState = {
  enableTooltip: true,
  showClientsRanking: false,
  showBranchClientsRanking: false,
  showPipSummaryLines: true,
};

interface IAction {
  type: String;
  payload: boolean;
}

export const companySummaryReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case actions.SET_ENABLE_TOOLTIP:
      return {
        ...state,
        enableTooltip: action.payload,
      };
    case actions.SET_SHOW_CLIENTS_RANKING:
      return {
        ...state,
        showClientsRanking: action.payload,
      };
    case actions.SET_SHOW_BRANCH_CLIENTS_RANKING:
      return {
        ...state,
        showBranchClientsRanking: action.payload,
      };
    case actions.SET_SHOW_PIP_SUMMARY_LINES:
      return {
        ...state,
        showPipSummaryLines: action.payload,
      };
    default:
      return state;
  }
};
