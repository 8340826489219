/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import { GetRequest } from "../plugins/https";

export const APIFetchClientCommentsMetrics = (
  area: any,
  subArea: any,
  region: any,
  district: any
) => {
  return GetRequest("/branch-top-focus/client-comment", {
    params: { area, subArea, region, district },
  });
};
export const APIFetchClientCommentsCompanyLevel = () => {
  return GetRequest("/branch-top-focus/company/client-comment");
};
export const APIFetchClientCommentsADA = () => {
  return GetRequest("/branch-top-focus/client-comment-ada");
};

export const APIFetchEmployeePlaced = (
  area: any,
  subArea: any,
  region: any,
  district: any
) => {
  return GetRequest("/branch-top-focus/employee-placed", {
    params: { area, subArea, region, district },
  });
};
export const APIFetchEmployeePlacedCompanyLevel = () => {
  return GetRequest("/branch-top-focus/company/employee-placed");
};
export const APIFetchEmployeePlacedADA = () => {
  return GetRequest("/branch-top-focus/employee-placed-ada");
};

export const APIFetchBarChartAndLineChart = (
  branch: any,
  area: any,
  subArea: any,
  region: any,
  district: any
) => {
  return GetRequest("/branch-top-focus/barchart-linechart", {
    params: { branch, area, subArea, region, district },
  });
};
export const APIFetchBarChartAndLineChartCompany = () => {
  return GetRequest("/branch-top-focus/company/barchart-linechart");
};
export const APIFetchBarChartAndLineChartADA = () => {
  return GetRequest("/branch-top-focus/barchart-linechart-ada");
};
export const APIFetchBarChartLineChartADABranches = (branch: any) => {
  return GetRequest("/branch-top-focus/barchart-linechart-ada-branch", {
    params: { branch },
  });
};

export const APIClientCommentTooltip = (
  branch: any,
  startDate: any,
  endDate: any
) => {
  return GetRequest("/branch-top-focus/client-comment/tooltip", {
    params: { branch, startDate, endDate },
  });
};
export const APIClientCommentTooltipBarChart = (
  branch: any,
  startDate: any,
  endDate: any
) => {
  return GetRequest("/branch-top-focus/client-comment/tooltip/barchart", {
    params: { branch, startDate, endDate },
  });
};
export const APIClientCommentTooltipBarChartADA = (
  branch: any,
  startDate: any,
  endDate: any
) => {
  return GetRequest("/branch-top-focus/client-comment/tooltip/barchart-ada", {
    params: { branch, startDate, endDate },
  });
};

export const APIFetchBranch = (selectedBranch: any) => {
  return GetRequest(`/branch-top-focus/branch`, {
    params: { selectedBranch },
  });
};

export const APIFetchADABranches = () => {
  return GetRequest(`/branch-top-focus/ada-branches`);
};
