/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from 'react-apexcharts';
import {Component, useEffect, useState} from "react";
import {groupByKey} from "../../../utils/group";
import {ContentLoader} from "../../common/Loader";

const DailyNumberRegionChart = (props) => {
    const [axisValues, setAxisValues] = useState([]);
    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: [
            "#01B8AA",
            "#364548",
            "#FD625E",
            "#F0CB1F",
            "#FE9564",
            "#89D4EB",
            "#8bb550",
        ],
        stroke: {
            width: 2,
            curve: 'straight',
        },
        title: {},
        legend: {
            position: 'top',
            show: true,
            horizontalAlign: 'center',
            tooltipHoverFormatter: function (val, opts) {
                return val;
            }
        },
        markers: {
            size: 3,
            strokeWidth: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: [...axisValues],
            labels: {
                show: false
            }
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    fontSize: '7px'
                },
            }
        },
        tooltip: {},
        grid: {
            borderColor: '#f1f1f1',
        }
    });
    // const series = ;
    useEffect(() => {
        if (props.data) {
            setChartData();
        }
        return;
    }, [props.data]);

    const setChartData = () => {
        const tempSeries = Object.keys(groupByKey(props.data, 'LocationName'));
        let areaObj = {}
        tempSeries.forEach(v => {
            let lastYrData = {};
            props.data.filter(loc => loc.LocationName === v).forEach(v => {
                lastYrData = {
                    ...lastYrData,
                    [v.StartDate]: v.DailyNumbers
                };
            });
            areaObj = {
                ...areaObj,
                ...{
                    [v]: axisValues.map(v => lastYrData[v] ?? null),
                }
            }
        });

        setSeries(Object.values(areaObj).map((val, key) => ({
            name: Object.keys(areaObj)[key],
            data: val
        })));
        setOptions({
            ...options,
            xaxis: {
                labels: {
                    show: true
                },
                categories: [...axisValues]
            },
        })
    }

    useEffect(() => {
        if (props.data.length) {
            setAxisValues(Object.keys(groupByKey(props.data, 'StartDate')));
        }
    }, [props.data]);

    useEffect(() => {
        setChartData();
    }, [axisValues])


    return <div className={"daily-number-area-chart flex relative"}>
        <div className="chart-area flex flex-grow relative">
            <ContentLoader loading={props.loading}/>
            <div className={"h-full flex-grow"}>
                <ApexChart options={options} series={series}/>
            </div>
        </div>
        <div className="filter-area"></div>
    </div>
}

// @ts-ignore
class ApexChart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // @ts-ignore
        return <>
            <ReactApexChart
                options={this.props.options}
                series={this.props.series}
                type="line"
                height={350}
            />
        </>
    }
}

export default DailyNumberRegionChart;