/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  SET_FINANCIAL_BRANCHES,
  SET_FINANCIAL_LOCATION,
  SET_FINANCIAL_YEARS,
  SET_MONTH,
  SET_REPORT_DATA,
  SET_REPORT_HEADER,
  SET_STRUCTURE,
  SET_YEAR,
} from "./actionTypes";
import {
  APIGetFinancialBranches,
  APIGetFinancialYears,
  APIRefreshDataFinancialYears,
  APIGetSageFinancialData,
  APIGetAllBranches,
  APIGetSageFinancialDataWipTest,
  APIGetSageFinancialDataTest,
} from "../../api/financial-report";

const setBranches = (branches: String[]) => {
  return {
    type: SET_FINANCIAL_BRANCHES,
    payload: branches,
  };
};

export const setFinancialData = (data: any) => {
  return {
    type: SET_REPORT_DATA,
    payload: data,
  };
};

export const setFinancialHeader = (data: any) => {
  return {
    type: SET_REPORT_HEADER,
    payload: data,
  };
};
const setYears = (years: String[]) => {
  return {
    type: SET_FINANCIAL_YEARS,
    payload: years,
  };
};
export const setYearSelected = (year: String) => {
  return {
    type: SET_YEAR,
    payload: year,
  };
};

export const setMonthSelected = (month: String) => {
  return {
    type: SET_MONTH,
    payload: month,
  };
};

export const setStructure = (structure: any) => {
  return {
    type: SET_STRUCTURE,
    payload: structure,
  };
};

export const setFinancialLocation = (location: any) => {
  return {
    type: SET_FINANCIAL_LOCATION,
    payload: location,
  };
};

export const getFinancialStructure = () => async (dispatch: any) => {
  const res = await APIGetAllBranches();
  dispatch(
    setStructure({
      primaryBranch: res.data["0"],
      secondaryBranch: res.data["1"],
      tertiaryBranch: res.data["2"],
      quarternaryBranch: res.data["3"],
      quinaryBranch: res.data["4"],
    })
  );
};

export const getFinancialBranches = () => async (dispatch: any) => {
  const res = await APIGetFinancialBranches();
  dispatch(setBranches(res.data));
};
//
// export const getFinancialData = (branch:string,year:string) => async (dispatch: any) => {
//     const res = await APIGetFinancialData(branch,year);
//     dispatch(setFinancialData(res.data));
// }

export const getFinancialData =
  (
    branch: string,
    year: string,
    month: string,
    wip?: boolean,
    test?: boolean
  ) =>
  async (dispatch: any) => {
    const res: any = test
      ? await APIGetSageFinancialDataTest({ branch, year, month })
      : wip
      ? await APIGetSageFinancialDataWipTest({ branch, year, month })
      : await APIGetSageFinancialData({ branch, year, month });
    dispatch(setFinancialData(res?.data));
    dispatch(setFinancialHeader(res?.header));
  };

export const getPostFinancialData =
  (branch: string, year: string, month: string) => async (dispatch: any) => {
    const res = await APIGetSageFinancialData({ branch, year, month });
    dispatch(setFinancialData(res.data));
  };

export const getFinancialYears = () => async (dispatch: any) => {
  const res = await APIGetFinancialYears();
  dispatch(setYears(res.data.map((item: any) => item["Year"]).sort()));
};

export const refreshFinancialData =
  (branch: string, year: string) => async (dispatch: any) => {
    const res = await APIRefreshDataFinancialYears(branch, year);
    dispatch(setFinancialData(res.data));
  };
