/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";

const BarChart = (props: any) => {
  const { barchartData, linechartData, selectedYear } = props;

  barchartData.sort((a: any, b: any) => {
    const weekA = parseInt(a.name.replace("wk", ""));
    const weekB = parseInt(b.name.replace("wk", ""));
    return weekA - weekB;
  });
  linechartData.sort((a: any, b: any) => {
    const weekA = parseInt(a.name.replace("wk", ""));
    const weekB = parseInt(b.name.replace("wk", ""));
    return weekA - weekB;
  });

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },

    colors: ["#5097b5", "#fc9330"],
    plotOptions: {
      bar: {
        columnWidth: "70%",
        dataLabels: {
          position: "bottom",
        },
      },
      line: {
        dataLabels: {
          position: "top",
        },
      },
    },
    fill: {
      colors: ["#5097b5"],
      opacity: 1,
    },
    title: {
      text: "",
    },
    markers: {
      size: 2,
      strokeColors: "#fc9330",

      fillOpacity: 1,
      shape: "circle",
      hover: {
        sizeOffset: 3,
      },
    },

    series: [
      {
        name: "Gross Profit",
        type: "column",
        data: [],
      },
      {
        name: "Revenue",
        type: "line",
        data: [],
      },
    ],
    stroke: {
      width: [0, 2],
      curve: "straight",
      colors: ["#fc9330"],
    },
    // dataLabels: {
    //   enabled: true,
    //   style: {
    //     colors: ["#000"],
    //   },
    // },
    dataLabels: {
      enabledOnSeries: selectedYear === 2023 ? [1] : [0, 1],
      formatter: function (value: any) {
        return "$" + Math.round(value / 1000) + "k";
      },
      style: {
        fontSize: "12px",
        colors: ["#a2bfdb", "#fc9330"],
      },
      background: {
        enabled: true,
        // foreColor: "#000",
        padding: 4,
        opacity: 0.9,
      },
      offsetY: -10,
    },
    legend: {
      show: false,
    },

    yaxis: [
      {
        labels: {
          style: {
            colors: "#000000",
          },
          formatter: function (value: any) {
            return Math.round(value / 1000) !== 0
              ? "$" + Math.round(value / 1000) + "k"
              : "$" + Math.round(value);
          },
        },
        title: {
          text: "Gross Profit",
        },
      },
      {
        opposite: true,

        labels: {
          show: false,
          formatter: function (value: any) {
            return "$" + Math.round(value / 1000) + "k";
          },
        },
      },
    ],

    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: (name: any, value: any) => name + ":",
        },
        formatter: (value: any) => {
          return "$" + Math.round(value);
        },
      },
    },
  });

  useEffect(() => {
    if (
      barchartData &&
      barchartData?.length > 0 &&
      linechartData &&
      linechartData?.length > 0
    ) {
      const seriesBarData = barchartData.map((data: any) => ({
        x: data.name,
        y: data.total.toFixed(2),
      }));
      const seriesLineData = linechartData.map((data: any) => ({
        x: data.name,
        y: Math.round(data.total),
      }));
      const maxProfit = Math.max(...barchartData.map((d: any) => d.total));
      const maxRevenue = Math.max(...linechartData.map((d: any) => d.total));

      setChartOptions({
        ...chartOptions,
        dataLabels: { enabledOnSeries: selectedYear === 2024 ? [0, 1] : [1] },
        series: [
          { type: "column", data: seriesBarData },
          { type: "line", data: seriesLineData },
        ],
        yaxis: [
          {
            labels: {
              style: {
                colors: "#000000",
              },
              formatter: function (value: any) {
                return Math.round(value / 1000) !== 0
                  ? "$" + Math.round(value / 1000) + "k"
                  : "$" + Math.round(value);
              },
            },
            title: {
              text: "Gross Profit",
            },
            max: maxProfit,
            min: 0,
          },
          {
            opposite: true,

            labels: {
              show: false,
              formatter: function (value: any) {
                return "$" + Math.round(value / 1000) + "k";
              },
            },
            max: maxRevenue,
            min: 0,
          },
        ],
      });
    }
  }, [barchartData, linechartData, selectedYear]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartOptions.series}
      type="bar"
      height={270}
    />
  );
};

export default BarChart;
