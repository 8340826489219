/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Dispatch } from "redux";
import {
  APIGetADAWeeklyData,
  APIGetADAWeeklyDataSecond,
  APIGetADAWeeklyDataThird,
  APIGetADAWeeklyManagers,
  APIGetWeeklyData,
  APIGetWeeklyDataSecond,
  APIGetWeeklyDataThird,
  APIGetWeeklyManagers,
} from "../../api/weekly-report";
import { groupByKey } from "../../utils/group";
import {
  convertToTableData,
  convertToTableDataManagers,
} from "../../utils/groupReportData";
import { setHeaders } from "../Filter/actions";
import { SET_WEEKLY_REPORT_DATA } from "./actionTypes";

export const setAllReportData = (payload: any) => {
  return {
    type: SET_WEEKLY_REPORT_DATA,
    payload: payload,
  };
};

export const loadWeeklyReportData =
  (weekData: any, level: any, params?: any) => async (dispatch: Dispatch) => {
    const [res, res2, res3, res4] =
      level === "Company"
        ? await Promise.all([
            APIGetWeeklyData(weekData, params),
            APIGetWeeklyDataSecond(weekData, params),
            APIGetWeeklyDataThird(weekData, params),
            APIGetWeeklyManagers(weekData, params),
          ])
        : await Promise.all([
            APIGetADAWeeklyData(weekData, params),
            APIGetADAWeeklyDataSecond(weekData, params),
            APIGetADAWeeklyDataThird(weekData, params),
            APIGetADAWeeklyManagers(weekData, params),
          ]);
    const grouped = groupByKey(res.data, "Location");
    const grouped2 = groupByKey(res2.data, "Location");
    const grouped3 = groupByKey(res3.data, "Location");
    const manager = groupByKey(res4.data, "Location");
    let data: any = convertToTableData(grouped, "WeekEndingDate");
    let data2: any = convertToTableData(grouped2, "SecondaryMeasures");
    let data3: any = convertToTableData(grouped3, "SecondaryMeasures");
    let data4: any = convertToTableDataManagers(manager);
    let row3: any = {};
    let row2: any = {};

    const firstHeaders: any = Array.from(
      new Set(
        Object.values(grouped)
          .flat()
          .map((v: any) => v["WeekEndingDate"])
      )
    );
    const secondHeaders: any = Array.from(
      new Set(res2.data.map((v: any) => v["SecondaryMeasures"]))
    );
    const thirdHeaders: any = Array.from(
      new Set(res3.data.map((v: any) => v["SecondaryMeasures"]))
    );
    dispatch(
      setHeaders({
        firstHeaders,
        secondHeaders,
        thirdHeaders,
      })
    );
    Object.keys(data3).forEach((v: any, key: number) => {
      const third = data3[v];
      const second = data2[v];

      row2 = {
        ...row2,
        [v]:
          second?.map((vKey: any) => {
            let val: any = {};
            secondHeaders.forEach((secondKey: any) => {
              val[secondKey] = vKey[secondKey] ? vKey[secondKey] : "";
            });
            return val;
          }) ?? [],
      };
      row3 = {
        ...row3,
        [v]: third.map((vKey: any) => {
          let val: any = {};
          thirdHeaders.forEach((secondKey: any) => {
            val[secondKey] = vKey[secondKey] ? vKey[secondKey] : "";
          });
          return val;
        }),
      };
    });
    let allData = {};
    Object.keys(data).forEach((key) => {
      allData = {
        ...allData,
        [key]: {
          first: data[key],
          second: row2[key],
          third: row3[key],
          manager: data4[key],
        },
      };
    });
    dispatch(setAllReportData(allData));
  };
