/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";

const BarChart = (props: any) => {
  const { barchartData } = props;
  const yLabel = [
    "SalesColdCall",
    "SalesProspect",
    "SalesAppointment",
    "SalesTour",
    "SalesRisk",
    "SalesProposal",
    "SalesWon",
    // "SalesCredit",
    // "SalesClient",
    // "SalesPresentation",
    // "SalesLost",
  ];
 

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      // height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },

    fill: {
      colors: [
        "#b3d0f5",
        "#fcf6bb",
        "#bbf7d0",
        "#ffb8cd",
        "#e9d5ff",
        "#fce3c5",
        "#b8fff1",
        // "#a8b8ff",
        // "#ffd8b8",
        // "#fce7f3",
      ],
    },

    series: [
      {
        data: [],
      },
    ],
    stroke: {
      width: 0.6,
      colors: ["#eee"],
    },
    dataLabels: {
      enabled: false,
      // enabled: true,
      // style: {
      //   colors: ["#000"],
      // },
      // offsetX: 40,
    },
    xaxis: {
      label: {
        style: {
          stroke: 4,
        },
      },
    },
    legend: {
      show: false,
    },

    tooltip: {
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
    // responsive: [
      
    //   {
    //     breakpoint: 1536,
    //     options: {
    //       chart: {
    //         width: 480,
    //         height: 300,
    //       },
    //     },
    //   },
    //   {
    //     breakpoint: 3000, 
    //     options: {
    //       chart: {
    //         width: 680,
    //         height: 500,
    //       },
    //     },
    //   },
    // ],
  });

  useEffect(() => {
    const mappedData = yLabel
      .map((label: any) => ({
        [label]: barchartData[label] || 0,
      }))
      .reduce((acc, obj) => Object.assign(acc, obj), {});
    if (mappedData && Object.keys(mappedData)?.length > 0) {
      const colors = chartOptions.fill.colors;
      const seriesData = Object.keys(mappedData).map(
        (key: any, index: any) => ({
          x: key,
          y: mappedData[key],
          color: colors[index % colors.length],
        })
      );

      setChartOptions({
        ...chartOptions,

        series: [
          {
            data: seriesData,
            colors: chartOptions.series[0].colors,
          },
        ],
      });
    }
  }, [barchartData]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartOptions.series}
      type="bar"
      height={"100%"}
    
    />
  );
};

export default BarChart;
