/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { ActionIcon, Modal, SegmentedControl, Select } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/common/Loader";
import { setLoading } from "../store/Filter/actions";
import { APIGetCalendarMonths, APIGetCalendarWeeks } from "../api/filter";
import { Top20ClientActivity } from "../components/modules/top20Clients/ClientActivity";
import { Top20ClientContacts } from "../components/modules/top20Clients/ClientContacts";
import { Top20RevenueOpportunity } from "../components/modules/top20Clients/RevenueOpportunity";
import { Top20ClientQBR } from "../components/modules/top20Clients/QBR";
import {
  APIFetchMonthlyTop20ClientsActivityData,
  APIFetchMonthlyTop20ClientsQbrData,
  APIFetchWeeklyTop20ClientsActivityData,
  APIFetchWeeklyTop20ClientsQbrData,
  APIFetchYearlyTop20ClientsActivityData,
  APIFetchYearlyTop20ClientsQbrData,
} from "../api/top-20-clients";
import { groupByKey } from "../utils/group";
import { useDisclosure } from "@mantine/hooks";

export const Top20Clients = () => {
  const tabs = [
    {
      name: "ClientActivity",
      label: "Client Activity",
      component: Top20ClientActivity,
      color: "yellow",
      background: "bg-yellow-50",
    },
    {
      name: "QBR",
      label: "QBR",
      component: Top20ClientQBR,
      color: "pink",
      background: "bg-red-50",
    },
    {
      name: "ClientContacts",
      label: "Client Contacts",
      component: Top20ClientContacts,
      color: "violet",
      background: "bg-violet-50",
    },
    {
      name: "RevenueOpportunity",
      label: "Revenue Opportunity",
      component: Top20RevenueOpportunity,
      color: "green",
      background: "bg-green-50",
    },
  ];
  const dispatch = useDispatch();
  const [weeksData, setWeeksData] = useState([]);
  const [monthsData, setMonthsData] = useState([]);
  const [weekOptions, setWeekOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [location, setLocation] = useState<any>("Company");
  const [year, setYear] = useState<any>(new Date().getFullYear().toString());
  const [month, setMonth] = useState<any>("");
  const [week, setWeek] = useState<any>("");
  const [type, setType] = useState<any>("Revenue");
  const [timeline, setTimeline] = useState<any>("Weekly");
  const [clientsData, setClientsData] = useState<any>([]);
  const [client, setClient] = useState<any>("All");
  const [commentTypeData, setCommentTypeData] = useState<any>([]);
  const [filteredClientData, setFilteredClientData] = useState<any>([]);
  const [commentType, setCommentType] = useState<any>("All");
  const [activeReport, setActiveReport] = useState<string | null>(tabs[0].name);
  const [tableData, setTableData] = useState<any>({
    ClientActivity: [],
    QBR: [],
    ClientContacts: [],
    RevenueOpportunity: [],
  });
  const [allTableData, setAllTableData] = useState<any>({
    ClientActivity: [],
    QBR: [],
    ClientContacts: [],
    RevenueOpportunity: [],
  });
  const loading = useSelector((state: any) => state.filterReducer.loading);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    dispatch(setLoading(true));
    APIGetCalendarWeeks().then((res) => {
      setWeeksData(res.data);
    });
    APIGetCalendarMonths().then((res) => {
      setMonthsData(res.data);
    });
    dispatch(setLoading(false));
  }, []);

  useEffect(() => {
    if (timeline === "Weekly") {
      const weeks: any = weeksData
        .filter((w: any) => w.CalendarYear === parseInt(year))
        .map((a: any) => {
          return a.WeekEndingDate;
        });
      setWeekOptions(weeks);
      setWeek(weeks[weeks.length - 2]);
    } else if (timeline === "Monthly") {
      const months: any = monthsData
        .filter((m: any) => m.CalendarYear === parseInt(year))
        .map((a: any) => {
          return { label: a.Monthname, value: a.MonthNumOfYear };
        });
      setMonthOptions(months);
      setMonth(months[months.length - 1]?.value);
    }
  }, [timeline, weeksData, monthsData, year]);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));
      let [clientActivity, qbr]: any = [{}, {}];
      if (timeline === "Weekly" && week) {
        if (week !== "") {
          [clientActivity, qbr] = await Promise.all([
            APIFetchWeeklyTop20ClientsActivityData(type, year, location, week),
            APIFetchWeeklyTop20ClientsQbrData(type, year, location, week),
          ]);
        }
      } else if (timeline === "Monthly") {
        if (month !== "") {
          [clientActivity, qbr] = await Promise.all([
            APIFetchMonthlyTop20ClientsActivityData(
              type,
              year,
              location,
              month
            ),
            APIFetchMonthlyTop20ClientsQbrData(type, year, location, month),
          ]);
        }
      } else if (timeline === "Yearly") {
        [clientActivity, qbr] = await Promise.all([
          APIFetchYearlyTop20ClientsActivityData(type, year, location),
          APIFetchYearlyTop20ClientsQbrData(type, year, location),
        ]);
      }

      setTableData({
        ClientActivity: clientActivity.data,
        QBR: qbr.data,
        ClientContacts: clientActivity.data,
        RevenueOpportunity: clientActivity.data,
      });
      setAllTableData({
        ClientActivity: clientActivity.data,
        QBR: qbr.data,
        ClientContacts: clientActivity.data,
        RevenueOpportunity: clientActivity.data,
      });
      setClientsData([
        "All",
        ...Object.keys(groupByKey(clientActivity.data, "Organization")),
      ]);
      setClient("All");
      setCommentType("All");
      setCommentTypeData([
        "All",
        ...Object.keys(groupByKey(clientActivity.data, "CommentType")).filter(
          (commentType) => commentType !== "null"
        ),
      ]);
      dispatch(setLoading(false));
    })();
  }, [timeline, location, type, year, month, week]);

  useEffect(() => {
    if (client === "All") {
      setTableData(allTableData);
    } else {
      const filtered: any = {};
      Object.keys(allTableData).map((key: any) => {
        filtered[key] = allTableData[key].filter(
          (data: any) => data.Organization === client
        );
      });
      setFilteredClientData(filtered);
      setTableData(filtered);
    }
    setCommentType("All");
  }, [client]);

  useEffect(() => {
    let filteredData: any = [];
    if (client === "All") {
      filteredData = allTableData.ClientActivity || [];
    } else {
      filteredData = filteredClientData.ClientActivity || [];
    }
    if (commentType !== "All") {
      filteredData = filteredData.filter(
        (data: any) => data.CommentType === commentType
      );
    }
    setTableData((prev: any) => ({
      ...prev,
      ClientActivity: filteredData,
    }));
  }, [client, commentType, allTableData, filteredClientData]);

  const handleModal = (index: any) => {
    open();
    setSelectedTab(tabs[index]);
  };

  return (
    <>
      <section className="flex flex-col items-center w-full gap-5 overflow-y-auto  xl:h-[100vh] px-5 py-2">
        {loading && <Loader />}
        <div className="absolute top-0 left-0 flex items-center">
          <ActionIcon>
            <a
              href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
              target={"_blank"}
              rel="noreferrer"
            >
              <i
                className="text-slate-600 material-icons"
                style={{ fontSize: "28px" }}
              >
                home
              </i>
            </a>{" "}
          </ActionIcon>
        </div>
        <header className="flex xl:flex-row flex-col w-full justify-between  items-center xl:items-end xl:gap-0 gap-4">
          <section className="w-1/3"></section>
          <section className="location-filter w-1/3 flex flex-col items-center gap-3 text-xs self-center">
            <div className="company-level">
              <div
                onClick={() => setLocation("Company")}
                className={`location w-24 py-2 text-center border border-gray-400 rounded cursor-pointer ${
                  location === "Company"
                    ? "bg-[#0e88e6] border-0 text-white font-bold "
                    : ""
                }`}
              >
                Company
              </div>
            </div>
            <div className="area-level flex gap-3">
              <div
                onClick={() => setLocation("Art's")}
                className={`location w-24 py-2 text-center border border-gray-400 rounded cursor-pointer ${
                  location === "Art's"
                    ? "bg-[#0e88e6] border-0 text-white font-bold"
                    : ""
                }`}
              >
                Art's
              </div>
              <div
                onClick={() => setLocation("Jake's")}
                className={`location w-24 py-2 text-center border border-gray-400 rounded cursor-pointer ${
                  location === "Jake's"
                    ? "bg-[#0e88e6] border-0 text-white font-bold"
                    : ""
                }`}
              >
                Jake's
              </div>
            </div>
          </section>
          <section className="w-1/3 flex flex-col justify-center items-center xl:items-end gap-3">
            <div className="flex gap-3">
              <SegmentedControl
                data={["Weekly", "Monthly", "Yearly"]}
                color="blue"
                size="xs"
                onChange={(d) => setTimeline(d)}
              />
              <SegmentedControl
                data={[
                  { label: "Revenue", value: "Revenue" },
                  { label: "Gross Profit", value: "GrossProfit" },
                ]}
                color="blue"
                size="xs"
                onChange={(d) => setType(d)}
              />
            </div>
            <div className="flex  gap-1 xl:gap-3">
              {timeline === "Weekly" && (
                <Select
                  size="xs"
                  className="w-24 xl:w-40"
                  data={weekOptions}
                  value={week}
                  onChange={(d) => setWeek(d)}
                  label="Week"
                />
              )}
              {timeline === "Monthly" && (
                <Select
                  size="xs"
                  className="w-32 xl:w-40"
                  data={monthOptions}
                  value={month}
                  onChange={(d) => setMonth(d)}
                  label="Month"
                />
              )}
              <Select
                size="xs"
                className="w-28"
                data={["2024", "2023", "2022"]}
                value={year}
                onChange={(d) => setYear(d)}
                label="Year"
              />
              <Select
                size="xs"
                className="w-32 xl:w-40"
                data={commentTypeData}
                value={commentType}
                onChange={(d) => setCommentType(d)}
                label="Comment Type"
                searchable
              />
            </div>
          </section>
        </header>
        <main className="w-full flex-1 flex gap-3 ">
          <section className="w-[25%] xl:w-[15%] border border-stone-200 flex flex-col">
            <div className="text-center text-blue-400 font-bold p-1 xl:text-lg text-xs bg-stone-100">
              Top 20 Clients
            </div>
            {/* <div className="flex flex-col"> */}
            {clientsData.map((clientName: any) => {
              return (
                <div
                  className={`flex-1 text-xs xl:text-sm  border-b p-1 text-center border-stone-200 cursor-pointer flex items-center justify-center ${
                    client === clientName
                      ? "bg-blue-400 text-white font-bold"
                      : "text-stone-800"
                  }`}
                  onClick={() => setClient(clientName)}
                >
                  {clientName}
                </div>
              );
            })}
            {/* </div> */}
          </section>
          <section className="w-[75%] xl:w-[85%] flex flex-col gap-3 ">
            {/* <Tabs value={activeReport} onTabChange={setActiveReport}>
            <Tabs.List>
              {tabs.map((tab) => {
                return (
                  <Tabs.Tab
                    value={tab.name}
                    pb="xs"
                    key={tab.name + "-tab"}
                    color={tab.color}
                  >
                    <span className="text-xs">{tab.label}</span>
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>
            {tabs.map((tab) => {
              return (
                <Tabs.Panel value={tab.name} key={tab.name + "-panel"}>
                  <tab.component tableData={tableData} />
                </Tabs.Panel>
              );
            })}
          </Tabs> */}
            {tabs.map((tab, index) => {
              return (
                <div
                  className="h-[25%] w-full max-h-[40vh] xl:max-h-[20vh] flex flex-col cursor-pointer"
                  onClick={() => handleModal(index)}
                >
                  <span
                    className={`block p-1 w-44 text-center text-xs ${tab.background} border border-stone-100 text-stone-800`}
                    // style={{ background: tab.color }}
                  >
                    {tab.name}
                  </span>
                  <section className="flex-1 overflow-auto mt-3">
                    <tab.component tableData={tableData[tab.name]} />
                  </section>
                </div>
              );
            })}
          </section>
        </main>
      </section>
      <Modal
        opened={opened}
        onClose={close}
        size="100%"
        title={client}
        centered
        closeOnClickOutside={false}
      >
        <div className=" flex flex-col h-[80vh]">
          <div className="flex justify-between items-end">
            <span
              className={`block p-1 w-44 text-center h-7 text-xs ${selectedTab.background} border border-stone-100 text-stone-800`}
            >
              {selectedTab.name}
            </span>
            {selectedTab.name === "ClientActivity" && (
              <Select
                size="xs"
                data={commentTypeData}
                value={commentType}
                onChange={(d) => setCommentType(d)}
                label="Comment Type"
                searchable
              />
            )}
          </div>
          <section className="flex-1 overflow-auto mt-3">
            <selectedTab.component tableData={tableData[selectedTab.name]} />
          </section>
        </div>
      </Modal>
    </>
  );
};
