/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

const msalInstance = new PublicClientApplication(msalConfig as any);
const myCache = createEmotionCache({ key: "mantine", prepend: false });

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <MantineProvider
            emotionCache={myCache}
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colors: {
                ascend: [
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                  "#01b8aa",
                ],
              },
            }}
          >
            <Notifications position="top-right" />
            <App />
          </MantineProvider>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
