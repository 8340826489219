/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  SET_ENABLE_TOOLTIP,
  SET_SHOW_BRANCH_CLIENTS_RANKING,
  SET_SHOW_CLIENTS_RANKING,
  SET_SHOW_PIP_SUMMARY_LINES,
} from "./actionTypes";

export const setEnableTooltip = (payload: any) => {
  return {
    type: SET_ENABLE_TOOLTIP,
    payload: payload,
  };
};

export const setShowClientsRanking = (payload: any) => {
  return {
    type: SET_SHOW_CLIENTS_RANKING,
    payload: payload,
  };
};

export const setShowBranchClientsRanking = (payload: any) => {
  return {
    type: SET_SHOW_BRANCH_CLIENTS_RANKING,
    payload: payload,
  };
};

export const setShowPipSummaryLines = (payload: any) => {
  return {
    type: SET_SHOW_PIP_SUMMARY_LINES,
    payload: payload,
  };
};
