/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import {
  setShowBranchClientsRanking,
  setShowClientsRanking,
} from "../../../store/CompanySummary/actions";
import { CS_BRANCHES_TOP } from "../companySummary/utils/constants";
import { groupByKey } from "../../../utils/group";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { APIFetchRegions } from "../../../api/sales-manager";

const getHierarchy = (data: any) => {
  let subAreaHierarchy = {};
  Object.keys(data).forEach((v: any) => {
    let temp = groupByKey(data[v], "Sub Area Name");
    let subHier = {};
    for (let v in temp) {
      subHier = {
        ...subHier,
        [v]: Object.values(temp[v]).map((v: any) => ({
          District: v.District,
          Region: v.Region,
          SubAreaName: v["Sub Area Name"],
          Area: v.Areaname,
        })),
      };
    }
    subAreaHierarchy = {
      ...subAreaHierarchy,
      [v]: subHier,
    };
  });
  return subAreaHierarchy;
};

const EmployeeRedeploymentSelector = (props: any) => {
  const dispatch = useDispatch();
  const {
    setLevel,
    hierarchy,
    setHierarchy,
    // setBranchesList,
    areaList,
    setAreaList,
    subAreaList,
    setSubAreaList,
    regionsList,
    setRegionsList,
    districtList,
    setDistrictList,
    selectedLevelBranch,
    setSelectedLevelBranch,
    areaSelected,
    setAreaSelected,
    regionSelected,
    setRegionSelected,
    districtSelected,
    setDistrictSelected,
    subAreaSelected,
    setSubAreaSelected,
    setSelectedBranch,
    setSelectedClientName,
    // chartAreaSelected,
    // chartRegionSelected,
    // setChartAreaSelected,
    // setChartRegionSelected,
  } = props;
  const [rawData, setRawData] = useState([]);
  const [showAllRegions, setShowAllRegions] = useState<string | null>(
    "Company"
  );
  const [showDistricts, setShowDistricts] = useState<string | null>("Company");
  const [showUngroupedRegions, setShowUngroupedRegions] =
    useState<boolean>(false);

  const loadRegions = async () => {
    const res: any = await APIFetchRegions();
    const resWithFixesSubArea = res.data.map((v: any) => ({
      ...v,
      "Sub Area Name": v["Sub Area Name"] ?? "Ungrouped",
    }));
    setRawData(resWithFixesSubArea);
    const regionTemp = Object.values(
      groupByKey(resWithFixesSubArea, "Region")
    ).map((v: any) => ({
      Region: v[0].Region,
      AreaName: v[0]["Areaname"],
      SubAreaName: v[0]["Sub Area Name"],
    }));
    const districtTemp = Object.values(
      groupByKey(resWithFixesSubArea, "District")
    ).map((v: any) => ({
      District: v[0].District,
      Region: v[0].Region,
      AreaName: v[0].Areaname,
      SubAreaName: v[0]["Sub Area Name"],
    }));
    const hierarchyTemp = groupByKey(resWithFixesSubArea, "Areaname");
    let subAreaHierarchy = getHierarchy(hierarchyTemp);
    setHierarchy(subAreaHierarchy);
    const areaTemp: any = Object.keys(hierarchyTemp) ?? [];
    setAreaList(areaTemp);
    setSubAreaList(
      Object.keys(groupByKey(resWithFixesSubArea, "Sub Area Name"))
    );
    setRegionsList(regionTemp ?? []);
    setDistrictList(districtTemp ?? []);
  };

  useEffect(() => {
    loadRegions();
  }, []);

  const setBranch = (level: string, branch: any) => {
    setLevel(level);
    setSelectedLevelBranch(branch.Location);
    dispatch(setShowClientsRanking(false));
    dispatch(setShowBranchClientsRanking(false));
  };

  const setAreaLevel = (branch: string) => {
    setSubAreaSelected(null);
    setSelectedBranch("All");
    setRegionSelected(null);
    setDistrictSelected(null);
    setShowUngroupedRegions(true);
    setShowAllRegions(null);
    setShowDistricts(null);
    setAreaSelected(branch);
    setLevel("Area");
    setBranch("Area", { Location: branch, Title: "Area" });
    setSubAreaList(
      Object.keys(hierarchy[branch]).filter((v) => v !== "Ungrouped")
    );
    setRegionsList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "Region")
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
    );
    setDistrictList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "District")
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
    );
  };

  const setSubareaLevel = (branch: string) => {
    setSubAreaSelected(branch);
    setSelectedBranch("All");
    setAreaSelected(null);
    setRegionSelected(null);
    setDistrictSelected(null);
    setShowDistricts(null);
    setShowUngroupedRegions(false);
    setShowAllRegions(branch);
    setBranch("Subarea", { Location: branch, Title: "Subarea" });
    setRegionsList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "Region"
        )
      ).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setDistrictList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "District"
        )
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Areaname,
          SubAreaName: v[0]["Sub Area Name"],
        })) ?? []
    );
  };

  const setRegionLevel = (branch: any) => {
    setRegionSelected(branch.Region);
    setSelectedBranch("All");
    setAreaSelected(null);
    setSubAreaSelected(branch.SubAreaName);
    setDistrictSelected(null);
    setLevel("Region");
    setDistrictList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v.Region === branch.Region),
          "District"
        )
      ).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setBranch("Region", { Location: branch.Region, Title: "Region" });
  };

  const setDistrictLevel = (branch: any) => {
    setDistrictSelected(branch.District);
    setLevel("District");
    setSelectedBranch("All");
    const districtTemp: any = rawData.find(
      (v: any) => v.District === branch.District
    );
    setAreaSelected(null);
    setRegionSelected(null);
    setSubAreaSelected(null);
    setRegionSelected(districtTemp ? districtTemp.Region : "");
    setSubAreaSelected(districtTemp ? districtTemp["Sub Area Name"] : "");
    setBranch("District", { Location: branch.District, Title: "Area Branch" });
  };

  const setCompanyLevel = () => {
    setShowAllRegions("Company");
    setSelectedBranch("All");
    setShowDistricts("Company");
    setSubAreaSelected(null);
    setRegionSelected(null);
    setDistrictSelected(null);
    setShowUngroupedRegions(false);
    setLevel("Company");
    setBranch("Company", { Location: "Company", Title: "Company" });
    setSubAreaList(Object.keys(groupByKey(rawData, "Sub Area Name")));
    setRegionsList(
      Object.values(groupByKey(rawData, "Region")).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      }))
    );
    setDistrictList(
      Object.values(groupByKey(rawData, "District")).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Area,
        SubAreaName: v[0].SubAreaName,
      })) ?? []
    );
  };

  return (
    <div className="flex flex-col gap-2 h-fit">
      {" "}
      <div className="top-hierarchy flex justify-center">
        {CS_BRANCHES_TOP.map(
          (v, key) =>
            v.label && (
              <div
                key={key}
                className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-sm ${
                  selectedLevelBranch === v.label
                    ? "bg-[#01b8aa] text-white"
                    : "bg-white hover:bg-green-50"
                }`}
                onClick={() => setCompanyLevel()}
              >
                Ascend Staffing
              </div>
            )
        )}
      </div>
      <div className="mid-hierarchy flex gap-4 justify-center">
        {areaList.map(
          (v: any, key: number) =>
            v && (
              <div
                key={key}
                className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-sm  ${
                  areaSelected === v.Location ? "bg-gray-500 " : ""
                } ${
                  selectedLevelBranch === v
                    ? "bg-[#01b8aa] text-white"
                    : "bg-white hover:bg-green-50"
                }`}
                onClick={() => setAreaLevel(v)}
              >
                {v}
              </div>
            )
        )}
      </div>
      <div className="mid-hierarchy flex justify-center gap-2">
        {subAreaList.map(
          (v: any, key: number) =>
            v &&
            v !== "Ungrouped" && (
              <div
                key={key}
                className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-sm   ${
                  selectedLevelBranch === v
                    ? "bg-[#01b8aa] text-white"
                    : "bg-white hover:bg-green-50"
                }`}
                onClick={() => setSubareaLevel(v)}
              >
                {v}
              </div>
            )
        )}
        {showUngroupedRegions &&
          regionsList
            .filter((region: any) => region.SubAreaName === "Ungrouped")
            .map(
              (v: any, key: any) =>
                v.Region && (
                  <div
                    key={key}
                    className={`cursor-pointer px-[0.2rem] lg:px-[0.6rem] py-[0.2rem] border-[1px] rounded-md  text-xs lg:text-sm  ${
                      selectedLevelBranch === v.Region
                        ? "bg-[#51a541] text-white"
                        : "bg-white hover:bg-green-50"
                    }`}
                    onClick={() => {
                      setRegionLevel(v);
                      setShowDistricts(null);
                      setShowAllRegions(null);
                    }}
                  >
                    {v.Region}
                  </div>
                )
            )}
      </div>
      <div className="low-hierarchy flex gap-2 justify-center">
        {showAllRegions &&
          regionsList
            .filter(
              (region: any) =>
                region.SubAreaName !== "Ungrouped" &&
                (!subAreaSelected || region.SubAreaName === subAreaSelected)
            )
            .map(
              (v: any, key: any) =>
                v.Region && (
                  <div
                    key={key}
                    className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-sm                      
                     ${
                       selectedLevelBranch === v.Region
                         ? "bg-[#01b8aa] text-white"
                         : "bg-white hover:bg-green-50"
                     }`}
                    onClick={() => {
                      setRegionLevel(v);
                      setShowDistricts(v.Region);
                      setShowUngroupedRegions(false);
                    }}
                  >
                    {v.Region}
                  </div>
                )
            )}
        {showAllRegions &&
          regionsList
            .filter(
              (region: any) =>
                region.SubAreaName === "Ungrouped" &&
                (!subAreaSelected || region.SubAreaName === subAreaSelected)
            )
            .map(
              (v: any, key: any) =>
                v.Region && (
                  <div
                    key={key}
                    className={`cursor-pointer px-[0.6rem] py-[0.2rem] border-[1px] rounded-md text-xs lg:text-sm  ${
                      selectedLevelBranch === v.Region
                        ? "bg-[#01b8aa] text-white"
                        : "bg-white hover:bg-green-50"
                    }`}
                    onClick={() => {
                      setRegionLevel(v);
                      setShowUngroupedRegions(
                        v.SubAreaName === "Ungrouped" ? true : false
                      );
                      setShowAllRegions(
                        v.SubAreaName === "Ungrouped" ? null : v.Region
                      );
                    }}
                  >
                    {v.Region}
                  </div>
                )
            )}
      </div>
      <div className="lowest-hierarchy flex justify-center gap-2">
        {showDistricts &&
          districtList
            .filter((v: any) =>
              regionSelected ? v.Region === regionSelected : true
            )
            .map(
              (v: any, key: any) =>
                v.District && (
                  <div
                    key={key}
                    className={`cursor-pointer px-[0.2rem] lg:px-[0.6rem] py-[0.2rem] border-[1px] rounded-md  text-xs lg:text-sm  ${
                      selectedLevelBranch === v.District
                        ? "bg-[#51a541] text-white"
                        : "bg-white hover:bg-green-50"
                    }`}
                    onClick={() => setDistrictLevel(v)}
                  >
                    {v.District}
                  </div>
                )
            )}
      </div>
    </div>
  );
};

export default EmployeeRedeploymentSelector;
