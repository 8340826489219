/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { SignInButton } from "../components/SignInButton";
import { Landing } from "../pages/Landing";

function AuthenticatedLayout() {
  return (
    <main>
      <AuthenticatedTemplate>
        <Landing />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You are not signed in!</p>
        <SignInButton />
      </UnauthenticatedTemplate>
    </main>
  );
}

export default AuthenticatedLayout;
