/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchRolling3MonthsOptions = () => {
  return GetRequest(`/top-clients/options/rolling3months`);
};

export const APIFetchRolling12MonthsOptions = () => {
  return GetRequest(`/top-clients/options/rolling12months`);
};

export const APIFetchPreviousRolling3MonthsOptions = () => {
  return GetRequest(`/top-clients/options/previousrolling3months`);
};

export const APIFetchPreviousRolling12MonthsOptions = () => {
  return GetRequest(`/top-clients/options/previousrolling12months`);
};

export const APIFetchPreviousRolling24MonthsOptions = () => {
  return GetRequest(`/top-clients/options/previousrolling24months`);
};

export const APIFetchAcquisitionDataOptions = () => {
  return GetRequest(`/top-clients/options/acquisitionclients`);
};

export const APIFetchWeeklyTopClients = (selectedWeek: any, location: any) => {
  return GetRequest(
    `/top-clients/weekly?selectedWeek=${selectedWeek}${
      location ? "&location=" + location : ""
    }`
  );
};
export const APIFetchRolling30DaysTopClients = async (
  selectedWeek: any,
  location: any
) => {
  return await GetRequest(
    `/top-clients/rolling30days?selectedWeek=${selectedWeek}${
      location ? "&location=" + location : ""
    }`
  );
};
export const APIFetchRolling90DaysTopClients = (
  selectedWeek: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/rolling90days?selectedWeek=${selectedWeek}${
      location ? "&location=" + location : ""
    }`
  );
};
export const APIFetchYTDThisYear = (selectedWeek: any, location: any) => {
  return GetRequest(
    `/top-clients/ytd?selectedWeek=${selectedWeek}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchMonthlyTopClients = (
  selectedMonth: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/monthly?selectedMonth=${selectedMonth}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchQuarterlyTopClients = (
  selectedQuarter: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/quarterly?selectedQuarter=${selectedQuarter}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchRolling3MonthsTopClients = (
  selectedYear: any,
  selectedMonth: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/rolling3months?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchRolling12MonthsTopClients = (
  selectedYear: any,
  selectedMonth: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/rolling12months?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchPreviousRolling3MonthsTopClients = (
  selectedYear: any,
  selectedMonth: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/previousrolling3months?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchPreviousRolling12MonthsTopClients = (
  selectedYear: any,
  selectedMonth: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/previousrolling12months?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchPreviousRolling24MonthsTopClients = (
  selectedYear: any,
  selectedMonth: any,
  location: any
) => {
  return GetRequest(
    `/top-clients/previousrolling24months?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}${
      location ? "&location=" + location : ""
    }`
  );
};

export const APIFetchWeeklyTopClientsChart = () => {
  return GetRequest(`/top-clients/weekly-chart`);
};

export const APIFetchMonthlyTopClientsChart = () => {
  return GetRequest(`/top-clients/monthly-chart`);
};

export const APIFetchQuarterlyTopClientsChart = () => {
  return GetRequest(`/top-clients/quarterly-chart`);
};
