/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { SORTING_HEADERS_WITH_VALUES } from "./cellFormatter";

export const getSummedArData = (arr: any) => {
  const temp = arr.reduce(
    (acc: any, curr: any) => {
      acc["Organization"] = curr["Organization"];
      acc["1-30 Days"] += curr["1-30 Days"] ?? 0;
      acc["31-60 Days"] += curr["31-60 Days"] ?? 0;
      acc["61-90 Days"] += curr["61-90 Days"] ?? 0;
      acc["Balance"] += curr["Balance"] ?? 0;
      acc["Current"] += curr["Current"] ?? 0;
      acc["InvoiceAmount"] += curr["InvoiceAmount"] ?? 0;
      acc["PaymentAmount"] += curr["PaymentAmount"] ?? 0;
      acc["Over 90 Days"] += curr["Over 90 Days"] ?? 0;
      return { ...curr, ...acc };
    },
    {
      "1-30 Days": 0,
      "31-60 Days": 0,
      "61-90 Days": 0,
      Balance: 0,
      Branch: 0,
      Current: 0,
      "Over 90 Days": 0,
      PaymentAmount: 0,
      InvoiceAmount: 0,
    }
  );
  return {
    ...temp,
    "1-30 Days":
      temp["1-30 Days"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["1-30 Days"].toFixed(2)),
    "31-60 Days":
      temp["31-60 Days"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["31-60 Days"].toFixed(2)),
    "61-90 Days":
      temp["61-90 Days"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["61-90 Days"].toFixed(2)),
    Balance:
      temp["Balance"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["Balance"].toFixed(2)),
    Current:
      temp["Current"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["Current"].toFixed(2)),
    InvoiceAmount:
      temp["InvoiceAmount"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["InvoiceAmount"].toFixed(2)),
    PaymentAmount:
      temp["PaymentAmount"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["PaymentAmount"].toFixed(2)),
    "Over 90 Days":
      temp["Over 90 Days"].toFixed(2) === 0.0
        ? ""
        : parseFloat(temp["Over 90 Days"].toFixed(2)),
  };
};

export const sortGroupedData = (data: any, sortVal: any) => {
  return SORTING_HEADERS_WITH_VALUES[sortVal.type] === "Organization"
    ? [...data].sort((a: any, b: any) => {
        const val = getSummedArData(a)[
          SORTING_HEADERS_WITH_VALUES[sortVal.type]
        ].localeCompare(
          getSummedArData(b)[SORTING_HEADERS_WITH_VALUES[sortVal.type]]
        );
        return val * (sortVal.isReverse ? -1 : 1);
      })
    : [...data].sort((a: any, b: any) => {
        if (
          getSummedArData(a)[SORTING_HEADERS_WITH_VALUES[sortVal.type]] <
          getSummedArData(b)[SORTING_HEADERS_WITH_VALUES[sortVal.type]]
        ) {
          return sortVal.isReverse ? 1 : -1;
        }
        if (
          getSummedArData(a)[SORTING_HEADERS_WITH_VALUES[sortVal.type]] >
          getSummedArData(b)[SORTING_HEADERS_WITH_VALUES[sortVal.type]]
        ) {
          return sortVal.isReverse ? -1 : 1;
        }
        return 0;
      });
};
