/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { ActionIcon } from "@mantine/core";
import SalesActivitySelector from "../components/modules/salesActivity/SalesActivitySelector";
import { useEffect, useState } from "react";
import SalesActivityCharts from "../components/modules/salesActivity/SalesActivityCharts";
import {
  APIFetchBarChartDataAreaLevel,
  APIFetchBarChartDataBranchLevel,
  APIFetchBarChartDataClientLevel,
  APIFetchBarChartDataCompanyLevel,
  APIFetchBarChartDataRegionLevel,
  APIFetchFunnelChartData,
} from "../api/sales-activity";
import { groupByKey } from "../utils/group";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";

const SalesActivity = () => {
  const [branchesList, setBranchesList] = useState([] as any);
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState("Company");
  const [selectedLevelBranch, setSelectedLevelBranch] = useState("Company");
  const [subAreaSelected, setSubAreaSelected] = useState("");
  const [areaSelected, setAreaSelected] = useState();
  const [regionSelected, setRegionSelected] = useState<{
    Location: string;
    Title: string;
  }>();
  const [totalType, setTotalType] = useState("Company");
  const [selectedBranchName, setSelectedBranchName] = useState<any>("All");
  const [data, setData] = useState([]);
  const [funnelChartData, setFunnelChartData] = useState([]);
  const [clientNames, setClientNames] = useState<string[]>([]);
  const [selectedClientName, setSelectedClientName] = useState("All");
  const dispatch = useDispatch();
  // const [selectedDateRange, setSelectedDateRange] = useState({
  //   startdate: moment(new Date()).add("-29", "days").format("yyyy-MM-DD"),
  //   enddate: moment(new Date()).format("yyyy-MM-DD"),
  // });
  const [selectedDateRange, setSelectedDateRange] = useState({
    startdate: null,
    enddate: null,
  });

  useEffect(() => {
    document.title = "Sales Activity";
  }, []);

  useEffect(() => {
    APIFetchFunnelChartData(
      selectedBranchName === "All"
        ? selectedLevelBranch === "Company"
          ? "All"
          : selectedLevelBranch
        : selectedBranchName
    ).then((res: any) => setFunnelChartData(res.data));
  }, [selectedLevelBranch, selectedBranchName]);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));
      if (selectedBranchName && selectedBranchName !== "All") {
        if (selectedClientName === "All") {
          await APIFetchBarChartDataBranchLevel(selectedBranchName).then(
            (res) => {
              setData(res.data);
              setClientNames(Object.keys(groupByKey(res.data, "clientname")));
            }
          );
        } else {
          await APIFetchBarChartDataClientLevel(selectedClientName).then(
            (res) => setData(res.data)
          );
        }
      } else if (
        selectedBranchName === "All" &&
        level === "Region" &&
        regionSelected
      ) {
        await APIFetchBarChartDataRegionLevel(regionSelected.Location).then(
          (res) => {
            setData(res.data);
          }
        );
      } else if (
        selectedBranchName === "All" &&
        level === "Area" &&
        areaSelected
      ) {
        await APIFetchBarChartDataAreaLevel(areaSelected).then((res) => {
          setData(res.data);
        });
      } else {
        await APIFetchBarChartDataCompanyLevel().then((res) => {
          setData(res.data);
        });
      }
      dispatch(setLoading(false));
    })();
  }, [
    selectedBranchName,
    areaSelected,
    regionSelected,
    selectedClientName,

    level,
  ]);

  const filteredDataByDate =
    selectedDateRange.startdate === null && selectedDateRange.enddate === null
      ? data
      : data.filter(
          (d: any) =>
            d.insertdate >= (selectedDateRange.startdate ?? "") &&
            d.insertdate <= (selectedDateRange.enddate ?? "")
        );

  const barchartTitle =
    selectedClientName === "All"
      ? "Total Comments by Branch"
      : "Total Comments by Client";

  let totalCounts: [] = [];
  totalCounts = Object.values(filteredDataByDate)
    .flat()
    .reduce((counts: any, entry: any) => {
      counts[entry.commenttype] =
        (counts[entry.commenttype] || 0) + entry.commenttypecount;
      return counts;
    }, {});

  return (
    <main className="min-h-[100vh] min-w-[100vw] w-[100%]   ">
      <section className="w-[100%] h-[100%] flex">
        <div className="h-full flex-grow">
          <div className=" flex flex-col gap-2 ">
            <div className="absolute flex items-center gap-1 p-2 ">
              <ActionIcon>
                <i className="material-icons" style={{ fontSize: "32px" }}>
                  chevron_left
                </i>
              </ActionIcon>
              <ActionIcon>
                <a
                  href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="text-slate-600 material-icons text-[28px]">
                    home
                  </i>
                </a>{" "}
              </ActionIcon>
              <div className="font-semibold text-[#5f98bc] text-md 2xl:text-xl ml-2">
                Sales Activity
              </div>
            </div>
            {/* )} */}

            <div className="flex gap-8">
              <div className="bg-white flex flex-col w-[16vw] lg:w-[9vw] cursor-pointer overflow-y-auto ml-2 border-[1px] border-slate-300 mt-[20vh] sm:max-2xl:mt-[10vh] 2xl:mt-[5vh] h-[88vh] 2xl:h-[93vh]">
                <div className=" bg-[#d3e9f7] pl-2 text-xs py-1 sticky z-10 top-0 text-center font-semibold border-b-2">
                  Select Branch
                </div>
                {/* <div
                  className={`
                
                   text-[0.8em]  px-2 font-medium ${
                     selectedBranchName === "All" &&
                     selectedLevelBranch === "Company"
                       ? "bg-[#01b8aa] text-white"
                       : "hover:bg-green-50"
                   }`}
                  onClick={() => {
                    setSelectedBranchName("All");
                    setSelectedClientName("All");
                  }}
                >
                  {selectedLevelBranch === "Company" ? "All" : ""}
                </div> */}

                {branchesList &&
                  branchesList?.sort().map((branch: any) => (
                    <div
                      className={`border-b-[1px] text-[0.7em] py-[0.1rem] px-2 font-medium ${
                        selectedBranchName === branch
                          ? "bg-[#80b5d6] text-white"
                          : "hover:bg-[#eff8fe]"
                      }`}
                      onClick={() => {
                        setSelectedBranchName(branch);
                        setSelectedClientName("All");
                      }}
                    >
                      {branch}
                    </div>
                  ))}
              </div>
              {/* <div className="flex flex-col "> */}
              {/* <div className="flex align-center px-[10vw] text-md font-medium">
                  <div className="py-2 px-4 bg-[#01b8aa] text-white w-full  ">
                    Sales Activity
                  </div>
                </div> */}
              <div className="flex flex-col flex-1 pr-8 w-[80vw]">
                <SalesActivitySelector
                  selectedLevelBranch={selectedLevelBranch}
                  setSelectedLevelBranch={setSelectedLevelBranch}
                  regionsList={regionsList}
                  setRegionsList={setRegionsList}
                  areaList={areaList}
                  setAreaList={setAreaList}
                  subAreaList={subAreaList}
                  setSubAreaList={setSubAreaList}
                  branchesList={branchesList}
                  setBranchesList={setBranchesList}
                  hierarchy={hierarchy}
                  setHierarchy={setHierarchy}
                  areaSelected={areaSelected}
                  setAreaSelected={setAreaSelected}
                  setRegionSelected={setRegionSelected}
                  subAreaSelected={subAreaSelected}
                  setSubAreaSelected={setSubAreaSelected}
                  setLevel={setLevel}
                  level={level}
                  setTotalType={setTotalType}
                  totalType={totalType}
                  setSelectedBranchName={setSelectedBranchName}
                  setSelectedClientName={setSelectedClientName}
                  setSelectedDate={setSelectedDateRange}
                />
                <SalesActivityCharts
                  selectedBranchName={selectedBranchName}
                  setSelectedBranchName={setSelectedBranchName}
                  branchesList={branchesList}
                  clientNames={clientNames}
                  selectedClientName={selectedClientName}
                  setSelectedClientName={setSelectedClientName}
                  barchartData={totalCounts}
                  barchartTitle={barchartTitle}
                  funnelChartData={funnelChartData}
                  selectedLevelBranch={selectedLevelBranch}
                  selectedDateRange={selectedDateRange}
                  setSelectedDate={setSelectedDateRange}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader />
    </main>
  );
};

export default SalesActivity;
