/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import moment from "moment";

export const chartDataValidator = (keys: any, arr: any) => {
  return [];
};

export const labelFormatter = (label: string, chartType?: string) => {
  const currentYear = moment(new Date()).add("weeks", "-1").year();
  // const currentYear = 2023;
  if (label === "Actual Goal") {
    return "President's Club Goal";
  } else if (
    label === "Current Year" ||
    label === "This Year Gross Profit" ||
    label === "This Year Revenue"
  ) {
    return currentYear;
  } else if (
    label === "Last Year" ||
    label === "Last Year Gross Profit" ||
    label === "Last Year Revenue"
  ) {
    return (+currentYear - 1).toString();
  } else if (
    label === "Previous Year" ||
    label === "Prior Year Gross Profit" ||
    label === "Prior Year Revenue"
  ) {
    return (+currentYear - 2).toString();
  } else if (label === "12 Months") {
    return "12 Months #";
  } else {
    return label;
  }
};

export const dottedLine = (label: string) => {
  return (
    label === "Actual Goal" ||
    label === "12 Months" ||
    label === "% to Plan PIP" ||
    label === "% to Last Year PIP"
  );
};

export const formatPercChange = (value: any) => {
  return (
    <div
      className={`${
        value > 0 ? "text-green-600" : value < 0 ? "text-red-500" : "text-black"
      } font-medium flex items-center justify-center`}
    >
      <div>{value===null?0:value?.toFixed(2)}%</div>
      <i className="material-icons text-xs ">
        {value > 0 ? "arrow_upward" : value < 0 ? "arrow_downward" : ""}
      </i>
    </div>
  );
};
