/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIMonthSlicerData = () => {
  return GetRequest(`kearl-kurve/slicer/months`);
};

export const APIProjectionData = (
  year: any,
  location: any,
  selectedLevel: string
) => {
  return GetRequest(
    `kearl-kurve/projection?year=${year}${
      location ? "&location=" + location : ""
    }`,
    { params: { selectedLevel: selectedLevel } }
  );
};

export const APIYtdData = (year: any, location: any, selectedLevel: string) => {
  return GetRequest(
    `kearl-kurve/ytd?year=${year}${location ? "&location=" + location : ""}`,
    { params: { selectedLevel: selectedLevel } }
  );
};

export const APIMonthlyData = (
  slicer: any,
  location: any,
  selectedLevel: string
) => {
  return GetRequest(
    `kearl-kurve/monthly?slicer=${slicer}${
      location ? "&location=" + location : ""
    }`,
    { params: { selectedLevel: selectedLevel } }
  );
};

export const APIWeeklyData = (location: any, selectedLevel: string) => {
  return GetRequest(
    `kearl-kurve/weekly${location ? "?location=" + location : ""}`,
    { params: { selectedLevel: selectedLevel } }
  );
};
