/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const getRevGPData = (data: any) => {
  let revDataTemp: any = {
    revenuePerDay: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
    gpPerDay: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
  };
  data?.forEach((v: any) => {
    if (
      ![
        "Company Total",
        "Region Total",
        "Area Total",
        "District Total",
      ].includes(v.Location)
    ) {
      revDataTemp.revenuePerDay = {
        ...revDataTemp.revenuePerDay,
        [v.Location]: v.RevPerEmpPerDay ?? 0,
      };
      revDataTemp.gpPerDay = {
        ...revDataTemp.gpPerDay,
        [v.Location]: v.GPPerEmpPerDay ?? 0,
      };
    }
  });

  const totalVal =
    data?.find((v: any) => v.Location.trim() === "Company Total") ?? 0;
  const regionVal =
    data?.find((v: any) => v.Location.trim() === "Region Total") ?? 0;
  const districtVal =
    data?.find((v: any) => v.Location.trim() === "District Total") ?? 0;
  const areaVal =
    data?.find((v: any) => v.Location.trim() === "Area Total") ?? 0;
  revDataTemp.revenuePerDay = {
    ...revDataTemp.revenuePerDay,
    CompanyTotal: totalVal?.RevPerEmpPerDay ?? 0,
    AreaTotal: areaVal?.RevPerEmpPerDay ?? 0,
    RegionTotal: regionVal?.RevPerEmpPerDay ?? 0,
    DistrictTotal: districtVal?.RevPerEmpPerDay ?? 0,
  };
  revDataTemp.gpPerDay = {
    ...revDataTemp.gpPerDay,
    CompanyTotal: totalVal.GPPerEmpPerDay ?? 0,
    AreaTotal: areaVal?.GPPerEmpPerDay ?? 0,
    RegionTotal: regionVal?.GPPerEmpPerDay ?? 0,
    DistrictTotal: districtVal?.GPPerEmpPerDay ?? 0,
  };
  return revDataTemp;
};

export const getNetStarts = (data: any) => {
  let netStatTemp: any = {
    CompanyTotal: 0,
    RegionTotal: 0,
    DistrictTotal: 0,
    AreaTotal: 0,
  };

  data?.forEach((v: any) => {
    if (v.Location !== "Company Total") {
      netStatTemp = {
        ...netStatTemp,
        [v.Location]: v.NetStarts ?? 0,
      };
    }
  });

  const totalVal = data?.find(
    (v: any) => v.Location.trim() === "Company Total"
  );
  const regionVal = data?.find(
    (v: any) => v.Location.trim() === "Region Total"
  );
  const districtVal = data?.find(
    (v: any) => v.Location.trim() === "District Total"
  );
  const areaVal = data?.find((v: any) => v.Location.trim() === "Area Total");
  netStatTemp = {
    ...netStatTemp,
    CompanyTotal: totalVal?.NetStarts ?? 0,
    AreaTotal: areaVal?.NetStarts ?? 0,
    RegionTotal: regionVal?.NetStarts ?? 0,
    DistrictTotal: districtVal?.NetStarts ?? 0,
  };
  return netStatTemp;
};

export const getAvgDailyNumbers = (data: any) => {
  let netStatTemp: any = {
    CurrDailyNumbers: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
    Difference: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
    LastDailyNumbers: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
  };

  data?.forEach((v: any) => {
    if (
      ![
        "Company Total",
        "Region Total",
        "Area Total",
        "District Total",
      ].includes(v.Location)
    ) {
      netStatTemp.CurrDailyNumbers = {
        ...netStatTemp.CurrDailyNumbers,
        [v.Location]: v.CurrDailyNumbers ?? 0,
      };
      netStatTemp.Difference = {
        ...netStatTemp.Difference,
        [v.Location]: v.Difference ?? 0,
      };
      netStatTemp.LastDailyNumbers = {
        ...netStatTemp.LastDailyNumbers,
        [v.Location]: v.LastDailyNumbers ?? 0,
      };
    }
  });

  const totalVal = data.find((v: any) => v.Location.trim() === "Company Total");
  const areaVal = data.find((v: any) => v.Location.trim() === "Area Total");
  const regionVal = data.find((v: any) => v.Location.trim() === "Region Total");
  const districtVal = data.find(
    (v: any) => v.Location.trim() === "District Total"
  );

  netStatTemp.CurrDailyNumbers = {
    ...netStatTemp.CurrDailyNumbers,
    AreaTotal: areaVal?.CurrDailyNumbers ?? 0,
    RegionTotal: regionVal?.CurrDailyNumbers ?? 0,
    DistrictTotal: districtVal?.CurrDailyNumbers ?? 0,
    CompanyTotal: totalVal.CurrDailyNumbers ?? 0,
  };
  netStatTemp.Difference = {
    ...netStatTemp.Difference,
    AreaTotal: areaVal?.Difference ?? 0,
    RegionTotal: regionVal?.Difference ?? 0,
    DistrictTotal: districtVal?.Difference ?? 0,
    CompanyTotal: totalVal?.Difference ?? 0,
  };
  netStatTemp.LastDailyNumbers = {
    ...netStatTemp.LastDailyNumbers,
    AreaTotal: areaVal?.LastDailyNumbers ?? 0,
    RegionTotal: regionVal?.LastDailyNumbers ?? 0,
    DistrictTotal: districtVal?.LastDailyNumbers ?? 0,
    CompanyTotal: totalVal?.LastDailyNumbers ?? 0,
  };
  return netStatTemp;
};

export const getComparison = (data: any) => {
  let comparisonRes: any = {
    CurrDailyNumbers: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
    Difference: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
    LastDailyNumbers: {
      CompanyTotal: 0,
      RegionTotal: 0,
      DistrictTotal: 0,
      AreaTotal: 0,
    },
  };

  data?.forEach((v: any) => {
    if (
      ![
        "Company Total",
        "Region Total",
        "Area Total",
        "District Total",
      ].includes(v.Location)
    ) {
      comparisonRes.CurrDailyNumbers = {
        ...comparisonRes.CurrDailyNumbers,
        [v.Location]: v.CurrDailyNumbers ?? 0,
      };
      comparisonRes.Difference = {
        ...comparisonRes.Difference,
        [v.Location]: v.Difference ?? 0,
      };
      comparisonRes.LastDailyNumbers = {
        ...comparisonRes.LastDailyNumbers,
        [v.Location]: v.LastDailyNumbers ?? 0,
      };
    }
  });

  const totalVal = data.find((v: any) => v.Location.trim() === "Company Total");
  const areaVal = data.find((v: any) => v.Location.trim() === "Area Total");
  const regionVal = data.find((v: any) => v.Location.trim() === "Region Total");
  const districtVal = data.find(
    (v: any) => v.Location.trim() === "District Total"
  );

  comparisonRes.CurrDailyNumbers = {
    ...comparisonRes.CurrDailyNumbers,
    AreaTotal: areaVal?.CurrDailyNumbers ?? 0,
    RegionTotal: regionVal?.CurrDailyNumbers ?? 0,
    DistrictTotal: districtVal?.CurrDailyNumbers ?? 0,
    CompanyTotal: totalVal?.CurrDailyNumbers ?? 0,
  };
  comparisonRes.Difference = {
    ...comparisonRes.Difference,
    AreaTotal: areaVal?.Difference ?? 0,
    RegionTotal: regionVal?.Difference ?? 0,
    DistrictTotal: districtVal?.Difference ?? 0,
    CompanyTotal: totalVal?.Difference ?? 0,
  };
  comparisonRes.LastDailyNumbers = {
    ...comparisonRes.LastDailyNumbers,
    AreaTotal: areaVal?.LastDailyNumbers ?? 0,
    RegionTotal: regionVal?.LastDailyNumbers ?? 0,
    DistrictTotal: districtVal?.LastDailyNumbers ?? 0,
    CompanyTotal: totalVal?.LastDailyNumbers ?? 0,
  };
  return comparisonRes;
};

export const dailyNumberFormatter = (val: string) => {
  return (
    "$ " + (val?.toString().includes(".") ? parseFloat(val).toFixed(2) : val)
  );
};
