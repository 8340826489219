/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import "../assets/scss/FinancialReport.scss";
import {
  HIGHLIGHTED_PARTICULARS,
  TABLE_MONTH_HEADERS,
  TABLE_OTHER_HEADERS,
} from "../utils/mock-data/financial-report";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getFinancialBranches,
  getFinancialData,
} from "../store/FinancialReport/actions";
import { Loader } from "../components/common/Loader";
import {
  setAlertDialog,
  setLoading,
  setUserAccessibleLocations,
} from "../store/Filter/actions";
import {
  APIFetchItemDetails,
  APIFetchItemDetailsTest,
  APIFetchItemDetailsWipTest,
  APIFetchItemLedgerDetails,
  APIFetchItemLedgerDetailsTest,
  APIFetchItemLedgerDetailsWipTest,
  APIPublishSageReportOption,
} from "../api/financial-report";
import { AlertDialog } from "../components/common/AlertDialog";
import { formatCellFinancial } from "../utils/helper/cellFormatter";
import { MONTH_LIST } from "../utils/helper/constants";
import { useDisclosure } from "@mantine/hooks";
import { ActionIcon, Menu, Modal, Select, Table } from "@mantine/core";
import { ContentLoader } from "../components/common/Loader";

const months: any = {
  0: { monthNumber: 1, monthName: "January" },
  1: { monthNumber: 2, monthName: "February" },
  2: { monthNumber: 3, monthName: "March" },
  3: { monthNumber: 4, monthName: "April" },
  4: { monthNumber: 5, monthName: "May" },
  5: { monthNumber: 6, monthName: "June" },
  6: { monthNumber: 7, monthName: "July" },
  7: { monthNumber: 8, monthName: "August" },
  8: { monthNumber: 9, monthName: "September" },
  9: { monthNumber: 10, monthName: "October" },
  10: { monthNumber: 11, monthName: "November" },
  11: { monthNumber: 12, monthName: "December" },
};

export const FinancialReportWip = (props: any) => {
  const ledgerHeaders: any = {
    batch_date: "Batch Date",
    entry_date: "Entry Date",
    document: "Document",
    description: "Memo/Description",
    department: "Department",
    locationid: "Location",
    vendor: "Vendor Name",
    symbol: "JNL",
    debit: "Debit",
    credit: "Credit",
    balance: "Balance",
  };
  const loading = useSelector((state: any) => state["filterReducer"].loading);
  const { alertDialog } = useSelector((state: any) => state.filterReducer);
  const branches = useSelector(
    (state: any) => state.filterReducer.userLocations
  );
  const [tableHeaders, setTableHeaders] = useState([
    ...TABLE_MONTH_HEADERS(),
    ...TABLE_OTHER_HEADERS,
  ]);
  const { header, data, financialLocation } = useSelector(
    (state: any) => state.financialReportReducer
  );

  const [data1, setData] = useState(data);
  const dispatch = useDispatch();

  const [selectedYear, setSelectedYear] = useState<any>("2024");
  const currentMonth = new Date().getMonth();
  const selectedMonth = MONTH_LIST[currentMonth];
  const [branchSelected, setBranchSelected] = useState(branches?.[0]);
  const highlightedParticulars = HIGHLIGHTED_PARTICULARS;
  const [message, setMessage] = useState("");
  const [allowPublish, setAllowPublish] = useState(false);
  const [assignedBranches, setAssignedBranches] = useState<any>([]);
  const [branchesLoading, setBranchesLoading] = useState(true);

  useEffect(() => {
    document.title = "Financial Report";
    // dispatch(getFinancialBranches());
  }, []);

  useEffect(() => {
    if (financialLocation) getUserLocations();
    const branchGroups = financialLocation
      .filter((l: any) => l.level === "Branch")
      .map((l: any) => l.name);
    setAssignedBranches(branchGroups);
  }, [financialLocation]);

  useEffect(() => {
    setData(data);
  }, [data]);

  const getUserLocations = async () => {
    let hier = [];
    hier = financialLocation.filter((l: any) => l.level === "Location");
    if (hier.length > 0) setAllowPublish(true);
    if (hier.length === 0) {
      hier = financialLocation.filter((l: any) => l.level === "Area");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: any) => l.level === "VP");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: any) => l.level === "Region");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: any) => l.level === "AreaBranch");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: any) => l.level === "Branch");
    }
    if (hier.length > 0)
      dispatch(await setUserAccessibleLocations(hier, selectedYear, true));
    setBranchesLoading(false);
  };

  const loadData = async () => {
    dispatch(setLoading(true));
    if ([branchSelected].every(Boolean)) {
      await dispatch(
        getFinancialData(
          branchSelected.locations,
          selectedYear,
          selectedMonth,
          props.wip,
          props.test
        )
      );
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getUserLocations();
  }, [selectedYear]);

  useEffect(() => {
    loadData();
  }, [branchSelected]);

  useEffect(() => {
    setBranchSelected(branches[0]);
  }, [branches]);

  const getHighlights = (particular: any) => {
    return highlightedParticulars.includes(particular.Item.trim());
  };

  const previewYearlyFinancialReport = async () => {
    dispatch(setLoading(true));
    const res: any = await APIPublishSageReportOption(
      "YTD",
      selectedYear,
      null,
      props.wip,
      props.test
    );
    await loadData();
    setMessage(
      res?.message ??
        "Preview in progress. Please click Post button to review the changes"
    );
    dispatch(setAlertDialog(true));
  };

  const previewMonthlyFinancialReport = async () => {
    dispatch(setLoading(true));
    const res: any = await APIPublishSageReportOption(
      "PREV_MONTH",
      selectedYear,
      currentMonth - 1,
      props.wip,
      props.test
    );
    await loadData();
    setMessage(
      res?.message ??
        "Preview in progress. Please click Post button to review the changes"
    );
    dispatch(setAlertDialog(true));
  };

  const exportData = async (val: string) => {
    const exportUrl = props.test
      ? "http://192.168.31.11:5005/api/v2/report/export"
      : props.wip
      ? "https://sage-api.ascendstaffing.com/api/beta/report/export"
      : "https://sage-api.ascendstaffing.com/api/v2/report/export";

    window.open(
      `${exportUrl}?type=${val}&year=${selectedYear}&branches=${JSON.stringify(
        branches.map((b: any) => b.locations.replace("'", "''"))
      )}`,
      "_blank"
    );
  };

  const [hideRows, setHideRows] = useState<boolean>(false);

  const showDetails = async (column: any, i: any) => {
    if (
      !(
        (getHighlights(column) && column.Category !== "Whole") ||
        column.Item.includes(":") ||
        branchSelected?.Level > 1 ||
        !assignedBranches.includes(branchSelected.locations) ||
        (props.wip ? selectedYear === "2022" : "")
      )
    ) {
      column.showDetails = !column.showDetails;
      if (!column.subData) {
        const res = props.test
          ? await APIFetchItemDetailsTest(
              selectedYear,
              branchSelected.locations,
              column.Item.trim(),
              column.Category
            )
          : props.wip === true
          ? await APIFetchItemDetailsWipTest(
              selectedYear,
              branchSelected.locations,
              column.Item.trim(),
              column.Category
            )
          : await APIFetchItemDetails(
              selectedYear,
              branchSelected.locations,
              column.Item.trim(),
              column.Category
            );
        column.subData = res.data;
      }
      data1[i] = column;
      setData([...data1]);
    }
  };

  const showLedgerReport = async (column: any, monthIndex: number) => {
    if (months[monthIndex]) {
      open();
      setModalTitle(
        `${column.SubItemNo ?? "N/A"} - ${column.SubItem.trim() ?? "N/A"}`
      );
      setModalLoading(true);
      const res = props.test
        ? await APIFetchItemLedgerDetailsTest(
            selectedYear,
            months[monthIndex].monthNumber,
            branchSelected.locations,
            column.SubItem.trim(),
            column.SubItemNo
          )
        : props.wip === true
        ? await APIFetchItemLedgerDetailsWipTest(
            selectedYear,
            months[monthIndex].monthNumber,
            branchSelected.locations,
            column.SubItem.trim(),
            column.SubItemNo
          )
        : await APIFetchItemLedgerDetails(
            selectedYear,
            months[monthIndex].monthNumber,
            branchSelected.locations,
            column.SubItem.trim(),
            column.SubItemNo
          );
      setLedgerData(res.data);
      setModalLoading(false);
    }
  };

  const [modalLoading, setModalLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [ledgerData, setLedgerData] = useState([]);

  const sortValues: any = {
    None: "None",
    Revenue: "Net Sales",
    GP: "Gross Profit",
    "Net Income": "Net Income/(Loss)",
  };
  const [selectedSort, setSelectedSort] = useState<any>(sortValues.None);

  useEffect(() => {
    if (selectedSort === sortValues.None) {
      setTableHeaders([...TABLE_MONTH_HEADERS(), ...TABLE_OTHER_HEADERS]);
    } else {
      if (data1.length > 0) {
        const sortValue = data1.find(
          (d: any) => d.Item.trim() === sortValues[selectedSort]
        );
        const sortedHeaders = TABLE_MONTH_HEADERS().sort((a, b) =>
          sortValue[a] < sortValue[b] ? 1 : -1
        );
        setTableHeaders([...sortedHeaders, ...TABLE_OTHER_HEADERS]);
      }
    }
  }, [selectedSort, data1]);

  const resetFilters = () => {
    setBranchSelected(branches?.[0]);
    setSelectedYear("2023");
    setSelectedSort(sortValues.None);
    setHideRows(false);
    const alteredData = data1.map((d: any) => {
      d.showDetails = false;
      return d;
    });
    setData(alteredData);
  };

  return (
    <main className={"dashboard screen financial-report"}>
      <header className="flex flex-col xl:flex-row xl:justify-between  gap-1 mb-2">
        <div className="flex flex-col gap-2 w-full lg:w-[30%] ">
          <div className="flex items-center relative">
            <div title="Reset to default" className="">
              <ActionIcon onClick={resetFilters}>
                <i className="material-icons" style={{ fontSize: "32px" }}>
                  chevron_left
                </i>
              </ActionIcon>
            </div>
            <a
              href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
              target={"_blank"}
              rel="noreferrer"
            >
              <i className="home-btn-financial material-icons">home</i>
            </a>
            <div className="text-base font-bold ">
              {header?.header_title ?? "Financial Report"}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2  self-center ">
          {branchSelected && (
            <div className="text-sm font-medium p-2 bg-tertiary text-center text-white">
              {branchSelected?.locations ?? ""}
            </div>
          )}
          {header?.header_subtitle && (
            <div className="text-xs bg-[#cddfdb] p-2 text-center">
              {header?.header_subtitle ?? ""}
            </div>
          )}
        </div>
        {branches.length > 0 && (
          <div className="flex flex-col gap-2 items-end w-[30%] self-end">
            <div className="flex items-end gap-2">
              <div className="w-20 lg:w-40">
                <button
                  onClick={() => {
                    setHideRows(!hideRows);
                  }}
                  className={`rounded border border-slate-400 flex items-center justify-center gap-2 w-full h-[30px] p-1`}
                >
                  <i className="material-icons text-base">
                    {hideRows ? "visibility" : "visibility_off"}
                  </i>

                  <span className="text-[0.5rem] lg:text-[0.7rem]">
                    {hideRows ? "Show all data" : "Show Particulars Only"}
                  </span>
                </button>
              </div>
              <div className="w-40">
                <Select
                  value={selectedSort}
                  onChange={setSelectedSort}
                  data={Object.keys(sortValues)}
                  size="xs"
                  label="Sort Columns By"
                  disabled={data1?.length === 0}
                />
              </div>
              <div className="w-30 lg:w-40">
                <Select
                  value={selectedYear}
                  onChange={setSelectedYear}
                  data={["2024", "2023", "2022"]}
                  size="xs"
                  label="Year"
                />
              </div>
            </div>
            <div className="flex gap-2">
              {/* <div className="flex gap-1"> */}
              {allowPublish && (
                <Menu width={150}>
                  <Menu.Target>
                    <button className="rounded p-2 bg-tertiary text-white w-40">
                      Publish Data
                    </button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    {selectedYear === "2024" && (
                      <Menu.Item onClick={previewMonthlyFinancialReport}>
                        Previous Month
                      </Menu.Item>
                    )}
                    <Menu.Item onClick={previewYearlyFinancialReport}>
                      YTD
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
              <Menu width={150}>
                <Menu.Target>
                  <button className="rounded p-2 bg-black text-white w-40">
                    Export Data
                  </button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item onClick={() => exportData("csv")}>CSV</Menu.Item>
                  <Menu.Item onClick={() => exportData("excel")}>
                    Excel
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
          </div>
        )}
      </header>
      {branches.length > 0 ? (
        <div className="flex gap-2 overflow-hidden">
          <div className="branches-list lg:w-[9%] w-[20%] items-center flex flex-col gap-2 overflow-y-auto border border-gray">
            <div className="font-bold sticky top-0 bg-white w-full text-center p-1">
              Select Branch
            </div>
            <div className="branches flex flex-col w-full h-full">
              {branches.length > 0 ? (
                branches.map((branch: any) => {
                  return (
                    <div
                      key={branch.locations}
                      className={`branch-menu cursor-pointer border border-1 border-gray  ${
                        branchSelected?.locations === branch.locations
                          ? "bg-tertiary text-white"
                          : ""
                      } px-0 lg:px-3 py-1 ${
                        branch.flag === 1 ? "font-bold" : ""
                      }`}
                      onClick={() => setBranchSelected(branch)}
                    >
                      <div
                        // style={{ paddingLeft: (7 - branch.level) * 3 + "px" }}
                        style={{
                          paddingLeft:
                            branch.locations.split(" ").pop() === "Combined" &&
                            branch.SubArea === "VP"
                              ? (7 - (branch.level - 4)) * 4 + "px"
                              : branch.locations.split(" ").pop() === "Combined"
                              ? (7 - (branch.level - 1)) * 4 + "px"
                              : (7 - branch.level) * 4 + "px",
                        }}
                      >
                        {branch.locations}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-sm text-gray-500">
                  <span className="text-center">
                    You are not assigned to any branches.
                  </span>
                </div>
              )}
            </div>
          </div>

          <section className={"financial-table"}>
            <div className="financial-table-headers flex text-bold bg-white w-fit 2xl:min-w-[100%]">
              <div className={"table-col particulars"}></div>
              {tableHeaders.map((header, index) => (
                <div className="table-col" key={index}>
                  {header}
                </div>
              ))}
            </div>

            {loading ? (
              <section
                className={"h-full w-full flex justify-center items-center"}
              >
                <Loader />
              </section>
            ) : data1.length > 0 ? (
              data1?.map((column: any, index: number) => {
                return (
                  <div key={index}>
                    <div
                      className={`financial-table-data w-fit 2xl:min-w-[100%] ${
                        getHighlights(column)
                          ? "bg-primary text-white font-bold"
                          : hideRows
                          ? "hidden"
                          : "hover:bg-[#daede8]"
                      }`}
                      key={index}
                    >
                      <div className="financial-table-row">
                        <div
                          className={`table-col particulars block ${
                            column["Item"].includes(":") ? "text-bold" : ""
                          }`}
                        >
                          <div
                            onClick={() => showDetails(column, index)}
                            className="cursor-pointer flex items-center"
                          >
                            {!(
                              (getHighlights(column) &&
                                column.Category !== "Whole") ||
                              column.Item.includes(":") ||
                              branchSelected?.Level > 1 ||
                              !assignedBranches.includes(
                                branchSelected.locations
                              ) ||
                              (props.wip === true
                                ? selectedYear === "2022"
                                : "")
                            ) ? (
                              <i className="material-icons text-base font-bold leading-none">
                                {column.showDetails
                                  ? "expand_more"
                                  : "chevron_right"}
                              </i>
                            ) : (
                              ""
                            )}
                            {column["Item"]}
                          </div>
                        </div>
                        {tableHeaders.map((header: any, index: number) => (
                          <div
                            className={`table-col ${
                              column["Item"].includes(":") ? "bg-gray" : ""
                            }`}
                            key={index}
                          >
                            <span>
                              {column["Item"].includes(":")
                                ? ""
                                : formatCellFinancial(column[header], index)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    {column.showDetails &&
                      column.subData &&
                      column.subData.map((subColumn: any, ind: number) => {
                        return (
                          <div
                            className={`financial-table-data${
                              hideRows ? "hidden" : ""
                            }`}
                            key={ind}
                          >
                            <div className="financial-table-row hover:bg-[#daede8] w-fit 2xl:min-w-[100%]">
                              <div className={`table-col particulars block`}>
                                <div className="pl-10">
                                  {subColumn.SubItem.trim()}
                                </div>
                              </div>
                              {tableHeaders.map((header: any, i: number) => (
                                <div
                                  className={`table-col cursor-pointer`}
                                  key={i}
                                  onClick={() => showLedgerReport(subColumn, i)}
                                >
                                  <span>
                                    {formatCellFinancial(subColumn[header], i)}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col items-center justify-center h-full text-lg text-gray-500">
                <span>
                  No data available for the selected location in this year.
                </span>
                <span>
                  Please select a different location or a different year.
                </span>
              </div>
            )}

            <Modal
              opened={modalOpened}
              onClose={close}
              title="General Ledger Report"
              centered
              size="100%"
              className="overflow-hidden"
            >
              <div className="h-[80vh] overflow-hidden modal-content">
                <div className="text-bold text-sm h-[4%]">{modalTitle}</div>
                <div className="h-[96%] overflow-y-scroll">
                  {ledgerData.length > 0 && (
                    <Table
                      className=" text-[10px]"
                      verticalSpacing={1}
                      // horizontalSpacing={2}
                      fontSize="11px"
                    >
                      <thead className="sticky top-0 bg-white">
                        <tr className="text-center">
                          {ledgerHeaders &&
                            Object.values(ledgerHeaders).map(
                              (header: any, i: number) => {
                                return (
                                  <th
                                    style={{
                                      textAlign: [8, 9, 10].includes(i)
                                        ? "right"
                                        : "left",
                                    }}
                                    className={`text-[10px]`}
                                    key={i}
                                  >
                                    <span>{header}</span>
                                  </th>
                                );
                              }
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {ledgerData &&
                          ledgerData.map((ledger: any, i) => {
                            return ledger.is_total_value ? (
                              <>
                                <tr className="text-bold">
                                  <td colSpan={8}>
                                    {ledger?.location === "All"
                                      ? `Grand Total`
                                      : `Totals for ${ledger?.location} (${ledger?.locationid})`}
                                  </td>
                                  <td className="text-right">
                                    {ledger?.debit}
                                  </td>
                                  <td className="text-right">
                                    {ledger?.credit}
                                  </td>
                                  <td className="text-right">
                                    {(ledger?.debit - ledger?.credit).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>
                                <tr className="h-5"></tr>
                              </>
                            ) : (
                              <tr key={i}>
                                {ledgerHeaders &&
                                  Object.keys(ledgerHeaders).map(
                                    (header, ind) => {
                                      return (
                                        <td
                                          key={ind}
                                          className={`${
                                            [8, 9].includes(ind)
                                              ? "text-blue"
                                              : ""
                                          } ${
                                            [8, 9, 10].includes(ind)
                                              ? "text-right"
                                              : ""
                                          }`}
                                        >
                                          {(header === "debit" ||
                                            header === "credit") &&
                                          ledger[header] === 0
                                            ? ""
                                            : ledger[header]}
                                        </td>
                                      );
                                    }
                                  )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                  {!modalLoading && ledgerData.length === 0 && (
                    <div className="flex items-center justify-center h-full">
                      No data to display
                    </div>
                  )}
                </div>
              </div>
              <ContentLoader loading={modalLoading} />
            </Modal>
          </section>
        </div>
      ) : (
        !branchesLoading && (
          <div className="flex flex-col items-center justify-center h-[100vh] text-lg text-gray-500">
            <span className="text-center">
              You are not assigned to any branches.
            </span>
          </div>
        )
      )}
      {alertDialog && (
        <AlertDialog>
          <div>{message}</div>
        </AlertDialog>
      )}
    </main>
  );
};
