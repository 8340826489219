/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Filters } from "../components/Filters";
import { TableHeaders } from "../components/modules/weeklyReport/TableHeaders";
import { SelectedReport } from "../components/modules/weeklyReport/SelectedReport";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCalendarWeeks, setWeeklyReportType } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import "../assets/scss/CustomReport.scss";
import { CompanySummarySidebar } from "../components/modules/companySummary/CompanySummarySidebar";
import HomeButton from "../components/common/HomeButton";

export const WeeklyReport = (props: any) => {
  const [exportToggle, setExportToggle] = useState();
  const [selectedLevel, setSelectedLevel] = useState(
    props.adaOnly ? "ADA" : "Company"
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCalendarWeeks());
  }, []);

  useEffect(() => {
    document.title = "Weekly Report";
  });

  return (
    <main
      className={"dashboard screen custom-report  w-[100vw] overflow-hidden"}
    >
      <Loader />
      <HomeButton adaOnly={props.adaOnly} />
      <div className={"sidebar-section"}>
        <CompanySummarySidebar />
      </div>
      <div className={"main-content  "}>
        {/* <div className="w-full flex justify-center items-center gap-4 mt-1">
          {companyLevels.map((v: any) => {
            return (
              <div
                className={`border-[1px] rounded-lg p-2 px-4 text-xs text-center  ${
                  props.isAscendAdaAccess ? "cursor-pointer" : "w-[220px] "
                } shadow-md 
                 ${
                   selectedLevel === v.level
                     ? "bg-[#3EA333] text-white"
                     : "hover:bg-[#ebf0f2]"
                 }`}
                onClick={() => {
                  setSelectedLevel(v.level);
                  dispatch(setWeeklyReportType("Branch"));
                }}
              >
                {v.name}
              </div>
            );
          })}
        </div> */}
        <div className="content-header ">
          <Filters exportData={setExportToggle} selectedLevel={selectedLevel} />
        </div>
        <div className="table-area overflow-auto pr-4">
          <TableHeaders />
          <SelectedReport
            exportData={exportToggle}
            selectedLevel={selectedLevel}
          />
        </div>
      </div>
    </main>
  );
};
