/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest, PostRequest } from "../plugins/https";

export const APIGetCompanyCalendarWeekData = (
  { startDate }: { startDate: string } = { startDate: "2023-01-01" }
) => {
  return PostRequest("daily-numbers/calendar-data/company", { startDate });
};

export const APIGetAreaCalendarWeekData = ({
  startDate,
  area,
}: {
  startDate: string;
  area: string;
}) => {
  return PostRequest("daily-numbers/calendar-data/area", { startDate, area });
};

export const APIGetVPCalendarWeekData = ({
  startDate,
  subarea,
}: {
  startDate: string;
  subarea: string;
}) => {
  return PostRequest("daily-numbers/calendar-data/vp", { startDate, subarea });
};

export const APIGetRegionCalendarWeekData = ({
  startDate,
  region,
}: {
  startDate: string;
  region: string;
}) => {
  return PostRequest("daily-numbers/calendar-data/region", {
    startDate,
    region,
  });
};
export const APIGetDistrictCalendarWeekData = ({
  startDate,
  district,
}: {
  startDate: string;
  district: string;
}) => {
  return PostRequest("daily-numbers/calendar-data/district", {
    startDate,
    district,
  });
};
export const APIGetADACalendarWeekData = ({
  startDate,
}: {
  startDate: string;
}) => {
  return PostRequest("daily-numbers/calendar-data/ada", {
    startDate,
  });
};

export const APIGetCompanyRevPerEmployeeDay = () => {
  return GetRequest("daily-numbers/revenue/company");
};
export const APIGetAreaRevPerEmployeeDay = (area: string) => {
  return GetRequest("daily-numbers/revenue/area/" + area);
};

export const APIGetVpRevPerEmployeeDay = (area: string) => {
  return GetRequest("daily-numbers/revenue/vp/" + area);
};

export const APIGetRegionRevPerEmployeeDay = (region: string) => {
  return GetRequest("daily-numbers/revenue/region/" + region);
};
export const APIGetDistrictRevPerEmployeeDay = (district: string) => {
  return GetRequest("daily-numbers/revenue/district/" + district);
};
export const APIGetADARevPerEmployeeDay = () => {
  return GetRequest("daily-numbers/revenue/ada");
};

export const APIGetCompanyNetStarts = () => {
  return GetRequest("daily-numbers/net-starts/company");
};

export const APIGetAreaNetStarts = (area: string) => {
  return GetRequest("daily-numbers/net-starts/area/" + area);
};

export const APIGetVpNetStarts = (vp: string) => {
  return GetRequest("daily-numbers/net-starts/vp/" + vp);
};

export const APIGetRegionNetStarts = (region: string) => {
  return GetRequest("daily-numbers/net-starts/region/" + region);
};
export const APIGetDistrictNetStarts = (district: string) => {
  return GetRequest("daily-numbers/net-starts/district/" + district);
};
export const APIGetADANetStarts = () => {
  return GetRequest("daily-numbers/net-starts/ada");
};

export const APIGetCompanyAvgDailyNumbersCompany = () => {
  return GetRequest("daily-numbers/avg-number/company");
};
export const APIGetAvgDailyNumbersADA = () => {
  return GetRequest("daily-numbers/avg-number/ada");
};
export const APIGetAreaAvgDailyNumbersArea = (area: string) => {
  return GetRequest("daily-numbers/avg-number/area/" + area);
};

export const APIGetAreaAvgDailyNumbersVp = (vp: string) => {
  return GetRequest("daily-numbers/avg-number/vp/" + vp);
};

export const APIGetRegionAvgDailyNumbersRegion = (region: string) => {
  return GetRequest("daily-numbers/avg-number/region/" + region);
};

export const APIGetRegionAvgDailyNumbersDistrict = (district: string) => {
  return GetRequest("daily-numbers/avg-number/district/" + district);
};

export const APIGetCompanyTrend = () => {
  return GetRequest("daily-numbers/company-trend");
};

export const APIGetAreaTrend = (area: string) => {
  return GetRequest("daily-numbers/area-trend/" + area);
};

export const APIGetVpTrend = (vp: string) => {
  return GetRequest("daily-numbers/vp-trend/" + vp);
};

export const APIGetRegionTrend = (region: string) => {
  return GetRequest("daily-numbers/region-trend/" + region);
};
export const APIGetDistrictTrend = (district: string) => {
  return GetRequest("daily-numbers/district-trend/" + district);
};
export const APIGetADATrend = () => {
  return GetRequest("daily-numbers/ada-trend");
};

export const APIGetDailyRegions = () => {
  return GetRequest("daily-numbers/regions");
};

export const APIGetCompanyComparison = ({ date }: { date: string }) => {
  return PostRequest("daily-numbers/year-comparison/company", { date });
};
export const APIGetAreaComparison = ({
  date,
  area,
}: {
  date: string;
  area: string;
}) => {
  return PostRequest("daily-numbers/year-comparison/area", { date, area });
};
export const APIGetVpComparison = ({
  date,
  vp,
}: {
  date: string;
  vp: string;
}) => {
  return PostRequest("daily-numbers/year-comparison/vp", { date, vp });
};

export const APIGetRegionComparison = ({
  date,
  region,
}: {
  date: string;
  region: string;
}) => {
  return PostRequest("daily-numbers/year-comparison/region", { date, region });
};
export const APIGetDistrictComparison = ({
  date,
  district,
}: {
  date: string;
  district: string;
}) => {
  return PostRequest("daily-numbers/year-comparison/district", {
    date,
    district,
  });
};
export const APIGetADAComparison = ({ date }: { date: string }) => {
  return PostRequest("daily-numbers/year-comparison/ada", { date });
};
