import { Table } from "@mantine/core";
import {
  getCalculatedPercentage,
  getMonthWiseSumOfData,
} from "./utils/tableFormulae";
import {
  HIGHLIGHTED_PARTICULARS,
  TABLE_MONTH_HEADERS,
} from "../../../utils/mock-data/financial-report";
import {
  INPUT_ROWS,
  ITEMS,
  NULL_ITEMS,
  NULLVALUE_ROWS,
  QUARTERLIST,
  quarterMonths,
} from "./utils/constants";
import {
  formatAmount,
  formatPercentValue,
} from "../../../utils/helper/cellFormatter";

const TableComponent = (props: any) => {
  const {
    tableHeaders,
    filteredGoalData,
    netSalesYTD,
    netSalesQuarters,
    calculateSum,
    netSalesRow,
    salesPayrollRow,
    salesCommissionRow,
    officePayrollRow,
    activeCell,
    setActiveCell,
    branchSelected,
    level,
    editedData,
    calcData,
    highlight,
    handleChangeRowData,
    getHighlightStyle,
    handleHilight,
    handleInputChange,
  } = props;

  const handleFocus = (
    branchSelected: string,
    categories: string,
    items: string,
    header: string
  ) => {
    setActiveCell((prevState: any) => ({
      branch: branchSelected,
      category: categories,
      item: items,
      header: prevState.header ? [...prevState.header, header] : [header],
    }));
    handleHilight(branchSelected, categories, items, header);
  };

  return (
    <Table
      fontSize={11}
      verticalSpacing={3}
      withBorder
      withColumnBorders
      horizontalSpacing={1}
    >
      <thead>
        <tr>
          <th className="min-w-[140px] sticky left-0 top-0 z-30 bg-white"></th>
          {tableHeaders.map((header: string, index: number) => {
            return (
              <th
                key={index}
                className={`text-center sticky top-0 bg-white z-10 h-8 ${
                  header.length === 0 ? "min-w-[5px]" : "min-w-[70px]"
                }`}
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {filteredGoalData &&
          filteredGoalData.length !== 0 &&
          filteredGoalData
            .filter((data: any) =>
              level === "Branch"
                ? isNaN(parseFloat(data.items)) &&
                  data.items !== "Acquisition Expense"
                : isNaN(parseFloat(data.items)) &&
                  !NULL_ITEMS.includes(data.items)
            )
            .map((data: any) => {
              const currentYTD = getMonthWiseSumOfData(
                data,
                TABLE_MONTH_HEADERS()
              );
              const percentageYTD = getCalculatedPercentage(
                currentYTD,
                netSalesYTD
              );
              // Get current data values for each quarter
              const currentQuarters = {
                Q1: getMonthWiseSumOfData(data, quarterMonths.Q1),
                Q2: getMonthWiseSumOfData(data, quarterMonths.Q2),
                Q3: getMonthWiseSumOfData(data, quarterMonths.Q3),
                Q4: getMonthWiseSumOfData(data, quarterMonths.Q4),
              };
              // Calculate percentages for each quarter
              const percentageQuarters = {
                Q1: getCalculatedPercentage(
                  currentQuarters.Q1,
                  netSalesQuarters.Q1
                ),
                Q2: getCalculatedPercentage(
                  currentQuarters.Q2,
                  netSalesQuarters.Q2
                ),
                Q3: getCalculatedPercentage(
                  currentQuarters.Q3,
                  netSalesQuarters.Q3
                ),
                Q4: getCalculatedPercentage(
                  currentQuarters.Q4,
                  netSalesQuarters.Q4
                ),
              };
              const currentRow = filteredGoalData.find(
                (d: any) =>
                  d.items === data.items && d.Categories === data.Categories
              );
              const totalTaxRate = data.Total;
              const salesTaxRate = data.Sales;
              const officeTaxRate = data.Office;
              const taxRate = {
                totalTaxRate,
                salesTaxRate,
                officeTaxRate,
              };
              const totalSum = calculateSum(
                data.items,
                data,
                TABLE_MONTH_HEADERS(),
                netSalesRow,
                currentRow,
                salesPayrollRow,
                salesCommissionRow,
                officePayrollRow,
                taxRate
              );
              const totalPerc = totalSum / netSalesYTD;
              const nullValueRows = NULLVALUE_ROWS.includes(data.sorting);
              const calculatedRow: any =
                calcData.find(
                  (d: any) =>
                    d.items === data.items && d.Categories === data.Categories
                ) ?? "";
              return (
                <tr>
                  <td
                    key={data.items}
                    className={`flex justify-between sticky left-0 z-20  ${
                      HIGHLIGHTED_PARTICULARS.includes(data.items.trim())
                        ? "font-bold"
                        : ""
                    } ${
                      data.items === ITEMS.STAFFINGMANAGER
                        ? "bg-[#ffd0a3]"
                        : data.items === ITEMS.INCOMEMODEL
                        ? "bg-[#CCC0DA]"
                        : HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                          level !== "Branch"
                        ? "bg-tertiary text-white"
                        : "bg-white"
                    } `}
                    style={{ paddingLeft: 5 }}
                  >
                    {data.items}
                    {/* {activeCell.item === data.items &&
                      activeCell.item === data.items &&
                      activeCell.branch === branchSelected && (
                        <i
                          className="material-icons text-base font-bold text-[#5ad606] hover:text-green-700 hover:cursor-pointer"
                          onClick={() =>
                            handleChangeRowData(
                              branchSelected,
                              data.Categories,
                              data.items
                            )
                          }
                        >
                          check
                        </i>
                      )} */}
                  </td>
                  {TABLE_MONTH_HEADERS().map(
                    (header: string, index: number) => {
                      const editedRow = editedData.find(
                        (d: any) =>
                          d.Branch === branchSelected &&
                          d.items === data.items &&
                          d.Categories === data.Categories
                      )?.[header];

                      return (
                        <td
                          key={index}
                          className={`${
                            data.flags === 1
                              ? "bg-[#fceec5]"
                              : data.items === ITEMS.STAFFINGMANAGER
                              ? "bg-[#ffd0a3]"
                              : data.items === ITEMS.INCOMEMODEL
                              ? "bg-[#CCC0DA]"
                              : HIGHLIGHTED_PARTICULARS.includes(
                                  data.items.trim()
                                ) && level !== "Branch"
                              ? "bg-tertiary text-white"
                              : "bg-white"
                          }`}
                          style={getHighlightStyle(
                            branchSelected,
                            data.Categories,
                            data.items,
                            header
                          )}
                        >
                          {data.flags === 1 ? (
                            (activeCell.branch === data.Branch &&
                              activeCell.category === data.Categories &&
                              activeCell.item === data.items &&
                              activeCell.header?.includes(header)) ||
                            (highlight.branch === data.Branch &&
                              highlight.category === data.Categories &&
                              highlight.item === data.items &&
                              highlight.header === header) ? (
                              <input
                                className="focus:outline-none w-[70px] bg-[#fceec5] "
                                onFocus={() =>
                                  handleHilight(
                                    branchSelected,
                                    data.Categories,
                                    data.items,
                                    header
                                  )
                                }
                                value={
                                  editedRow !== undefined
                                    ? editedRow
                                    : data[header]
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    branchSelected,
                                    data.Categories,
                                    data.items,
                                    header,
                                    e.target.value
                                  )
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Delete") {
                                    handleInputChange(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header,
                                      ""
                                    );
                                  } else if (
                                    e.key === "Enter" ||
                                    e.key === "Tab"
                                  ) {
                                    handleChangeRowData(
                                      branchSelected,
                                      data.Categories,
                                      data.items
                                    );
                                  }
                                }}
                              />
                            ) : (
                              <input
                                className="focus:outline-none w-[70px] bg-[#fceec5] readonly "
                                onFocus={() =>
                                  handleFocus(
                                    branchSelected,
                                    data.Categories,
                                    data.items,
                                    header
                                  )
                                }
                                onClick={() =>
                                  handleFocus(
                                    branchSelected,
                                    data.Categories,
                                    data.items,
                                    header
                                  )
                                }
                                readOnly
                                value={`${formatAmount(data[header])}`}
                              />
                            )
                          ) : (
                            <div
                              onMouseDown={() =>
                                handleHilight(
                                  branchSelected,
                                  data.Categories,
                                  data.items,
                                  header
                                )
                              }
                            >
                              {level === "Branch"
                                ? data.items === ITEMS.STAFFINGMANAGER
                                  ? parseFloat(calculatedRow[header]).toFixed(2)
                                  : formatAmount(calculatedRow[header])
                                : formatAmount(data[header])}
                            </div> //Month wise calculated values
                          )}
                        </td>
                      );
                    }
                  )}

                  <td
                    className={`${
                      data.items === ITEMS.INCOMEMODEL
                        ? "bg-[#CCC0DA]"
                        : HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                          level !== "Branch"
                        ? "bg-tertiary text-white"
                        : "bg-white"
                    }`}
                    style={getHighlightStyle(
                      branchSelected,
                      data.Categories,
                      data.items,
                      "YTD"
                    )}
                    onMouseDown={() =>
                      handleHilight(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "YTD"
                      )
                    }
                  >
                    {nullValueRows
                      ? null
                      : level === "Branch"
                      ? INPUT_ROWS.includes(data.items)
                        ? formatAmount(currentYTD)
                        : formatAmount(calculatedRow["YTD"])
                      : formatAmount(currentYTD)}
                  </td>
                  <td
                    className={`${
                      HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                      level !== "Branch"
                        ? "bg-tertiary text-white"
                        : "bg-white"
                    }`}
                    style={getHighlightStyle(
                      branchSelected,
                      data.Categories,
                      data.items,
                      "YTD %"
                    )}
                    onMouseDown={() =>
                      handleHilight(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "YTD %"
                      )
                    }
                  >
                    {nullValueRows
                      ? null
                      : level === "Branch"
                      ? INPUT_ROWS.includes(data.items)
                        ? formatPercentValue(percentageYTD.toString())
                        : formatPercentValue(calculatedRow["YTD %"])
                      : formatPercentValue(percentageYTD.toString())}
                  </td>
                  <td
                    className={`${
                      HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                      level !== "Branch"
                        ? "bg-tertiary text-white"
                        : "bg-white"
                    }`}
                  ></td>
                  {level === "Branch" &&
                    QUARTERLIST.map(
                      (q: {
                        quarter: string;
                        quarterPercentage: string;
                        name: string;
                      }) => {
                        // const quarterSum = calculateSum(
                        //   data.items,
                        //   data,
                        //   quarterMonths[q.name],
                        //   netSalesRow,
                        //   currentRow,
                        //   salesPayrollRow,
                        //   salesCommissionRow,
                        //   officePayrollRow,
                        //   taxRate
                        // );
                        // const quarterPerc =
                        //   quarterSum /
                        //   netSalesQuarters[
                        //     q.name as keyof typeof percentageQuarters
                        //   ];
                        return (
                          <>
                            <td
                              className={` ${
                                data.QuarterflagQ === 1
                                  ? "bg-[#fceec5]"
                                  : HIGHLIGHTED_PARTICULARS.includes(
                                      data.items.trim()
                                    ) && level !== "Branch"
                                  ? "bg-tertiary text-white"
                                  : "bg-white"
                              }`}
                              style={getHighlightStyle(
                                branchSelected,
                                data.Categories,
                                data.items,
                                q.quarter
                              )}
                            >
                              {data.QuarterflagQ === 1 ? (
                                (activeCell.branch === data.Branch &&
                                  activeCell.category === data.Categories &&
                                  activeCell.item === data.items &&
                                  activeCell.header?.includes(q.quarter)) ||
                                (highlight.branch === data.Branch &&
                                  highlight.category === data.Categories &&
                                  highlight.item === data.items &&
                                  highlight.header === q.quarter) ? (
                                  <input
                                    className="focus:outline-none w-[70px] bg-[#fceec5] "
                                    onFocus={() =>
                                      handleHilight(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarter
                                      )
                                    }
                                    value={
                                      editedData.find(
                                        (d: any) =>
                                          d.Branch === branchSelected &&
                                          d.items === data.items &&
                                          d.Categories === data.Categories
                                      )?.[q.quarter] !== undefined
                                        ? editedData.find(
                                            (d: any) =>
                                              d.Branch === branchSelected &&
                                              d.items === data.items &&
                                              d.Categories === data.Categories
                                          )?.[q.quarter]
                                        : data[q.quarter]
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarter,
                                        e.target.value
                                      )
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Delete") {
                                        handleInputChange(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarter,
                                          ""
                                        );
                                      } else if (
                                        e.key === "Enter" ||
                                        e.key === "Tab"
                                      ) {
                                        handleChangeRowData(
                                          branchSelected,
                                          data.Categories,
                                          data.items
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <input
                                    className="focus:outline-none w-[70px] bg-[#fceec5] "
                                    onFocus={() =>
                                      handleFocus(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarter
                                      )
                                    }
                                    onClick={() =>
                                      handleFocus(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarter
                                      )
                                    }
                                    readOnly
                                    value={`${formatAmount(data[q.quarter])}`}
                                  />
                                )
                              ) : (
                                <div
                                  onMouseDown={() =>
                                    handleHilight(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      q.quarter
                                    )
                                  }
                                >
                                  {nullValueRows
                                    ? null
                                    : level === "Branch"
                                    ? INPUT_ROWS.includes(data.items)
                                      ? formatAmount(
                                          currentQuarters[
                                            q.name as keyof typeof currentQuarters
                                          ]
                                        )
                                      : formatAmount(calculatedRow[q.quarter])
                                    : formatAmount(
                                        currentQuarters[
                                          q.name as keyof typeof currentQuarters
                                        ]
                                      )}
                                </div>
                              )}
                            </td>
                            <td
                              className={` ${
                                data.Quarterflagperc === 1
                                  ? "bg-[#fceec5]"
                                  : HIGHLIGHTED_PARTICULARS.includes(
                                      data.items.trim()
                                    ) && level !== "Branch"
                                  ? "bg-tertiary text-white"
                                  : "bg-white"
                              }`}
                              style={getHighlightStyle(
                                branchSelected,
                                data.Categories,
                                data.items,
                                q.quarterPercentage
                              )}
                            >
                              {data.Quarterflagperc === 1 ? (
                                (activeCell.branch === data.Branch &&
                                  activeCell.category === data.Categories &&
                                  activeCell.item === data.items &&
                                  activeCell.header?.includes(
                                    q.quarterPercentage
                                  )) ||
                                (highlight.branch === data.Branch &&
                                  highlight.category === data.Categories &&
                                  highlight.item === data.items &&
                                  highlight.header === q.quarterPercentage) ? (
                                  <input
                                    className="focus:outline-none w-[70px] bg-[#fceec5] "
                                    onFocus={() =>
                                      handleHilight(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarterPercentage
                                      )
                                    }
                                    value={
                                      editedData.find(
                                        (d: any) =>
                                          d.Branch === branchSelected &&
                                          d.items === data.items &&
                                          d.Categories === data.Categories
                                      )?.[q.quarterPercentage] !== undefined
                                        ? editedData.find(
                                            (d: any) =>
                                              d.Branch === branchSelected &&
                                              d.items === data.items &&
                                              d.Categories === data.Categories
                                          )?.[q.quarterPercentage]
                                        : data[q.quarterPercentage]
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Delete") {
                                        handleInputChange(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          q.quarterPercentage,
                                          ""
                                        );
                                      } else if (
                                        e.key === "Enter" ||
                                        e.key === "Tab"
                                      ) {
                                        handleChangeRowData(
                                          branchSelected,
                                          data.Categories,
                                          data.items
                                        );
                                      }
                                    }}
                                    onChange={(e) =>
                                      handleInputChange(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarterPercentage,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    className="focus:outline-none w-[70px] bg-[#fceec5] "
                                    onFocus={() =>
                                      handleFocus(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarterPercentage
                                      )
                                    }
                                    onClick={() =>
                                      handleFocus(
                                        branchSelected,
                                        data.Categories,
                                        data.items,
                                        q.quarterPercentage
                                      )
                                    }
                                    readOnly
                                    value={`${formatPercentValue(
                                      data[q.quarterPercentage]
                                    )}`}
                                  />
                                )
                              ) : (
                                <div
                                  onMouseDown={() =>
                                    handleHilight(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      q.quarterPercentage
                                    )
                                  }
                                >
                                  {nullValueRows
                                    ? null
                                    : level === "Branch"
                                    ? INPUT_ROWS.includes(data.items)
                                      ? formatPercentValue(
                                          percentageQuarters[
                                            q.name as keyof typeof percentageQuarters
                                          ].toString()
                                        )
                                      : formatPercentValue(
                                          calculatedRow[q.quarterPercentage]
                                        )
                                    : formatPercentValue(
                                        percentageQuarters[
                                          q.name as keyof typeof percentageQuarters
                                        ].toString()
                                      )}
                                </div>
                              )}
                            </td>
                            <td
                              className={`${
                                HIGHLIGHTED_PARTICULARS.includes(
                                  data.items.trim()
                                ) && level !== "Branch"
                                  ? "bg-tertiary text-white"
                                  : "bg-white"
                              }`}
                            ></td>
                          </>
                        );
                      }
                    )}
                </tr>
              );
            })}{" "}
      </tbody>
    </Table>
  );
};

export default TableComponent;
