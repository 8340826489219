/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Route, Routes } from "react-router";
import { WeeklyReport } from "../pages/WeeklyReport";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import { ArAgingDashboard } from "../pages/ArAgingDashboard";
import { Profile } from "../pages/Profile";
import { CompanySummaryReport } from "../pages/CompanySummaryReport";
import { Landing } from "../pages/Landing";
import { DailyNumberReport } from "../pages/DailyNumberReport";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Forbidden } from "../pages/Forbidden";
import { getAllUserReports, getUserGroups } from "../api/user";
import { useEffect, useState } from "react";
import { loginRequest } from "../config/authConfig";
import { REPORT_NAMES } from "../utils/helper/constants";
import { DemoCalendar } from "../pages/DemoCalendar";
import { ClientSegmentation } from "../pages/ClientSegmentation";
import { PipSummaryReport } from "../pages/PipSummaryReport";
import { setUserHasNotesAccess } from "../store/Filter/actions";
import { useDispatch } from "react-redux";
import { KearlKurveReport } from "../pages/KearlKurveReport";
import { FinancialReportWip } from "../pages/FinancialReportWip";
import { TopClients } from "../pages/TopClients";
// import { Drag } from "../pages/Drag";
import { setFinancialLocation } from "../store/FinancialReport/actions";
import { EmployeeRedployment } from "../pages/EmployeeRedeployment";
import { Top20Clients } from "../pages/Top20Clients";
import SalesActivity from "../pages/SalesActivity";
import { setPipBranches, setPipRegions } from "../store/PipSummary/actions";
import BranchTopFocus from "../pages/BranchTopFocus";
import SalesManager from "../pages/SalesManager";
import GoalSetting from "../pages/GoalSetting";

export const GritRoutes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accounts[0]) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance.acquireTokenSilent(request).then((response) => {
        getUserGroups(response.accessToken).then(async (res) => {
          const groups = res.value.map((group: any) => group.id);
          const userHasNotesAccess = groups.includes(
            process.env.REACT_APP_COMPANY_SUMMARY_NOTES_GROUP_ID
          );
          dispatch(setUserHasNotesAccess(userHasNotesAccess));
          const reportAccess = await getAllUserReports({
            groups: "'" + groups.join("','") + "'",
            username: accounts[0].username,
            name: accounts[0].name,
          });
          setReports(reportAccess.data);
          const location = res.value
            .filter((g: any) => g.displayName.startsWith("Location"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return {
                level: location?.[0].split("_")?.[1] ?? location?.[0],
                name: location?.[1],
              };
            });
          dispatch(setFinancialLocation(location));
          const pipRegions = res.value
            .filter((g: any) => g.displayName.startsWith("PIP_Region"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          const pipBranches = res.value
            .filter((g: any) => g.displayName.startsWith("PIP_Branch"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          dispatch(setPipRegions(pipRegions));
          dispatch(setPipBranches(pipBranches));
          setLoading(false);
        });
      });
    }
  }, [isAuthenticated, accounts, instance]);

  const hasReportAccess = (report_name: any) => {
    const exists = reports.filter(
      (report: any) => report.ReportName === report_name
    );
    return exists.length > 0;
  };

  return (
    <Routes>
      <Route
        element={
          isAuthenticated ? (
            <Landing loading={loading} reports={reports} />
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={""}
      />

      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <ArAgingDashboard adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/ar-aging-ada/*"}
          /> */}

          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/ar-aging/*"}
          />
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/ar-aging-wip/*"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.AR_AGING_REPORT) ? (
                  <ArAgingDashboard isAscendAdaAccess={false} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/ar-aging/*"}
          />
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.AR_AGING_REPORT) ? (
                  <ArAgingDashboard wip={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/ar-aging-wip/*"}
          />

          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <ArAgingDashboard adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/ar-aging-ada/*"}
          /> */}
        </>
      )}

      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT) ? (
              <FinancialReportWip test={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/financial-report-test"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT) ? (
              <FinancialReportWip wip={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/financial-report-wip"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT) ? (
              <FinancialReportWip />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/financial-report"}
      />

      {/* <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
              <FinancialReportWip adaOnly={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/financial-report-ada"}
      /> */}

      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <CompanySummaryReport adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/company-summary-ada"}
          />
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/company-summary"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
                  <CompanySummaryReport
                    isAdaAccess={hasReportAccess(
                      REPORT_NAMES.ASCEND_ADA_REPORTS
                    )}
                  />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/company-summary"}
          />
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <CompanySummaryReport adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/company-summary-ada"}
          />
        </>
      )}
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
              <CompanySummaryReport wip={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/company-summary-wip"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : (
              <CompanySummaryReport wip={true} executive={true} />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/executive-company-summary-wip"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.PIP_SUMMARY) ? (
              <CompanySummaryReport executive={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/executive-company-summary"}
      />
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <DailyNumberReport adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/daily-numbers-ada"}
          />
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/daily-numbers"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.DAILY_NUMBERS) ? (
                  <DailyNumberReport
                    isAscendAdaAccess={hasReportAccess(
                      REPORT_NAMES.ASCEND_ADA_REPORTS
                    )}
                  />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/daily-numbers"}
          />
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <DailyNumberReport adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/daily-numbers-ada"}
          />
        </>
      )}

      <Route
        element={isAuthenticated ? <Profile /> : <AuthenticatedLayout />}
        path={"/profile"}
      />
      <Route
        element={isAuthenticated ? <DemoCalendar /> : <AuthenticatedLayout />}
        path={"/calendar"}
      />

      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.PIP_SUMMARY) ? (
              <PipSummaryReport executive={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/executive-pip-summary"}
      />
      <Route
        element={
          isAuthenticated ? <PipSummaryReport /> : <AuthenticatedLayout />
        }
        path={"/pip-summary"}
      />
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <KearlKurveReport adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/kearl-kurve-ada"}
          /> */}

          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/kearl-kurve"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <KearlKurveReport isAscendAdaAccess={false} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"kearl-kurve"}
          />

          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <KearlKurveReport adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/kearl-kurve-ada"}
          /> */}
        </>
      )}
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <TopClients adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/top-clients-ada"}
          /> */}

          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/top-clients"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <TopClients isAscendAdaAccess={false} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/top-clients"}
          />

          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <TopClients adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/top-clients-ada"}
          /> */}
        </>
      )}
      <Route
        element={
          isAuthenticated ? <TopClients wip={true} /> : <AuthenticatedLayout />
        }
        path={"/top-clients-wip"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
              <CompanySummaryReport goal={true} wip={true} />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/company-summary-goal"}
      />
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <WeeklyReport adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/weekly-report-ada"}
          /> */}
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/weekly-report"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.WEEKLY_REPORT) ? (
                  <WeeklyReport isAscendAdaAccess={false} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/weekly-report"}
          />
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <WeeklyReport adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/weekly-report-ada"}
          /> */}
        </>
      )}
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
              <EmployeeRedployment />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/employee-redeployment"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
              <Top20Clients />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/top-20-clients"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
              <SalesActivity />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/sales-activity"}
      />
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <BranchTopFocus adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/branch-top-focus-ada"}
          /> */}

          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/branch-top-focus"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
                  <BranchTopFocus isAscendAdaAccess={false} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/branch-top-focus"}
          />
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <BranchTopFocus adaOnly={true} />
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/branch-top-focus-ada"}
          /> */}
        </>
      )}
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <ClientSegmentation adaOnly={true} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/client-segmentation-ada"}
          /> */}

          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/client-segmentation"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : (
                  <ClientSegmentation isAscendAdaAccess={false} />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/client-segmentation"}
          />
          {/* <Route
            element={
              isAuthenticated ? (
                loading ? (
                  <>LOADING...</>
                ) : hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS) ? (
                  <ClientSegmentation adaOnly={true} />             
                ) : (
                  <Forbidden />
                )
              ) : (
                <AuthenticatedLayout />
              )
            }
            path={"/client-segmentation-ada"}
          /> */}
        </>
      )}
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.SALES_MANAGER) ? (
              <SalesManager />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/sales-manager"}
      />
      <Route
        element={
          isAuthenticated ? (
            loading ? (
              <>LOADING...</>
            ) : hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT) ? (
              <GoalSetting />
            ) : (
              <Forbidden />
            )
          ) : (
            <AuthenticatedLayout />
          )
        }
        path={"/goal-setting"}
      />

      {/* <Route path="/drag" element={<Drag />} /> */}
      <Route element={<AuthenticatedLayout />} path={"/login"} />
    </Routes>
  );
};
