/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useDispatch, useSelector } from "react-redux";
import { setAlertDialog } from "../../store/Filter/actions";

export const AlertDialog = (props: any) => {
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(setAlertDialog(false));
  };
  return (
    <section className={"alert-dialog"}>
      <div className="alert-backdrop" onClick={closeDialog}></div>
      <div className="alert-dialog-card">
        <div className="alert-dialog-content">{props.children}</div>
        <div className="buttons-area">
          <button onClick={closeDialog}>Close</button>
        </div>
      </div>
    </section>
  );
};
