/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useDispatch, useSelector } from "react-redux";
import {
  setWeeklyReportType,
  setWeekDate,
  setRegion,
  setDistrict,
} from "../store/Filter/actions";
import { useEffect, useState } from "react";
import { ActionIcon, Button, Select } from "@mantine/core";
import {
  APIGetAllDistinctDistricts,
  APIGetAllDistinctRegions,
} from "../api/filter";

export const Filters = (props: any) => {
  const { exportData, selectedLevel } = props;
  const type = useSelector(
    (state: any) => state.filterReducer.selectedAreaType
  );
  const selectedWeek = useSelector(
    (state: any) => state.filterReducer.weekSelected
  );
  const weekEndingDates = useSelector(
    (state: any) => state["filterReducer"].weekEndingDates
  );
  const weeklyData = useSelector(
    (state: any) => state.weeklyReportReducer.weeklyData
  );
  const selectedRegion = useSelector(
    (state: any) => state.filterReducer.regionSelected
  );
  const selectedDistrict = useSelector(
    (state: any) => state.filterReducer.districtSelected
  );

  const [weeks, setWeeks] = useState([]);
  const [regionList, setRegionList] = useState<any>(["All"]);
  const [districtList, setDistrictList] = useState<any>(["All"]);
  const dispatch = useDispatch();
  useEffect(() => {
    // const weeks = weekEndingDates.slice(1, weekEndingDates.length - 1);
    setWeeks(weekEndingDates);
    if (weekEndingDates.length > 0) {
      dispatch(setWeekDate(weekEndingDates[0]["WeekEndingDate"]));
    }
  }, [weekEndingDates]);

  useEffect(() => {
    APIGetAllDistinctRegions().then(({ data }) => {
      setRegionList(["All", ...data.map((d: any) => d.Region)]);
    });
    APIGetAllDistinctDistricts().then(({ data }) => {
      setDistrictList(["All", ...data.map((d: any) => d.district)]);
    });
  }, []);

  const changeBranchType = (value: string) => {
    dispatch(setWeeklyReportType(value));
    dispatch(setRegion("All"));
  };

  const setWeekData = (value: string) => {
    dispatch(setWeekDate(value));
  };

  const branchList = [
    { value: "All", label: "All" },
    { value: "Area", label: "Area" },
    { value: "VP", label: "Subarea" },
    { value: "Region", label: "Region" },
    { value: "District", label: "Area Branch" },
    { value: "Branch", label: "Branch" },
  ];
  const ADAbranchList = [
    { value: "All", label: "All" },
    { value: "Branch", label: "Branch" },
  ];
  const weekList = weeks.map((v: any, key) => ({
    value: v.WeekEndingDate,
    label: v.WeekEndingDate,
  }));

  return (
    <article>
      <div className="absolute top-0 left-0 flex">
        <ActionIcon
          onClick={() => {
            dispatch(setWeekDate(weeks[0]["WeekEndingDate"]));
            dispatch(setWeeklyReportType("Branch"));
          }}
        >
          <i className="material-icons" style={{ fontSize: "32px" }}>
            chevron_left
          </i>
        </ActionIcon>
        <ActionIcon>
          <a
            href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
            target={"_blank"}
            rel="noreferrer"
          >
            <i
              className="text-slate-600 material-icons"
              style={{ fontSize: "28px" }}
            >
              home
            </i>
          </a>
        </ActionIcon>
      </div>
      <div className="flex flex-col items-end justify-start mb-4 sm:mb-0 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-col gap-1 w-full py-1 sm:flex-row sm:gap-4 sm:py-4 ">
          <div>
            <Select
              label="Weekly Report by"
              value={type}
              data={selectedLevel === "Company" ? branchList : ADAbranchList}
              onChange={(value: string) => changeBranchType(value)}
              size="xs"
            />
          </div>
          <div>
            <Select
              label="Select Week"
              value={selectedWeek}
              data={weekList}
              onChange={(value: string) => setWeekData(value)}
              size="xs"
            />
          </div>
          {type === "Region" && (
            <div className="">
              <Select
                label="Select Region"
                value={selectedRegion}
                defaultValue={"All"}
                data={regionList}
                onChange={(value) => dispatch(setRegion(value))}
                size="xs"
                // searchable={true}
              />
            </div>
          )}
          {type === "District" && (
            <div className="">
              <Select
                label="Select Area Branch"
                value={selectedDistrict}
                defaultValue={"All"}
                data={districtList}
                onChange={(value) => dispatch(setDistrict(value))}
                size="xs"
                // searchable={true}
              />
            </div>
          )}
        </div>
        <Button variant="filled" size="sm" onClick={exportData}>
          Export
        </Button>
      </div>
    </article>
  );
};
