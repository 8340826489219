/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import "./loader.scss";
import { useSelector } from "react-redux";

export const Loader = (props: any) => {
  const loading = useSelector((state: any) => state["filterReducer"].loading);
  return (
    (!!props.loading || loading) && (
      <section className={"loader-content"}>
        <div className="loader" />
      </section>
    )
  );
};

export const ContentLoader = (props: any) => {
  return (
    props.loading && (
      <section className={"content-loader-content"}>
        <div className="loader" />
      </section>
    )
  );
};
