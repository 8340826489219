/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import "./App.scss";
import { GritRoutes } from "./routes";

function App() {
    return (
        <div className="App">
            <GritRoutes/>
        </div>
    );
}

export default App;
