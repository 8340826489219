/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIGetClientSegmentRegions = () => {
  return GetRequest("client-segmentation/regions");
};
export const APIGetClientSegmentADABranches = () => {
  return GetRequest("client-segmentation/ada-branches");
};

export const APIFetchGpMarginSegments = () => {
  return GetRequest("/client-segmentation/segments/gp-margin");
};

export const APIFetchMonthlyGpSegments = () => {
  return GetRequest("/client-segmentation/segments/gp-monthly");
};

export const APIFetchQuarterlyGpSegments = () => {
  return GetRequest("/client-segmentation/segments/gp-quarterly");
};

export const APIFetchYearlyGpSegments = () => {
  return GetRequest("/client-segmentation/segments/gp-yearly");
};

export const APIFetchMonthlyClientSegmentation = (
  year: any,
  month: any,
  location: any,
  selectedLevel: string
) => {
  const url =
    selectedLevel === "ADA"
      ? `/client-segmentation/ada-monthly/${month}?year=${year}`
      : `/client-segmentation/monthly/${month}?year=${year}`;
  return GetRequest(`${url}${location ? "&location=" + location : ""}`);
};

export const APIFetchQuarterlyClientSegmentation = (
  year: any,
  quarter: any,
  location: any,
  selectedLevel: string
) => {
  const url =
    selectedLevel === "ADA"
      ? `/client-segmentation/ada-quarterly/${quarter}?year=${year}`
      : `/client-segmentation/quarterly/${quarter}?year=${year}`;
  return GetRequest(`${url}${location ? "&location=" + location : ""}`);
};

export const APIFetchYearlyClientSegmentation = (
  year: any,
  location: any,
  selectedLevel: string
) => {
  const url =
    selectedLevel === "ADA"
      ? `/client-segmentation/ada-yearly/${year}`
      : `/client-segmentation/yearly/${year}`;
  return GetRequest(`${url}${location ? "&location=" + location : ""}`);
};

export const APIFetchMonthlyClientSegmentationDetails = (
  year: any,
  month: any,
  location: any,
  gpSegment: any,
  gpMarginSegment: any,
  previousQuarters: any,
  selectedLevel: string
) => {
  const url =
    selectedLevel === "ADA"
      ? `/client-segmentation/details/ada-monthly/${month}?year=${year}`
      : `/client-segmentation/details/monthly/${month}?year=${year}`;
  return GetRequest(
    `${url}${location ? "&location=" + location : ""}${
      gpSegment ? "&gpSegment=" + gpSegment : ""
    }${gpMarginSegment ? "&gpMarginSegment=" + gpMarginSegment : ""}&q0=${
      previousQuarters.q0
    }&q1=${previousQuarters.q1}&q2=${previousQuarters.q2}`
  );
};

export const APIFetchQuarterlyClientSegmentationDetails = (
  year: any,
  quarter: any,
  location: any,
  gpSegment: any,
  gpMarginSegment: any,
  previousQuarters: any,
  selectedLevel: string
) => {
  const url =
    selectedLevel === "ADA"
      ? `/client-segmentation/details/ada-quarterly/${quarter}?year=${year}`
      : `/client-segmentation/details/quarterly/${quarter}?year=${year}`;
  return GetRequest(
    `${url}${location ? "&location=" + location : ""}${
      gpSegment ? "&gpSegment=" + gpSegment : ""
    }${gpMarginSegment ? "&gpMarginSegment=" + gpMarginSegment : ""}&q0=${
      previousQuarters.q0
    }&q1=${previousQuarters.q1}&q2=${previousQuarters.q2}`
  );
};

export const APIFetchYearlyClientSegmentationDetails = (
  year: any,
  location: any,
  gpSegment: any,
  gpMarginSegment: any,
  previousQuarters: any,
  selectedLevel: string
) => {
  const url =
    selectedLevel === "ADA"
      ? `/client-segmentation/details/ada-yearly/${year}`
      : `/client-segmentation/details/yearly/${year}`;
  return GetRequest(
    `${url}${location ? "&location=" + location : ""}${
      gpSegment ? "&gpSegment=" + gpSegment : ""
    }${gpMarginSegment ? "&gpMarginSegment=" + gpMarginSegment : ""}&q0=${
      previousQuarters.q0
    }&q1=${previousQuarters.q1}&q2=${previousQuarters.q2}`
  );
};
