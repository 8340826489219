/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchWeeklyTop20ClientsActivityData = (
  type: any,
  year: any,
  location: any,
  week: any
) => {
  return GetRequest(`/top-20-clients/client-activity/weekly`, {
    params: { year, location, week, type },
  });
};

export const APIFetchMonthlyTop20ClientsActivityData = (
  type: any,
  year: any,
  location: any,
  month: any
) => {
  return GetRequest(`/top-20-clients/client-activity/monthly`, {
    params: { year, location, month, type },
  });
};

export const APIFetchYearlyTop20ClientsActivityData = (
  type: any,
  year: any,
  location: any
) => {
  return GetRequest(`/top-20-clients/client-activity/yearly`, {
    params: { year, location, type },
  });
};

export const APIFetchWeeklyTop20ClientsQbrData = (
  type: any,
  year: any,
  location: any,
  week: any
) => {
  return GetRequest(`/top-20-clients/qbr/weekly`, {
    params: { year, location, week, type },
  });
};

export const APIFetchMonthlyTop20ClientsQbrData = (
  type: any,
  year: any,
  location: any,
  month: any
) => {
  return GetRequest(`/top-20-clients/qbr/monthly`, {
    params: { year, location, month, type },
  });
};

export const APIFetchYearlyTop20ClientsQbrData = (
  type: any,
  year: any,
  location: any
) => {
  return GetRequest(`/top-20-clients/qbr/yearly`, {
    params: { year, location, type },
  });
};
