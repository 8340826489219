/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use(
  function (config: any) {
    config.headers.webtoken = "ascendStaffingAzureWeb@123";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    response.status === 200 &&
      !!response.data.message &&
      console.log(response.data.message);
    return response.data;
  },
  async function (error) {
    if (error?.response?.status === 403) {
      console.error(error?.response?.data?.message, error);
      return;
    }
    if (error?.response?.status === 401) {
      console.error(
        error?.response?.data?.message ?? "Unauthorized user",
        error
      );
      localStorage.clear();
    }
    return Promise.reject(error?.response?.data?.message);
  }
);

export const sageAxios = axios.create({
  baseURL: process.env.REACT_APP_SAGE_API_URL,
});
sageAxios.interceptors.request.use(
  function (config: any) {
    config.headers.webtoken = process.env.REACT_APP_SAGE_WEBTOKEN;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

sageAxios.interceptors.response.use(
  function (response) {
    response.status === 200 &&
      !!response.data.message &&
      console.log(response.data.message);
    return response.data;
  },
  async function (error) {
    if (error?.response?.status === 403) {
      console.error(error?.response?.data?.message, error);
      return;
    }
    if (error?.response?.status === 401) {
      console.error(
        error?.response?.data?.message ?? "Unauthorized user",
        error
      );
      localStorage.clear();
    }
    return Promise.reject(error?.response?.data?.message);
  }
);

export default axios;
