/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  SET_FINANCIAL_BRANCHES,
  SET_FINANCIAL_LOCATION,
  SET_FINANCIAL_YEARS,
  SET_MONTH,
  SET_REPORT_DATA,
  SET_REPORT_HEADER,
  SET_STRUCTURE,
  SET_YEAR,
} from "./actionTypes";
import moment from "moment";

const initialState = {
  structure: {
    primaryBranch: [],
    secondaryBranch: [],
    tertiaryBranch: [],
  },
  branches: [],
  years: [],
  header: [],
  yearSelected: moment().year(),
  monthSelected: "",
  branchSelected: "",
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  data: [],
  financialLocation: null,
};
export const financialReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FINANCIAL_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case SET_REPORT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_REPORT_HEADER:
      return {
        ...state,
        header: action.payload,
      };
    case SET_FINANCIAL_YEARS:
      return {
        ...state,
        years: action.payload,
      };
    case SET_YEAR:
      return {
        ...state,
        yearSelected: action.payload,
      };
    case SET_MONTH:
      return {
        ...state,
        monthSelected: action.payload,
      };
    case SET_STRUCTURE:
      return {
        ...state,
        structure: action.payload,
      };
    case SET_FINANCIAL_LOCATION:
      return {
        ...state,
        financialLocation: action.payload,
      };
    default:
      return state;
  }
};
