/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchWebCalendarDates = () => {
  return GetRequest(`/web-calendar/dates`);
};
