/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  APIGetClientComments,
  APIGetClientCommentsADA,
} from "../../../api/ar-aging";
import {
  setArCommentBranch,
  setArCommentDialog,
} from "../../../store/ArAging/actions";
import { Loader } from "../../common/Loader";
import { setLoading } from "../../../store/Filter/actions";
import moment from "moment";

export const ArCommentDialog = (props: any) => {
  const { level } = props;
  const dispatch = useDispatch();
  const branch = useSelector(
    (state: any) => state.arAgingReducer.arCommentBranch
  );
  const loading = useSelector((state: any) => state["filterReducer"].loading);

  const [comments, setComments] = useState<any>([]);

  useEffect(() => {
    loadComments();
  }, [branch, level]);

  const loadComments = async () => {
    if (branch !== "") {
      dispatch(setLoading(true));
      const commentRetrieved: any =
        level === "ADA"
          ? await APIGetClientCommentsADA(branch)
          : await APIGetClientComments(branch);
      const sortedComments =
        [...commentRetrieved.data].sort((a: any, b: any) =>
          b["InsertDate"].localeCompare(a["InsertDate"])
        ) ?? [];
      setComments(sortedComments);
      dispatch(setLoading(false));
    }
  };

  const closeDialog = () => {
    dispatch(setArCommentBranch(""));
    dispatch(setArCommentDialog(false));
  };
  return (
    <section className={"ar-comment-section"}>
      <div className="ar-comment-backdrop" onClick={closeDialog}></div>
      {loading ? (
        <Loader />
      ) : (
        <div className="ar-comment-dialog-card">
          <div className="ar-title">
            <span className="branch-name">{branch}</span>
          </div>
          <div className="ar-comment-dialog-content">
            {comments.map((v: any, key: number) => (
              <div className="ar-comment" key={key}>
                <div
                  className={
                    "ar-content-subject text-bold flex justify-between"
                  }
                >
                  <div>Subject: {v.subject}</div>
                  <div>
                    Date: {moment(v.InsertDate).format("MMM DD, YYYY HH:MM")}
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: v.Comment }}></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
