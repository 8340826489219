/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const SET_ENABLE_TOOLTIP = "SET_ENABLE_TOOLTIP";
export const SET_SHOW_CLIENTS_RANKING = "SET_SHOW_CLIENTS_RANKING";
export const SET_SHOW_BRANCH_CLIENTS_RANKING =
  "SET_SHOW_BRANCH_CLIENTS_RANKING";
export const SET_SHOW_PIP_SUMMARY_LINES = "SET_SHOW_PIP_SUMMARY_LINES";
