/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CloseButton, Select } from "@mantine/core";
import FunnelChart from "./FunnelChart";
import BarChart from "./ClientBarChart";
import { useDisclosure } from "@mantine/hooks";
import { CustomDatePicker } from "../../common/CustomDatePicker";
import { useEffect, useRef, useState } from "react";

const SalesActivityCharts = (props: any) => {
  const {
    selectedBranchName,
    // setSelectedBranchName,
    // branchesList,
    clientNames,
    selectedClientName,
    setSelectedClientName,
    barchartData,
    barchartTitle,
    funnelChartData,
    selectedDateRange,
    setSelectedDate,
  } = props;

  const [opened, { open, close }] = useDisclosure();
  const [showInfo, setShowInfo] = useState(false);
  const buttonRef = useRef<any>(null);

  useEffect(() => {
    if (showInfo) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showInfo]);

  const handleClickOutside = (e: any) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target)) {
      setShowInfo(false);
    }
  };

  return (
    <div className="h-[80vh] relative ">
      <div className="flex w-full justify-end p-1">
        <i
          className="material-icons cursor-pointer hover:text-slate-500"
          onClick={() => setShowInfo(!showInfo)}
          ref={buttonRef}
        >
          info
        </i>
      </div>
      <div
            className={`flex gap-4 justify-end w-full`}
          >
            <Select
              size="xs"
              className={`w-[10rem] 2xl:w-[16rem] ${
                selectedBranchName && selectedBranchName !== "All"
                  ? "block"
                  : "hidden"
              }`}
              searchable
              data={["All", ...clientNames]}
              label="Client Name"
              value={selectedClientName}
              onChange={(value: any) => setSelectedClientName(value)}
            />
            <div className="relative">
              <div>
                <Select
                  id="redeployment-date"
                  className="w-[10rem] 2xl:w-[16rem] "
                  size="xs"
                  data={[]}
                  label="Date"
                  onClick={() => {
                    opened ? close() : open();
                  }}
                  placeholder={
                    selectedDateRange.startdate !== null &&
                    selectedDateRange.enddate !== null
                      ? selectedDateRange.startdate +
                        "  to  " +
                        selectedDateRange.enddate
                      : "All"
                  }
                />
                {selectedDateRange.startdate !== null &&
                  selectedDateRange.enddate !== null && (
                    <div
                      className=" absolute right-2 top-7 bg-[#fff] rounded-md cursor-pointer"
                      onClick={() =>
                        setSelectedDate({ startdate: null, enddate: null })
                      }
                    >
                      <CloseButton />
                    </div>
                  )}
              </div>
              {opened && (
                <div className="absolute z-20 right-0">
                  <CustomDatePicker
                    close={close}
                    selectedDate={selectedDateRange}
                    setSelectedDate={setSelectedDate}
                  />
                </div>
              )}
            </div>
          </div>
      

      {/* <div className="bg-white flex flex-col w-[12vw] cursor-pointer overflow-y-auto ml-3 border-[1px] border-slate-300 -mt-[8%]">
        <div className=" bg-white pl-2 text-xs py-2 sticky z-10 top-0 text-center font-semibold border-b-2">
          Select Branch
        </div>
        <div
          className={`${
            selectedLevelBranch === "Company" ? "border-b-2 p-[0.1rem]" : ""
          } text-xs  px-2 font-medium ${
            selectedBranchName === "All" && selectedLevelBranch === "Company"
          className={`${
            selectedLevelBranch === "Company" ? "border-b-2 p-[0.1rem]" : ""
          } text-xs  px-2 font-medium ${
            selectedBranchName === "All" && selectedLevelBranch === "Company"
              ? "bg-[#01b8aa] text-white"
              : "hover:bg-green-50"
          }`}
          onClick={() => {
            setSelectedBranchName("All");
            setSelectedClientName("All");
          }}
        >
          {selectedLevelBranch === "Company" ? "All" : ""}
          {selectedLevelBranch === "Company" ? "All" : ""}
        </div>

        {branchesList &&
          branchesList?.sort().map((branch: any) => (
            <div
              className={`border-b-2 text-xs p-[0.1rem] px-2 font-medium ${
              className={`border-b-2 text-xs p-[0.1rem] px-2 font-medium ${
                selectedBranchName === branch
                  ? "bg-[#01b8aa] text-white"
                  : "hover:bg-green-50"
              }`}
              onClick={() => {
                setSelectedBranchName(branch);
                setSelectedClientName("All");
              }}
            >
              {branch}
            </div>
          ))}
      </div> */}
      <div className="flex flex-1 flex-row gap-8 justify-around items-start mt-2 overflow-x-auto h-fit  p-2">
      {/* <div className="flex flex-1 lg:flex-row flex-col gap-8 justify-center lg:justify-around items-center lg:items-start mt-2 overflow-auto h-fit  p-2"> */}
        <div className="flex flex-col justify-center items-center  rounded-sm ">
          <div className="font-semibold p-1 2xl:p-2 2xl:px-10 px-5 bg-[#d3e9f7] -ml-10  mb-2 2xl:mb-4  rounded-md shadow-sm text-xs">
            Sales Process
          </div>
          <FunnelChart funnelChartData={funnelChartData} />
          {showInfo && (
            <div className="absolute top-10 left-0 p-2 2xl:p-4 text-[9px] 2xl:text-xs w-[240px] 2xl:w-[340px] h-fit bg-[#eaf6fc] rounded-md shadow-sm ">
              <div>Funnel chart depicts the following: </div>
              <div>
                Each sales process step is compared to Sales Cold Call i.e. It
                currently shows what percent of clients from Sales Cold Call
                have reached to that step. Comments are considered only after
                the date ‘2023-06-07’.
              </div>
              <div className="bg-[#eaf6fc] w-5 h-5 absolute right-10 -bottom-2 rotate-45 "></div>
            </div>
          )}
        </div>
        <div className="flex flex-col ">
          <div className="flex flex-col items-center justify-start ">
            <div className="font-semibold p-1 2xl:p-2 2xl:px-10 px-5 bg-[#d3e9f7]  2xl:mb-4 mb-2  rounded-md shadow-sm text-xs">
              {barchartTitle}
            </div>

            <div
              className="bg-white border-[1px]  p-4 rounded-md shadow-xl  h-[350px] w-[480px] 2xl:h-[550px] 2xl:w-[680px] "
              id="chart"
            >
              <BarChart barchartData={barchartData} />
            </div>
            {showInfo && (
              <div className="absolute right-[34%] top-20 2xl:top-14 p-2 2xl:p-4 text-[9px] 2xl:text-xs w-[220px] h-fit bg-[#eaf6fc] rounded-md shadow-sm z-100">
              {/* <div className="absolute -left-20 -top-[40px] p-4 text-xs w-[220px] h-fit bg-[#eaf6fc] rounded-md shadow-sm z-100"> */}
                Total comment count shown based on the slicer selection for each
                sales process
                <div className="bg-[#eaf6fc] w-5 h-5 absolute right-10 -bottom-2 rotate-45 "></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesActivityCharts;
