/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import { FunnelChart, Funnel, Tooltip, LabelList, ResponsiveContainer } from "recharts";
import LabelIcon from "../../../assets/images/labelicon.png"
import { useEffect, useState } from "react";

const FunnelChartComponent = (props: any) => {
  const { funnelChartData } = props;

  const demoData = [
    { name: "Sales Cold Call", value: 100, fill: "#c7e0ff" },
    { name: "Sales Prospect", value: 85.7, fill: "#fcf6bb" },
    { name: "Sales Appointment", value: 71.4, fill: "#bbf7d0" },
    { name: "Sales Tour", value: 57.1, fill: "#ffb8cd" },
    { name: "Sales Risk", value: 42.8, fill: "#e9d5ff" },
    { name: "Sales Proposal", value: 28.5, fill: "#fce3c5" },
    { name: "Sales Won", value: 14.2, fill: "#b8fff1" },
    // { name: "Sales Credit", value: 25, fill: "#b8fff1" },
    // { name: "Sales Client", value: 12.5, fill: "#a8b8ff" },
    // { name: "Sales Presentation", value: 70, fill: "#fce7f3" },
    // { name: "Sales Lost", value: 10, fill: "#ffd8b8" },
  ];

  const chartData =
    funnelChartData && funnelChartData.length > 0
      ? Object.entries(funnelChartData[0])
          ?.sort((a: any, b: any) => b[1] - a[1])
          ?.map(([key, value]: any) => {
            return {
              name: key,
              value: value ?? value,
            };
          })
      : [];
     

  return (
    <div  className="relative mr-12 h-[360px] w-[450px] 2xl:h-[560px] 2xl:w-[600px]  ">
      <ResponsiveContainer >
      <FunnelChart >
        <Funnel
          dataKey="value"
          data={demoData}
          nameKey="name"
          stroke="#ddd"
          strokeWidth={1}
        >
          <LabelList
          className="text-[10px] 2xl:text-xs"
            dataKey={(entry: any) =>
              ` ${chartData
                .filter((data: any) => data.name === entry.name)
                .map((d: any) => d.name)}`
            }
            position="center"
            fill="#000000"
            stroke="#000"
            strokeWidth={0.1}
          />
        </Funnel>
        {/* <Tooltip
          formatter={(value, name) => [
            `${name}: ${chartData
              .filter((data: any) => data.name === name)
              .map((d: any) => d.value?.toFixed(2))}%`,
          ]}
        /> */}
      </FunnelChart>
      </ResponsiveContainer>
      {demoData.slice(1).map((data:any, index) => (
        <div
          key={`label-${index}`}
          style={{
            position: "absolute",
            top: `calc(${((index + 1) * 96) / demoData.length}% - 20px)`,
            right: `calc(${((index + 1) * 42) / demoData.length}% )`,
            textAlign: "center",
            transform: "translate(45%)",
            width: "120px",
            fontSize: "12px",
            color: "#000",
          }}
        >
         <div className="flex items-center">
         <img src={LabelIcon} className="h-10 2xl:h-14"/>
          <div className="border-[1px] shadow-md rounded-full px-2 2xl:px-4 h-6 2xl:h-8 text-center flex justify-center items-center">{chartData.find((d:any)=>d.name===data.name)?.value.toFixed(2)}%</div>
         </div>
        </div>
      ))}
    </div>
  );
};

export default FunnelChartComponent;
