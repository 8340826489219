/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useSelector } from "react-redux";

export const TableHeaders = () => {
  const firstHeaders = useSelector(
    (state: any) => state["filterReducer"].firstHeaders
  );
  const secondHeaders = useSelector(
    (state: any) => state["filterReducer"].secondHeaders
  );
  const thirdHeaders = useSelector(
    (state: any) => state["filterReducer"].thirdHeaders
  );
  const type = useSelector(
    (state: any) => state["filterReducer"].selectedAreaType
  );
  return (
    <header className={"table-headers table-row "}>
      <div className="data-area header-section">
        <div className="table-col title text-center">Weekly Sales</div>
        {firstHeaders.map((v: string, key: number) => (
          <div key={key} className={"table-col text-center"}>
            {v}
          </div>
        ))}
      </div>
      <div className="projection-area header-section bg-white">
        {secondHeaders.map((v: string, key: number) => (
          <div key={key} className={"table-col text-center"}>
            {v}
          </div>
        ))}
      </div>
      <div className="rate-area header-section">
        {thirdHeaders.map((v: string, key: number) => (
          <div key={key} className={"table-col text-center"}>
            {v}
          </div>
        ))}
      </div>
      {type !== "All" && (
        <div className="manager-area header-section">
          <div className={"table-col text-center"}>Manager</div>
        </div>
      )}
    </header>
  );
};
