/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchRegions = () => {
  return GetRequest(`/sales-manager/regions`);
};

export const APIFetchBranch = (selectedBranch: any) => {
  return GetRequest(`/sales-manager/branch`, {
    params: { selectedBranch },
  });
};
export const APIFetchGPTableData = () => {
  return GetRequest(`/sales-manager/gp`);
};
export const APIFetchGP13WeeksCompany = () => {
  return GetRequest(`/sales-manager/company/gp13weeks`);
};
export const APIFetchGP13Weeks = (selectedBranch: any) => {
  return GetRequest(`/sales-manager/gp13weeks`, {
    params: { selectedBranch },
  });
};

export const APIFetchSalesTable = (
  startDate: any,
  endDate: any,
  minStep: number,
  maxStep: number,
  branch: any,
  level: any
) => {
  return GetRequest(`/sales-manager/sales`, {
    params: { startDate, endDate, minStep, maxStep, branch, level },
  });
};

export const APIFetchClientsData = (year: any, level: any) => {
  return GetRequest(`/sales-manager/clients`, { params: { year, level } });
};
