/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { ActionIcon } from "@mantine/core";
import { Loader } from "../components/common/Loader";
import { useEffect, useState } from "react";
import SalesManagerSelector from "../components/modules/salesManager/SalesManagerSelector";
import SalesManagerTables from "../components/modules/salesManager/SalesManagerTables";
import { APIFetchBranch, APIFetchGPTableData } from "../api/sales-manager";
import { setLoading } from "../store/Filter/actions";
import { useDispatch } from "react-redux";

const SalesManager = () => {
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [districtList, setDistrictList] = useState([] as any);
  const [branchesList, setBranchesList] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("All");
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState("Company");
  const [selectedLevelBranch, setSelectedLevelBranch] =
    useState<string>("Company");
  const [subAreaSelected, setSubAreaSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [districtSelected, setDistrictSelected] = useState(null);
  const [gpData, setGpData] = useState([]);
  const [filteredGpData, setFilteredGpData] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState("2024");
  const dispatch = useDispatch();

  const getFilteredData = (data: any) => {
    return data.filter((d: any) => {
      if (d.CalendarYear !== parseInt(selectedYear)) return false;

      if (districtSelected) {
        return d.District === districtSelected;
      } else if (regionSelected) {
        return d.Region === regionSelected;
      } else if (subAreaSelected) {
        return d["Sub area name"] === subAreaSelected;
      } else if (areaSelected) {
        return d.AreaName === areaSelected;
      }
      return true;
    });
  };

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));
      await APIFetchGPTableData().then((res: any) => {
        setGpData(res.data);
        setFilteredGpData(getFilteredData(res.data));
      });
      dispatch(setLoading(false));

      document.title = "Sales Manager Dashboard";
    })();
  }, []);

  useEffect(() => {
    setFilteredGpData(getFilteredData(gpData));
  }, [
    gpData,
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    selectedYear,
  ]);

  useEffect(() => {
    APIFetchBranch(
      districtSelected
        ? districtSelected
        : regionSelected
        ? regionSelected
        : subAreaSelected
        ? subAreaSelected
        : areaSelected
        ? areaSelected
        : "All"
    ).then((res: any) => {
      setBranchesList(res.data.map((d: any) => d.branch));
    });
  }, [areaSelected, subAreaSelected, regionSelected, districtSelected]);

  return (
    <main className="h-[100vh] w-[100vw] ">
      <section className="w-[100%] h-[100%] flex">
        <div className="h-full flex-grow">
          <div className="flex flex-col gap-2">
            <div className="absolute top-2 left-0 flex items-center">
              <ActionIcon>
                <i className="material-icons" style={{ fontSize: "32px" }}>
                  chevron_left
                </i>
              </ActionIcon>
              <ActionIcon>
                <a
                  href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="text-slate-600 material-icons text-[28px]">
                    home
                  </i>
                </a>{" "}
              </ActionIcon>
            </div>
            <div className="flex flex-col h-screen w-screen bg-[#e6f1f1] overflow-x-hidden">
              <div className="py-2 bg-[#cddfdb] w-screen  text-blue-900 text-xl font-bold text-start pl-20">
                Sales Manager Dashboard
              </div>
              <div className="relative">
                <SalesManagerSelector
                  setLevel={setLevel}
                  hierarchy={hierarchy}
                  setHierarchy={setHierarchy}
                  areaList={areaList}
                  setAreaList={setAreaList}
                  subAreaList={subAreaList}
                  setSubAreaList={setSubAreaList}
                  regionsList={regionsList}
                  setRegionsList={setRegionsList}
                  districtList={districtList}
                  setDistrictList={setDistrictList}
                  selectedLevelBranch={selectedLevelBranch}
                  setSelectedLevelBranch={setSelectedLevelBranch}
                  areaSelected={areaSelected}
                  setAreaSelected={setAreaSelected}
                  subAreaSelected={subAreaSelected}
                  setSubAreaSelected={setSubAreaSelected}
                  regionSelected={regionSelected}
                  setRegionSelected={setRegionSelected}
                  setDistrictSelected={setDistrictSelected}
                  setSelectedBranch={setSelectedBranch}
                />
                {/* <div className="border-[1px] text-[0.6rem] absolute bottom-0 right-0 mr-20 p-2 border-stone-400 flex flex-col gap-1">
                  <div className="font-semibold">
                    Gross Profit Hightlight Logic:
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-3 h-3 rounded-full bg-[#81d07e]"></div>
                    Not Billed Previously but Billed this Month
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="w-3 h-3 rounded-full bg-[#a0d1ff]"></div>
                    Billed Previously
                  </div>
                </div> */}
              </div>
              <SalesManagerTables
                gpData={gpData}
                filteredGpData={filteredGpData}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                areaSelected={areaSelected}
                subAreaSelected={subAreaSelected}
                regionSelected={regionSelected}
                districtSelected={districtSelected}
                branchesList={branchesList}
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
              />
            </div>
          </div>
        </div>
      </section>
      <Loader />
    </main>
  );
};

export default SalesManager;
