/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useRef, useState, useEffect } from "react";
import { Select } from "@mantine/core";
import { APIFetchWebCalendarDates } from "../api/web-calendar";

export const DemoCalendar = () => {
  const [calendarDates, setCalendarDates] = useState<any>({});
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null as any);
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear()
  );
  const calendarRef: any = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

  const setToday = () => {
    setSelectedYear(new Date().getFullYear());
    setSelectedMonth(new Date().getMonth());
  };

  useEffect(() => {
    APIFetchWebCalendarDates().then((res) => {
      setCalendarDates(res.data);
    });
  }, []);

  useEffect(() => {
    if (calendarRef) {
      const api = calendarRef?.current?.getApi();
      api?.gotoDate(new Date(selectedYear, selectedMonth));
    }
  }, [calendarRef, selectedMonth]);

  const monthsList = [];

  for (let i = 0; i < 12; i++) {
    monthsList.push(
      <div
        key={i}
        className="cursor-pointer"
        onClick={() => {
          setShowFullCalendar(true);
          setSelectedMonth(i);
        }}
      >
        <Calendar
          month={i}
          year={selectedYear}
          events={calendarDates[selectedCategory]}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-center px-10 py-2 gap-10">
        {showFullCalendar ? (
          <>
            <div className="w-[75vw] h-[80vh]">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={calendarDates[selectedCategory] ?? {}}
                fixedWeekCount={false}
                dayHeaderFormat={{ weekday: "long" }}
                aspectRatio={1}
                height="100%"
                customButtons={{
                  yearViewButton: {
                    text: "Year View",
                    click: function () {
                      setShowFullCalendar(false);
                      setSelectedMonth(null);
                    },
                  },
                  thisMonthButton: {
                    text: "This Month",
                    click: setToday,
                  },
                }}
                headerToolbar={{
                  start: "yearViewButton", // will normally be on the right. if RTL, will be on the left
                  center: "title",
                  end: "prev,thisMonthButton,next", // will normally be on the left. if RTL, will be on the right
                }}
              />
            </div>
            <div>
              <CategoriesTable
                events={calendarDates[selectedCategory]}
                category={selectedCategory}
              />
            </div>
          </>
        ) : (
          <div className="flex gap-10">
            <div className="flex flex-col gap-5 w-[75vw] h-full">
              <div className="flex">
                <button
                  className="py-1 px-4 border border-light bg-calendar text-white rounded"
                  onClick={() => setSelectedYear(new Date().getFullYear())}
                >
                  This year
                </button>
                <button
                  className="p-1 border border-light font-bold text-xl bg-calendar text-white rounded-l"
                  onClick={() => setSelectedYear(selectedYear - 1)}
                >
                  <span className="fc-icon fc-icon-chevron-left"></span>
                </button>
                <span className="px-8 py-1 border-y border-light text-xl">
                  {selectedYear}
                </span>
                <button
                  className="p-1 border border-light font-bold text-xl bg-calendar text-white rounded-r"
                  onClick={() => setSelectedYear(selectedYear + 1)}
                >
                  <span className="fc-icon fc-icon-chevron-right"></span>
                </button>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-wrap gap-20">{monthsList}</div>
              </div>
            </div>
            <div>
              <Select
                label="Select Category"
                placeholder="Category"
                data={Object.keys(calendarDates)}
                value={selectedCategory}
                onChange={setSelectedCategory}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Calendar = ({ month, year, events }: any) => {
  const daysOfWeek = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthData = (year: number, month: number) => {
    const daysInMonth = getDaysInMonth(year, month);
    const monthStartDay = new Date(year, month, 1).getDay();
    const monthEndDay = new Date(year, month, daysInMonth).getDay();
    const numWeeks = Math.ceil((daysInMonth + monthStartDay) / 7);

    let days = [];
    let day = 1;

    for (let i = 0; i < numWeeks; i++) {
      let week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < monthStartDay) || day > daysInMonth) {
          week.push(null);
        } else {
          week.push(day);
          day++;
        }
      }
      days.push(week);
    }

    return { days, monthStartDay, monthEndDay };
  };

  const date = new Date(year, month);
  const monthName = date.toLocaleString("default", { month: "long" });
  const monthData = getMonthData(year, month);

  const isEventDay = (day: number) => {
    if (day === 0) {
      return false;
    }
    const currentDate = new Date(year, month, day);
    return events?.find((event: any) => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      return currentDate >= eventStart && currentDate <= eventEnd;
    });
  };

  return (
    <div>
      <h2 className="text-center bg-calendar text-white text-bold p-1">
        {monthName}
      </h2>
      <table>
        <thead className="bg-secondary text-white">
          <tr className="p-2">
            {daysOfWeek.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {monthData.days.map((week, i) => {
            return (
              <tr key={i}>
                {week.map((day, j) => {
                  const event = isEventDay(day ?? 0);
                  const bgColor = event?.backgroundColor;
                  return (
                    <td
                      key={j}
                      style={{
                        backgroundColor: bgColor
                          ? bgColor
                          : day
                          ? ""
                          : "#d1d5db",
                        color: bgColor ? "#fff" : "",
                      }}
                      className={`px-2 py-1 text-center border border-secondary text-bold ${
                        j === 0 || j === 6 ? "text-calendar" : "text-slate-800"
                      }`}
                    >
                      {day}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const CategoriesTable = ({ events, category }: any) => {
  return (
    <table className="w-[450px]">
      <tr>
        <th
          className="border border-slate-500 text-sm p-1 bg-calendar text-white"
          colSpan={2}
        >
          {category} Dates
        </th>
      </tr>
      <tr>
        <th className="border border-slate-500 w-4 text-sm p-1">Event</th>
        <th className="border border-slate-500 w-20 text-sm p-1">Date</th>
      </tr>
      {events?.map((event: any) => {
        return (
          <tr>
            <td className="border border-slate-500 text-sm p-1">
              {event.title}
            </td>
            <td className="border border-slate-500 text-sm p-1">
              {event.start + (event.end ? " to " + event.end : "")}
            </td>
          </tr>
        );
      })}
    </table>
  );
};
