/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import "../assets/scss/ArAging.scss";
import { useEffect, useState } from "react";
import {
  loadArAgingData,
  loadArAgingDueDateData,
  loadCorporations,
} from "../store/ArAging/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setLoading } from "../store/Filter/actions";
import { ActionIcon } from "@mantine/core";
import ArClerkAssignment from "../components/modules/arAging/ArClerkAssignment";
import { getSubsTime } from "../utils/helper/dateTime";
import { setSortBy } from "../store/ArAging/actions";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ArAgingCLerkPage from "../components/modules/arAging/ArAgingCLerkPage";
import HomeButton from "../components/common/HomeButton";

export const ArAgingDashboard = ({ wip, adaOnly, isAscendAdaAccess }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedCorporation, setSelectedCorporation] = useState("All");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [dateType, setDateType] = useState("Invoice Date");
  const [multiSelect, setMultiSelect] = useState(["1-30 Days"] as string[]);
  const [selectFilter, setSelectFilter] = useState("AR Aging");
  const commentDialog = useSelector(
    (state: any) => state.arAgingReducer.commentDialog
  );
  const exportDialog = useSelector(
    (state: any) => state.arAgingReducer.exportDialog
  );
  // const [counter,setCounter] = useState(0);
  const isArView: boolean = params["*"]?.includes("ar-view") || false;

  const [arClerksView, setArClerksView] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(
    adaOnly ? "ADA" : "Company"
  );

  const loadAllData = async () => {
    dispatch(setLoading(true));
    await loadArCorporations();
    await loadData();
    dispatch(setLoading(false));
  };
  useEffect(() => {
    document.title = "AR Aging Report";
  });

  useEffect(() => {
    const timeout = setTimeout(loadAllData, getSubsTime());
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    loadAllData();
  }, [selectedLevel]);

  useEffect(() => {
    if ([selectedCorporation, date, dateType].every(Boolean)) {
      loadData().then((_) => {});
    }
  }, [selectedCorporation, date, dateType]);

  const loadArCorporations = async () => {
    dispatch(setLoading(true));
    await dispatch(loadCorporations(selectedLevel));
    dispatch(setLoading(false));
  };

  const loadData = async () => {
    dispatch(setLoading(true));
    if (dateType.trim() === "Invoice Date") {
      await dispatch(loadArAgingData(date, selectedCorporation, selectedLevel));
    } else {
      await dispatch(
        loadArAgingDueDateData(date, selectedCorporation, selectedLevel)
      );
    }
    dispatch(setLoading(false));
  };

  const resetFilters = () => {
    setSelectedCorporation("All");
    setDate(moment(new Date()).format("YYYY-MM-DD"));
    setDateType("Invoice Date");
    setMultiSelect(["1-30 Days"] as string[]);
    setSelectFilter("AR Aging");
    dispatch(
      setSortBy({
        type: "Client Name",
        isReverse: false,
      })
    );
  };

  return (
    <main
      className={"dashboard screen ar-aging-report flex flex-col h-[100vh]"}
    >
      <div className="ar-bg-primary flex justify-between mb-1">
        <div className="w-1/3 flex items-center justify-start px-5">
          {isArView ? (
            <ActionIcon
              radius={"xl"}
              variant="light"
              // className="p-0 bg-black bg-opacity-20"
              onClick={() => {
                setArClerksView(false);
                navigate(-1);
              }}
            >
              <i className="material-icons text-3xl text-sky-800">
                chevron_left
              </i>
            </ActionIcon>
          ) : (
            <HomeButton adaOnly={adaOnly} resetFilters={resetFilters} />
          )}
        </div>
        <div className="ar-aging-header w-1/3 text-[1rem] md:text-[1.4rem]">
          {isArView ? "AR Clerk Assignment" : "AR Aging"}
        </div>
        <div className="w-1/3 flex items-center justify-end px-5">
          {!isArView && selectedLevel !== "ADA" && (
            <Link to="ar-view">
              <button
                className="bg-black bg-opacity-20 text-white px-5 py-2 md:py-3  rounded-md font-semibold"
                onClick={() => setArClerksView(true)}
              >
                AR View
              </button>
            </Link>
          )}
        </div>
      </div>
      {/* {!isArView && (
        <div className=" w-full flex justify-center items-center gap-4">
          {companyLevels.map((v: any) => {
            return (
              <div
                className={`border-[1px] rounded-lg p-2 px-4 text-xs ${
                  isAscendAdaAccess ? "cursor-pointer" : "w-[220px] "
                } text-center  shadow-md mb-2 ${
                  selectedLevel === v.level
                    ? "bg-[#4187D5] text-white"
                    : "hover:bg-[#ebf0f2]"
                }`}
                onClick={() => {
                  setSelectedLevel(v.level);
                  setSelectedCorporation("All");
                }}
              >
                {v.name}
              </div>
            );
          })}
        </div>
      )} */}
      <Routes>
        <Route
          element={
            <ArAgingCLerkPage
              level={selectedLevel}
              setDate={setDate}
              date={date}
              dateType={dateType}
              selectedCorporation={selectedCorporation}
              setSelectedCorporation={setSelectedCorporation}
              multiSelect={multiSelect}
              setMultiSelect={setMultiSelect}
              setDateType={setDateType}
              selectFilter={selectFilter}
              setSelectFilter={setSelectFilter}
              exportDialog={exportDialog}
              commentDialog={commentDialog}
            />
          }
          path={"/"}
        />
        <Route element={<ArClerkAssignment />} path={"ar-view"} />
      </Routes>
    </main>
  );
};
