import { GetRequest, PutRequest } from "../plugins/https";

export const APIGoalSettingData = () => {
  return GetRequest(`goal-setting/table-data`);
};

export const APIUpdateTableRow = (editedRowData: {
  branch: string;
  categories: string;
  items: string;
  changes: {};
}) => {
  return PutRequest(`goal-setting/goal-value`, editedRowData);
};

export const APIBulkUpdate = (calculatedData: any) => {
  return PutRequest(`goal-setting/bulk-goal-value`, calculatedData);
};

export const APIClearAll = (branch: string) => {
  return PutRequest(`goal-setting/clear-all`, { branch: branch });
};
