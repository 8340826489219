/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from "../plugins/https";

export const APIGetPipSummaryByBranches = () => {
  return GetRequest("/pip-summary/branches");
};

export const APIGetPipMilestonesByBranches = () => {
  return GetRequest("/pip-summary/milestones");
};

export const APIAddNotes = (data: any) => {
  return PostRequest("pip-summary/notes", data);
};

export const APIUpdateNotes = (id: any, data: any) => {
  return PutRequest("pip-summary/notes/" + id, data);
};

export const APIFetchNotesByLocation = (branch: any) => {
  return GetRequest("pip-summary/notes/" + branch);
};

export const APIDeleteNote = (id: any) => {
  return DeleteRequest("pip-summary/notes/" + id);
};

export const APIGetPipSummaryByRegion = (regions: any) => {
  return GetRequest("/pip-summary/locations/branches", {
    params: { regions },
  });
};

export const APIGetPipMilestonesByRegion = (regions: any) => {
  return GetRequest("/pip-summary/locations/milestones", {
    params: { regions },
  });
};
