/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import * as actions from "./actionTypes";

export const initialState = {
  branch: "",
};

interface IAction {
  type: String;
  payload: any;
}

export const dailyNumberReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actions.SET_BRANCH:
      return {
        ...state,
        branch: action.payload,
      };
    default:
      return state;
  }
};
