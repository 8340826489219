/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const Forbidden = () => {
  return (
    <main>
      <div className="h-[100vh] w-full flex flex-col items-center justify-center">
        <div className="text-[5rem] font-medium">Forbidden</div>
        <h1>You do not have enough permission to access this page</h1>
      </div>
    </main>
  );
};
