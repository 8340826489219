/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { groupByKey } from "./group";

const columnHeaderes = ["YTD-Incr", "%-GOAL", "4Wk-LstYr", "4Wk-LstPr4"];
const rowHeaders = ["Hours", "Revenue", "GP", "%"];

export const convertToTableData = (grouped: any, key: string) => {
  let data: any = {};
  const primaryMeasures: any = Object.values(grouped)[0];
  const primaryMeasuresSet: any = Array.from(
    new Set(primaryMeasures?.map((v: any) => v.PrimaryMeasures))
  );

  Object.keys(grouped).forEach((v) => {
    const val = groupByKey(grouped[v], "PrimaryMeasures");
    const locationData = primaryMeasuresSet.map((v: string) => {
      let arrData = { title: v };
      val[v]?.forEach((dat: any) => {
        arrData = {
          ...arrData,
          [dat[key]]: dat.Value || data.Value === "" ? dat.Value : "",
        };
      });
      return arrData;
    });
    data = { ...data, [v]: locationData };
  });
  return data;
};

export const convertToTableDataManagers = (grouped: any) => {
  let data: any = {};

  Object.keys(grouped).forEach((v) => {
    const val = groupByKey(grouped[v], "PrimaryMeasures");
    const manager = val["Revenue"][0].Manager;
    const time = val["GP"][0].Manager;
    const managerData: any = {
      Hours: "",
      Revenue: "",
      GP: time,
      "%": manager,
    };
    const locationData = rowHeaders.map((v) => {
      let arrData: any = { title: v };
      val[v]?.forEach((dat: any) => {
        arrData = { ...arrData, Manager: managerData[v] ? managerData[v] : "" };
      });
      return arrData;
    });
    data = { ...data, [v]: locationData };
  });
  return data;
};
