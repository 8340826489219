/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { groupByKey } from "../../../utils/group";
import ReactApexChart from "react-apexcharts";
import { dottedLine, labelFormatter } from "../../../utils/helper/charts";
import { formatAmount } from "../../../utils/helper/cellFormatter";
import {
  getMonthData,
  getQuarterData,
  getWeekData,
} from "../companySummary/utils/weekdata";

const TopClientsChart = ({ dataMode, chartData, selectedTimeline }: any) => {
  const [weekLabels, setWeekLabels] = useState<Number[]>([]);
  const dataFunction: any = {
    Weekly: getWeekData,
    Monthly: getMonthData,
    Quarterly: getQuarterData,
  };

  useEffect(() => {
    for (let i = 1; i <= 52; i++) {
      weekLabels.push(i);
    }
    setWeekLabels([...weekLabels]);
  }, []);

  const chartColors: any = {
    GrossProfit: [
      {
        label: "Prior Year Gross Profit",
        color: "#1875B5",
      },
      {
        label: "Last Year Gross Profit",
        color: "#FF7F0E",
      },
      {
        label: "This Year Gross Profit",
        color: "#29A229",
      },
    ],
    Revenue: [
      {
        label: "Prior Year Revenue",
        color: "#1875B5",
      },
      { label: "Last Year Revenue", color: "#FF7F0E" },
      { label: "This Year Revenue", color: "#29A229" },
    ],
  };
  const chartValuesTopClients: any = {
    GrossProfit: {
      [`${labelFormatter("Prior Year Gross Profit")}`]: [],
      [`${labelFormatter("Last Year Gross Profit")}`]: [],
      [`${labelFormatter("This Year Gross Profit")}`]: [],
    },
    Revenue: {
      [`${labelFormatter("Prior Year Revenue")}`]: [],
      [`${labelFormatter("Last Year Revenue")}`]: [],
      [`${labelFormatter("This Year Revenue")}`]: [],
    },
  };

  const [colors, setColors] = useState<any>(chartColors[dataMode]);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState<any>({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {},
    legend: {
      show: false,
    },
    markers: {
      size: [1, 1, 1, 0],
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });

  useEffect(() => {
    const data = groupByKey(chartData, "CalendarYear");
    if (data && Object.keys(data).length > 0) {
      setColors(chartColors[dataMode]);
      setChartData(data);
    }
  }, [chartData, dataMode]);

  const setChartData = (data: any) => {
    const arrByMode = chartValuesTopClients[dataMode];
    const chartSeries: any = [
      ...chartColors[dataMode].map(({ label }: any, key: any) => {
        return {
          name: label,
          data: [
            ...dataFunction[selectedTimeline](
              Object.values({ ...arrByMode, ...data })[key],
              dataMode
            ),
          ],
        };
      }),
    ];
    setOptions({
      ...options,
      stroke: {
        width: [1, 1, 1],
        dashArray: [0, 0, 0],
        curve: "straight",
        colors: colors.map((v: any) => v.color),
      },
      markers: {
        size: 2,
      },
      colors: colors.map((v: any) => v.color),
      xaxis: {
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        categories: weekLabels,
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "10px",
          },
          formatter: (v: any) => formatAmount(v),
        },
        tickAmount: 5,
      },
      // dataLabels: {
      //   enabled: true,
      //   textAnchor: "end",
      //   offsetX: 0,
      //   offsetY: -5,
      //   style: {
      //     fontSize: "7px",
      //     fontFamily: "Helvetica, Arial, sans-serif",
      //     fontWeight: "bold",
      //     colors: undefined,
      //   },
      // },
    });
    setSeries(chartSeries);
  };

  return (
    <div className="chart-area w-full bg-white">
      <div className="chart-legend-area">
        <div className="colors-area">
          {colors.map((v: any, key: any) => (
            <div className="color-box" key={key}>
              <div
                className={
                  dottedLine(v.label)
                    ? `w-4 border-dotted border-y-2 border-x-0 border-[${v.color}]`
                    : "color-circle"
                }
                style={{ backgroundColor: `${v.color}` }}
              ></div>
              <div className={"color-name"}>{v.label}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={"h-full flex-grow bg-white"}>
        <ReactApexChart
          type="line"
          options={options}
          series={series}
          height={150}
        />
      </div>
    </div>
  );
};

export default TopClientsChart;
