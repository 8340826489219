/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import {
  APIFetchEmployeeRedeployment,
  APIFetchEndReasons,
} from "../api/employee-redeployment";
import { CustomDatePicker } from "../components/common/CustomDatePicker";
import { ActionIcon, Select, Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/common/Loader";
import { setLoading } from "../store/Filter/actions";
import EmployeeRedeploymentSelector from "../components/modules/employeeDeployment/EmployeeRedeploymentSelector";
import {
  sortByKey,
  sortByKeyDailyNumbers,
} from "../utils/helper/cellFormatter";
import { groupByKey } from "../utils/group";
import RedeploymentTable from "../components/modules/employeeDeployment/RedeploymentTable";

export const EmployeeRedployment = () => {
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [districtsList, setDistrictsList] = useState([] as any);
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState("Company");
  const [selectedLevelBranch, setSelectedLevelBranch] =
    useState<string>("Company");
  const [subAreaSelected, setSubAreaSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [districtSelected, setDistrictSelected] = useState(null);
  const [branchesList, setBranchesList] = useState([] as any);
  const dispatch = useDispatch();
  const [branches, setBranches] = useState<any>(["All"]);
  const [clients, setClients] = useState<any>(["All"]);
  const [allData, setAllData] = useState([]);
  const [endReasonData, setEndReasonData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState<any>({});
  const [opened, { open, close }] = useDisclosure();
  const [selectedDateRange, setSelectedDate] = useState({
    startdate: moment(new Date()).add("-29", "days").format("yyyy-MM-DD"),
    enddate: moment(new Date()).format("yyyy-MM-DD"),
  });
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedClient, setSelectedClient] = useState("All");
  const loading = useSelector((state: any) => state.filterReducer.loading);
  const [sortedData, setSortedData] = useState<any>(tableData);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>({
    key: "Redeployed %",
    direction: "desc",
  });
  const [endReasonsList, setEndReasonsList] = useState<string[]>([]);
  const [selectedEndReason, setSelectedEndReason] = useState<string>("All");

  const fetchEndReasons = async () => {
    const res = await APIFetchEndReasons();
    const endReasons = res.data.map((data: any) => data.EndReasons).sort();
    setEndReasonsList(["All", ...endReasons]);
  };

  useEffect(() => {
    document.title = "Employee Redeployment";
    fetchEndReasons();
  }, []);

  const getIcon = (label: string) => {
    return sortConfig?.key !== label
      ? "unfold_more"
      : sortConfig?.direction === "asc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  };

  const headers = [
    { label: "Branch", value: "Branch" },
    {
      label: "# of Employees that came off Assignment",
      value: "EmployeesOffAssignment",
    },
    {
      label: "# of Employees that were Re-Assigned",
      value: "EmployeesReassigned",
    },
    { label: "Redeployed %", value: "Redeployed%" },
  ];

  const requestSort = (key: any, direction: any, data: any) => {
    if (direction === "asc") {
      const sorted = sortByKeyDailyNumbers(data, key, false);
      setSortedData(sorted);
    } else {
      const sorted = sortByKeyDailyNumbers(data, key, true);
      setSortedData(sorted);
    }
  };

  useEffect(() => {
    requestSort(sortConfig?.key, sortConfig?.direction, tableData);
  }, [sortConfig, tableData]);

  useEffect(() => {
    dispatch(setLoading(true));
    APIFetchEmployeeRedeployment(
      selectedDateRange.startdate,
      selectedDateRange.enddate,
      selectedBranch,
      selectedClient,
      selectedLevelBranch === "Company" ? "All" : selectedLevelBranch
    ).then(({ data }) => {
      setClients(["All", ...data.clients]);
      setBranches(["All", ...data.branches]);
      setAllData(data.data);
      setEndReasonData(data.dataWithEndReasons);
      dispatch(setLoading(false));
    });
  }, [selectedDateRange, selectedBranch, selectedClient, selectedLevelBranch]);

  useEffect(() => {
    if (selectedEndReason === "All") {
      const total = allData.reduce(
        (acc: any = {}, curr: any) => {
          acc.AssignedTotal += curr["# of Employees that came off Assignment"];
          acc.ReassignedTotal += curr["# of Employees that were Re-Assigned"];
          return { ...curr, ...acc };
        },
        { AssignedTotal: 0, ReassignedTotal: 0 }
      );
      setTableData(allData);
      setTotalData(total);
    } else {
      const filteredWithEndReasons =
        selectedEndReason !== "All"
          ? endReasonData.filter((d: any) => d.EndReason === selectedEndReason)
          : endReasonData;

      const branchWiseGroup = groupByKey(filteredWithEndReasons, "Branch");
      const eData: any = sortByKey(
        Object.values(branchWiseGroup).map((value: any) => {
          const accumulatedData = value.reduce(
            (acc: any, curr: any) => {
              acc["# of Employees that came off Assignment"] +=
                curr["# of Employees that came off Assignment"];
              acc["# of Employees that were Re-Assigned"] +=
                curr["# of Employees that were Re-Assigned"];
              return {
                Branch: curr.Branch,
                Region: curr.Region,
                "Sub Area Name": curr["Sub Area Name"],
                Areaname: curr["Areaname"],
                EndReason: curr.EndReason,
                ...acc,
              };
            },
            {
              "# of Employees that came off Assignment": 0,
              "# of Employees that were Re-Assigned": 0,
            }
          );

          accumulatedData["Redeployed %"] =
            accumulatedData["# of Employees that came off Assignment"] !== 0
              ? (accumulatedData["# of Employees that were Re-Assigned"] /
                  accumulatedData["# of Employees that came off Assignment"]) *
                100
              : 0;

          return {
            ...accumulatedData,
            data: value,
          };
        }),
        { type: "Branch", isReverse: false }
      );

      const total = eData.reduce(
        (acc: any = {}, curr: any) => {
          acc.AssignedTotal += curr["# of Employees that came off Assignment"];
          acc.ReassignedTotal += curr["# of Employees that were Re-Assigned"];
          return { ...curr, ...acc };
        },
        { AssignedTotal: 0, ReassignedTotal: 0 }
      );
      setTableData(eData);
      setTotalData(total);
    }
  }, [allData, endReasonData, selectedEndReason, selectedClient]);

  const maxEmployeesOffAssignment =
    tableData && tableData.length > 0
      ? Math.max(
          ...tableData.map((d) => d["# of Employees that came off Assignment"])
        )
      : 0;

  const maxEmployeesReAssigned =
    tableData && tableData.length > 0
      ? Math.max(
          ...tableData.map((d) => d["# of Employees that were Re-Assigned"])
        )
      : 0;

  const maxRedeployedPercentage =
    tableData && tableData.length > 0
      ? Math.max(...tableData.map((d) => d["Redeployed %"]))
      : 0;

  return (
    <section className="flex flex-col items-center w-[100vw] gap-6 h-[100vh] bg-client-light overflow-auto">
      {loading && <Loader />}
      <div className="absolute top-0 left-0 flex items-center">
        <ActionIcon>
          <a
            href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
            target={"_blank"}
            rel="noreferrer"
          >
            <i
              className="text-slate-600 material-icons"
              style={{ fontSize: "28px" }}
            >
              home
            </i>
          </a>{" "}
        </ActionIcon>
      </div>
      <header className="w-full flex flex-col items-center gap-2">
        <div className="text-center text-lg md:text-2xl p-1 font-semibold">
          Employee Redeployment
        </div>

        <EmployeeRedeploymentSelector
          selectedLevelBranch={selectedLevelBranch}
          setSelectedLevelBranch={setSelectedLevelBranch}
          hierarchy={hierarchy}
          setHierarchy={setHierarchy}
          areaList={areaList}
          setAreaList={setAreaList}
          subAreaList={subAreaList}
          setSubAreaList={setSubAreaList}
          regionsList={regionsList}
          setRegionsList={setRegionsList}
          districtList={districtsList}
          setDistrictList={setDistrictsList}
          branchesList={branchesList}
          setBranchesList={setBranchesList}
          areaSelected={areaSelected}
          setAreaSelected={setAreaSelected}
          subAreaSelected={subAreaSelected}
          setSubAreaSelected={setSubAreaSelected}
          regionSelected={regionSelected}
          setRegionSelected={setRegionSelected}
          districtSelected={districtSelected}
          setDistrictSelected={setDistrictSelected}
          setSelectedBranch={setSelectedBranch}
          setSelectedClientName={setSelectedClient}
          setLevel={setLevel}
          level={level}
        />

        <div className="filters flex flex-col sm:flex-row gap-0 sm:gap-5 sm:max-md:gap-2 px-1 lg:w-[64rem] w-[95%]">
          <div className="w-full sm:max-md:w-[16rem] md::w-[21rem]">
            <Select
              id="redeployment-date"
              size="xs"
              className="bg-client-light"
              data={[]}
              label="Date"
              onClick={() => {
                opened ? close() : open();
              }}
              placeholder={
                selectedDateRange.startdate +
                "  to  " +
                selectedDateRange.enddate
              }
            />
            {opened && (
              <div className="absolute z-30">
                <CustomDatePicker
                  close={close}
                  selectedDate={selectedDateRange}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            )}
          </div>
          <Select
            className="w-full sm:max-md:w-[16rem] md::w-[21rem] bg-client-light"
            data={branches}
            label="Branch"
            size="xs"
            value={selectedBranch}
            onChange={(value: any) => setSelectedBranch(value)}
          />
          <Select
            className="w-full sm:max-md:w-[16rem] md::w-[21rem] bg-client-light"
            data={clients}
            label="Client Name"
            size="xs"
            value={selectedClient}
            onChange={(value: any) => setSelectedClient(value)}
          />
        </div>
      </header>

      <main className="flex justify-center lg:gap-8 gap-2 overflow-auto h-[80dvh] w-full  ">
        <div className="lg:w-[10%] w-0 "></div>
        <div className="lg:w-[72%] w-[80%] h-full overflow-auto">
          <RedeploymentTable
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            headers={headers}
            getIcon={getIcon}
            sortedData={sortedData}
            maxEmployeesOffAssignment={maxEmployeesOffAssignment}
            maxEmployeesReAssigned={maxEmployeesReAssigned}
            maxRedeployedPercentage={maxRedeployedPercentage}
            tableData={tableData}
            totalData={totalData}
          />
        </div>
        <div className="lg:w-[10%] w-[20%] border-[1px] border-stone-500 bg-white  text-[0.7rem] h-full overflow-auto">
          <div className="font-bold border-b-2 p-1 text-center sticky top-0 bg-white">
            End Reasons
          </div>
          {endReasonsList &&
            endReasonsList.map((endReason: string) => {
              return (
                <div
                  className={`py-[0.07rem] px-2 border-b-[1px] cursor-pointer ${
                    selectedEndReason === endReason
                      ? "bg-tertiary text-white"
                      : "bg-white"
                  }`}
                  onClick={() => {
                    setSelectedEndReason(endReason);
                    setSelectedClient("All");
                  }}
                >
                  {endReason}
                </div>
              );
            })}
        </div>
      </main>
    </section>
  );
};
