/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { Component, useEffect, useState } from "react";
import { convertDaysIntoDuration, getWeekData } from "./utils/weekdata";
import {
  formatAmount,
  millionConverter,
} from "../../../utils/helper/cellFormatter";
import {
  dottedLine,
  formatPercChange,
  labelFormatter,
} from "../../../utils/helper/charts";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnableTooltip,
  setShowClientsRanking,
} from "../../../store/CompanySummary/actions";
import CompanySummaryNotes from "./CompanySummaryNotes";
import { getMaxRoundedValue, getMinRoundedValue } from "./utils/operations";
import PipSummaryNotes from "../pipSummary/PipSummaryNotes";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { groupByKey } from "../../../utils/group";

const SummaryChart = (props) => {
  const weeks = [];
  for (let i = 1; i <= 52; i++) {
    weeks.push(i);
  }
  // const weeksData = useSelector((state) => state.filterReducer.weekLabels);
  const Weeks = useSelector((state) => state.filterReducer.allWeekLabels);

  const [allWeeks, setAllWeeks] = useState([]);
  const [weekLabels, setWeekLabels] = useState([]);

  useEffect(() => {
    const allWeeksData = groupByKey(Weeks, "CalendarYear");
    setAllWeeks(allWeeksData);
    setWeekLabels(
      props.isTopClientsReport
        ? weeks
        : allWeeksData?.["2024"]?.map((w) => w.WeekEndingDate)
    );
  }, [Weeks]);

  const enableTooltip = useSelector(
    (state) => state.companySummaryReducer.enableTooltip
  );
  const showPipData = useSelector(
    (state) => state.companySummaryReducer.showPipSummaryLines
  );

  const chartColors = {
    TopClients: {
      GrossProfit: [
        {
          label: "Prior Year Gross Profit",
          color: "#1875B5",
        },
        { label: "Last Year Gross Profit", color: "#FF7F0E" },
        { label: "This Year Gross Profit", color: "#29A229" },
      ],
      Revenue: [
        {
          label: "Prior Year Revenue",
          color: "#1875B5",
        },
        { label: "Last Year Revenue", color: "#FF7F0E" },
        { label: "This Year Revenue", color: "#29A229" },
      ],
    },
    Revenue: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
      { label: "Actual Goal", color: "#A66999" },
    ],
    GrossProfit: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
      { label: "Actual Goal", color: "#A66999" },
    ],
    NetIncome: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
    ],
    OperatingIncome: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
    ],
  };
  const chartValuesTopClients = {
    GrossProfit: {
      [`${labelFormatter("Prior Year Gross Profit")}`]: [],
      [`${labelFormatter("Last Year Gross Profit")}`]: [],
      [`${labelFormatter("This Year Gross Profit")}`]: [],
    },
    Revenue: {
      [`${labelFormatter("Prior Year Revenue")}`]: [],
      [`${labelFormatter("Last Year Revenue")}`]: [],
      [`${labelFormatter("This Year Revenue")}`]: [],
    },
  };
  const chartValuesTempRevenue = {
    [`${labelFormatter("Previous Year")}`]: [],
    [`${labelFormatter("Last Year")}`]: [],
    [`${labelFormatter("Current Year")}`]: [],
    [`${labelFormatter("Actual Goal")}`]: [],
  };
  const chartValuesTempGP = {
    [`${labelFormatter("Previous Year")}`]: [],
    [`${labelFormatter("Last Year")}`]: [],
    [`${labelFormatter("Current Year")}`]: [],
    [`${labelFormatter("Actual Goal")}`]: [],
  };
  const chartValuesTempNetIncome = {
    [`${labelFormatter("Previous Year")}`]: [],
    [`${labelFormatter("Last Year")}`]: [],
    [`${labelFormatter("Current Year")}`]: [],
  };

  const [colors, setColors] = useState(
    props.isTopClientsReport
      ? chartColors.TopClients[props.dataMode]
      : chartColors[props.dataMode]
  );
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {},
    legend: {
      position: "top",
      show: false,
      horizontalAlign: "right",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: [1, 1, 1, 0],
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });

  const dispatch = useDispatch();
  const openClientsRanking = () => {
    dispatch(setEnableTooltip(true));
    dispatch(setShowClientsRanking(true));
    props.setSelectedBranch(props.location.name);
    props.setLevel(props.location.level);
    props.setTotalType(props.location.type);
  };

  const pipChartColorsLegend = [
    { color: "#609DD4", label: "Plan Revenue" },
    { color: "#0AB156", label: "Actual Revenue PIP" },
    { color: "#FCF30A", label: "Original Goal" },
    { color: "#FB986B", label: "Last Year Revenue " },
    // { color: "#609DD4", label: "% to Plan PIP" },
    // { color: "#FB986B", label: "% to Last Year PIP" },
  ];
  const pipChartColorsLegendWIP = [{ color: "#609DD4", label: "Plan Revenue" }];
  const pipChartColors = [
    { color: "#609DD4", label: "Plan Revenue" },
    // { color: "#0AB156", label: "Actual Revenue PIP" },
    // { color: "#FCF30A", label: "Original Goal" },
    // { color: "#FB986B", label: "Last Year Revenue" },
    { color: "#609DD4", label: "Plan Revenue" },
    // { color: "#0AB156", label: "Actual Revenue PIP" },
    // { color: "#FCF30A", label: "Original Goal" },
    // { color: "#FB986B", label: "Last Year Revenue" },
    { color: "#609DD4", label: "Plan Revenue" },
    // { color: "#0AB156", label: "Actual Revenue PIP" },
    // { color: "#FCF30A", label: "Original Goal" },
    // { color: "#FB986B", label: "Last Year Revenue" },
  ];

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
    });
  }, [weekLabels]);

  useEffect(() => {
    // console.log(props.location.level, props.pipData);
    if (weekLabels?.length > 0)
      if (
        showPipData &&
        props.pipData &&
        props.dataMode === "Revenue" &&
        props.executive
      ) {
        setChartPipDataWip();
      } else {
        if (props.data) {
          setColors(
            props.isTopClientsReport
              ? chartColors.TopClients[props.dataMode]
              : chartColors[props.dataMode]
          );
          setChartData();
        }
      }
  }, [
    props.data,
    props.dataMode,
    enableTooltip,
    props.sameMaxValue,
    showPipData,
    props.pipData,
    weekLabels,
  ]);

  //non-pip
  const setChartData = () => {
    const chartLineColors = props.isTopClientsReport
      ? chartColors.TopClients[props.dataMode]
      : chartColors[props.dataMode];

    const arrByMode = props.isTopClientsReport
      ? chartValuesTopClients[props.dataMode]
      : props.dataMode === "Revenue"
      ? chartValuesTempRevenue
      : props.dataMode === "GrossProfit"
      ? chartValuesTempGP
      : chartValuesTempNetIncome;
    const chartSeries = [
      ...Object.keys(arrByMode).map((v, key) => {
        return {
          name: labelFormatter(v),
          data: [...getWeekData(props.data[v], props.dataMode)],
        };
      }),
    ];
    const maxValue = getMaxRoundedValue(
      Math.max(
        ...Object.values(chartSeries)
          .flatMap(({ data }) => [].concat(...Object.values(data)))
          .filter((d) => d !== null)
      )
    );
    const minValue = getMinRoundedValue(
      Math.min(
        ...Object.values(chartSeries)
          .flatMap(({ data }) => [].concat(...Object.values(data)))
          .filter((d) => d !== null)
      )
    );
    setOptions({
      ...options,
      stroke: {
        width: 1,
        dashArray: [0, 0, 0, 2],
        curve: "straight",
        colors: [...chartLineColors].map((v) => v.color),
      },
      colors: [...chartLineColors].map((v) => v.color),
      markers: {
        size: [1, 1, 1, 0],
        strokeWidth: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      tooltip: {
        enabled: enableTooltip,
        enabledOnSeries: [0, 1, 2, 3],
        followCursor: !!props.isTopClientsReport,
        fixed: {
          enabled: !props.isTopClientsReport,
          position: "topRight",
          offsetX: 0,
          offsetY: -150,
        },
      },
      yaxis:
        props.location.level === "Company"
          ? {
              labels: {
                show: true,
                style: {
                  fontSize: "7px",
                },
                formatter: (v) => formatAmount(v),
              },
            }
          : {
              labels: {
                show: true,
                style: {
                  fontSize: "7px",
                },
                formatter: (v) => formatAmount(v),
              },
              max:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue?.RevSARMaxValue
                    : props.revenue?.RevMaxValue ?? undefined
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue?.GpSARMaxValue
                    : props.revenue?.GpMaxValue ?? undefined
                  : maxValue,
              min:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue?.RevSARMinValue
                    : props.revenue?.RevMinValue ?? 0
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue?.GpSARMinValue
                    : props.revenue?.GpMinValue ?? 0
                  : minValue,
              tickAmount: 5,
            },
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
    });
    setSeries(chartSeries);
  };

  //pip
  const setChartPipDataWip = () => {
    if (props.pipData) {
      let markersData = {},
        pipData = {};
      Object.keys(allWeeks).map((year) => {
        let markers = [];
        let planrevenue = [];
        allWeeks[year]?.map(({ WeekEndingDate, CalendarWeekNumber }) => {
          const v = props.pipData?.find(
            (d) => d.WeekEndingDate === WeekEndingDate.toString()
          );
          if (v) {
            if (v.DotsColor)
              markers.push({ week: CalendarWeekNumber, color: v.DotsColor });
            planrevenue.push(v.PlanRevenue);
          } else {
            planrevenue.push(null);
          }
        });
        markersData = { ...markersData, [year]: markers };
        pipData = {
          ...pipData,
          [year]: {
            planrevenue,
          },
        };
      });
      const arrByMode =
        props.dataMode === "Revenue"
          ? chartValuesTempRevenue
          : props.dataMode === "GrossProfit"
          ? chartValuesTempGP
          : chartValuesTempNetIncome;
      const chartSeries = [
        ...Array.from(
          new Set(
            Object.keys({ ...arrByMode }).map((v) => labelFormatter(v) ?? "")
          )
        ).map((v, key) => {
          return {
            name: v,
            data: [
              ...getWeekData(
                Object.values({ ...arrByMode, ...props.data })[key],
                props.dataMode
              ),
            ],
          };
        }),
      ];
      const seriesData = [
        ...Object.values(pipData).flatMap((data) => {
          return [{ name: "Plan Revenue", data: data.planrevenue }];
        }),
      ];
      const maxValue = getMaxRoundedValue(
        Math.max(
          ...Object.values(chartSeries).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter((d) => d !== null)
          ),
          ...Object.values(seriesData).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter((d) => d !== null)
          )
        )
      );

      const minValue = getMinRoundedValue(
        Math.min(
          ...Object.values(chartSeries).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter((d) => d !== null)
          ),
          ...Object.values(seriesData).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter((d) => d !== null)
          )
        )
      );

      const markerOptions = [];
      Object.values(markersData).map((markers, index) => {
        const weekNumbers = allWeeks[2024].map((w) => w.CalendarWeekNumber);
        markers.map((marker) => {
          markerOptions.push({
            seriesIndex: index,
            dataPointIndex: weekNumbers.indexOf(marker.week),
            fillColor: marker.color,
            strokeColor: marker.color,
            size: 2,
            shape: "circle",
          });
        });
      });

      setOptions({
        ...options,
        tooltip: {
          enabled: enableTooltip,
          enabledOnSeries: [0, 1, 2, 3],
          followCursor: !!props.isTopClientsReport,
          fixed: {
            enabled: !props.isTopClientsReport,
            position: "topRight",
            offsetX: 0,
            offsetY: -150,
          },
        },
        stroke: {
          width: 1,
          dashArray: [0, 0, 0, 2, 0, 0, 0],
          curve: "straight",
          colors: [...chartColors[props.dataMode], ...pipChartColors].map(
            (v) => v.color
          ),
        },
        colors: [...chartColors[props.dataMode], ...pipChartColors].map(
          (v) => v.color
        ),
        yaxis: [
          ...chartSeries.map((s, i) => {
            return {
              floating: i > 0,
              seriesName: s?.name,
              labels: {
                show: i === 0,
                formatter: (v) => formatAmount(v),
                style: {
                  fontSize: "7px",
                },
              },
              max:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMaxValue
                    : props.revenue.RevMaxValue ?? maxValue
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMaxValue
                    : props.revenue?.GpMaxValue ?? maxValue
                  : maxValue,
              min:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMinValue
                    : props.revenue.RevMinValue ?? minValue
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMinValue
                    : props.revenue?.GpMinValue ?? minValue
                  : minValue,
              tickAmount: 5,
            };
          }),
          ...seriesData.map((s) => {
            return {
              floating: true,
              seriesName: s?.name,
              labels: {
                show: false,
                formatter: (v) => formatAmount(v),
              },
              max:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMaxValue
                    : props.revenue.RevMaxValue ?? maxValue
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMaxValue
                    : props.revenue?.GpMaxValue ?? maxValue
                  : maxValue,
              min:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMinValue
                    : props.revenue.RevMinValue ?? minValue
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMinValue
                    : props.revenue?.GpMinValue ?? minValue
                  : minValue,
              tickAmount: 5,
            };
          }),
        ],
        xaxis: {
          categories: weekLabels,
          labels: {
            show: true,
            style: {
              fontSize: "5px",
            },
          },
        },
        markers: {
          discrete: markerOptions,
          hover: {
            size: 2,
            sizeOffset: 2,
          },
        },
      });
      setSeries([...chartSeries, ...seriesData]);
      setColors([...chartColors[props.dataMode], ...pipChartColorsLegendWIP]);
    }
  };

  const [modalOpened, { open, close }] = useDisclosure(false);

  const getPercentChange = () => {
    return props.dataMode === "Revenue"
      ? formatPercChange(props.revenue?.PercChangeRev ?? 0)
      : props.dataMode === "GrossProfit"
      ? formatPercChange(props.revenue?.PercChangeGP ?? 0)
      : props.dataMode === "NetIncome"
      ? formatPercChange(props.revenue?.PercChangeNI ?? 0)
      : formatPercChange(props.revenue?.PercChangeOI ?? 0);
  };

  const getPercentOverlyChange = () => {
    return props.dataMode === "Revenue"
      ? formatPercChange(props.revenue?.peroverlyRev ?? 0)
      : props.dataMode === "GrossProfit"
      ? formatPercChange(props.revenue?.peroverlyGP ?? 0)
      : props.dataMode === "NetIncome"
      ? formatPercChange(props.revenue?.peroverlyNI ?? 0)
      : formatPercChange(props.revenue?.peroverlyOI ?? 0);
  };

  return (
    <div className="company-summary-charts">
      <div className="chart-header">
        <div className="flex align-end w-full">
          <div className="w-third flex flex-col">
            {props.dataMode === "Revenue" &&
              props.executive &&
              showPipData &&
              props.pipMilestonesData && (
                <div className="status-area flex w-full flex-col justify-end text-xs">
                  Current Status:
                  <div
                    className={`w-full border border-slate-400 flex items-center justify-center h-8 text-center ${
                      props.pipMilestonesData?.StatusColor !== "#ffff00"
                        ? "text-white"
                        : ""
                    } font-semibold`}
                    style={{
                      background: props.pipMilestonesData.StatusColor,
                    }}
                  >
                    {props.pipMilestonesData.Milestone}
                  </div>
                </div>
              )}
            {props.revenue && (
              <div className="branch-revenues">
                <div className={"branch-revenue-box revenue"}>
                  {millionConverter(props.revenue?.ProjectedRevenue ?? 0)}
                </div>
                {props.location.type !== "Company" && (
                  <div className={"branch-revenue-box oi"}>
                    {props.revenue["OI%"] ?? 0}%
                  </div>
                )}
                <div className={"branch-revenue-box trailing"}>
                  {millionConverter(
                    props.revenue[
                      `${
                        props.sortValue === "TWGPRank"
                          ? "Trailing4WeeksGP"
                          : "Trailing4WeeksRevenue"
                      }`
                    ] ?? 0
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="w-third flex justify-center">
            <div
              title="Show Top Clients"
              className={`chart-header-title text-center ${
                props.location.name === "Company without PDQ"
                  ? ""
                  : "cursor-pointer"
              }`}
              onClick={
                props.location.name === "Company without PDQ"
                  ? null
                  : openClientsRanking
              }
            >
              {props.name.label}
            </div>
          </div>
          <div className="flex flex-col w-third manager-area-summary items-end ">
            {props.manager?.length > 0 && (
              <div className="">
                Manager: <span className="bold"> {props.manager}</span>
                {props.dataMode === "Revenue" &&
                  props.executive &&
                  showPipData &&
                  props.pipMilestonesData && (
                    <div>
                      Work Time:
                      {convertDaysIntoDuration(
                        props.pipMilestonesData.DaysWorked
                      )}
                    </div>
                  )}
              </div>
            )}
            {(props.regionManagerDays ||
              props.districtManagerDays ||
              props.areaManagerDays) && (
              <div>
                {props.areaManagerDays
                  ? "AM"
                  : props.regionManagerDays
                  ? "RM"
                  : "DM"}{" "}
                Employement Time:{" "}
                <span className="bold">
                  {convertDaysIntoDuration(
                    props.areaManagerDays ||
                      props.regionManagerDays ||
                      props.districtManagerDays
                  )}
                </span>
              </div>
            )}

            <div className="branch-revenues w-full flex justify-end">
              <div
                className="branch-revenue-box flex justify-center ppm"
                title="PPM"
              >
                {props.revenue.PPM?.toFixed(2) ?? 0}
              </div>
              {props.executive && (
                <>
                  <div
                    className="branch-revenue-box flex justify-center percentage"
                    title="Week over week %"
                  >
                    {getPercentChange()}
                  </div>
                  <div
                    className="branch-revenue-box flex justify-center  percentage2"
                    title="% over last year"
                  >
                    {getPercentOverlyChange()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <SummaryChartArea
        colors={colors}
        options={options}
        series={series}
        showPipData={showPipData}
        {...props}
        open={open}
        modalOpened={modalOpened}
        dataMode={props.dataMode}
        revenue={props.revenue}
      />
      <Modal
        opened={modalOpened}
        onClose={close}
        title={props.location?.name}
        centered
        size="100%"
      >
        <div className="h-[82vh] flex items-end w-full">
          <SummaryChartArea
            colors={colors}
            options={options}
            series={series}
            showPipData={showPipData}
            {...props}
            open={open}
            height="700"
            modalOpened={modalOpened}
          />
        </div>
      </Modal>
    </div>
  );
};

export const SummaryChartArea = (props) => {
  const { revenue, dataMode } = props;

  const getPercentChange = () => {
    return dataMode === "Revenue"
      ? formatPercChange(revenue.PercChangeRev)
      : dataMode === "GrossProfit"
      ? formatPercChange(revenue.PercChangeGP)
      : dataMode === "NetIncome"
      ? formatPercChange(revenue.PercChangeNI)
      : formatPercChange(revenue.PercChangeOI);
  };

  return (
    <div className="chart-area w-full bg-white">
      <div className="chart-legend-area">
        <div className="colors-area">
          {props.colors.map((v, key) => (
            <div className="color-box" key={key}>
              <div
                className={
                  dottedLine(v.label)
                    ? `w-4 border-dotted border-y-2 border-x-0 border-[${v.color}]`
                    : "color-circle"
                }
                style={{ backgroundColor: `${v.color}` }}
              ></div>
              <div
                className={
                  props.isTopClientsReport
                    ? "text-xs mx-2 color-name"
                    : "color-name"
                }
              >
                {labelFormatter(v.label)}
              </div>
            </div>
          ))}
        </div>

        {props.executive && (
          <div className="flex gap-2">
            {!props.modalOpened && (
              <div className="cursor-pointer" onClick={props.open}>
                <i className="material-icons" style={{ fontSize: "16px" }}>
                  zoom_in
                </i>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={"h-full flex-grow bg-white"}>
        <ApexChart
          options={props.options}
          series={props.series}
          height={props.height ?? 250}
        />
        {!props.wip && (
          <div
            className={`notes flex flex-col justify-end ${
              // props.dataMode === "Revenue" &&
              props.executive && props.showPipData ? "h-10" : ""
            }`}
          >
            <div className="flex w-full">
              {
                // props.dataMode === "Revenue" &&
                props.executive &&
                  props.showPipData &&
                  props.pipMilestonesData && (
                    <span className="text-[10px] px-2">
                      Company Summary Notes:
                    </span>
                  )
              }
              {props.executive && props.revenue && (
                <CompanySummaryNotes
                  notes={props.revenue?.Notes}
                  noteId={props.revenue?.noteId}
                  location={props.location}
                />
              )}
            </div>
            {
              // props.dataMode === "Revenue" &&
              props.executive &&
                props.showPipData &&
                props.pipMilestonesData && (
                  <div className="flex w-full">
                    <span className="text-[10px] px-2">PIP Summary Notes:</span>
                    <PipSummaryNotes
                      notes={props.pipMilestonesData.Notes}
                      noteId={props.pipMilestonesData.noteId}
                      location={props.location}
                    />
                  </div>
                )
            }
          </div>
        )}
      </div>
    </div>
  );
};

class ApexChart extends Component {
  render() {
    return (
      <>
        <ReactApexChart
          key={!!this.props.options?.markers?.discrete}
          options={this.props.options}
          series={this.props.series}
          type="line"
          height={this.props.height}
        />
      </>
    );
  }
}

export default SummaryChart;
