/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useLocation, useNavigate } from "react-router-dom";
import { COMPANY_MENUS, EXECUTIVE_COMPANY_MENUS } from "./utils/constants";
import "../../../assets/scss/sidebar.scss";

export const CompanySummarySidebar = ({ executive }: any) => {
  const navigate = useNavigate();
  const activeMenuItem = useLocation().pathname as any;
  const menuItems = executive ? EXECUTIVE_COMPANY_MENUS : COMPANY_MENUS;
  const activeMenu = activeMenuItem;
  const openLink = (menu: any) => {
    if (!pageIsActive(menu)) {
      menu.path ? navigate(menu.path) : window.open(menu.link, "_blank");
    }
  };

  const pageIsActive = (menu: any) => {
    return menu.path === activeMenu;
  };
  return (
    <div className="sidebar">
      {menuItems.map((menu, index) => {
        return (
          <div
            className={`sidebar-item ${pageIsActive(menu) ? "active" : ""}`}
            onClick={() => openLink(menu)}
            key={index}
          >
            {menu.label}
          </div>
        );
      })}
    </div>
  );
};
