/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const SET_WEEKLY_REPORT = "SET_WEEKLY_REPORT";
export const SET_CALENDAR_WEEK_DATA = "SET_CALENDAR_WEEK_DATA";
export const SET_CALENDAR_WEEK_LABELS = "SET_CALENDAR_WEEK_LABELS";
export const SET_ALL_CALENDAR_WEEK_LABELS = "SET_ALL_CALENDAR_WEEK_LABELS";
export const SET_SORT_COLUMN = "SET_SORT_COLUMN";
export const SET_WEEK = "SET_WEEK";
export const SET_REGION = "SET_REGION";
export const SET_DISTRICT = "SET_DISTRICT";
export const SET_LOADING = "SET_LOADING";
export const SET_ALERT_DIALOG = "SET_ALERT_DIALOG";
export const SET_HEADERS = "SET_HEADERS";
export const SET_MULTI_PAYMENT_TERMS = "SET_MULTI_PAYMENT_TERMS";
export const SET_REGIONS = "SET_REGIONS";
export const SET_LOCATION_ORDER = "SET_LOCATION_ORDER";
export const SET_USER_HAS_NOTES_ACCESS = "SET_USER_HAS_NOTES_ACCESS";
export const SET_USER_LOCATIONS = "SET_USER_LOCATIONS";
