/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const SET_AR_AS_OF_DATE: string = "SET_AR_AS_OF_DATE";
export const SET_AR_VIEW_TYPE: string = "SET_AR_VIEW_TYPE";
export const SET_CORPORATION: string = "SET_CORPORATION";
export const SET_AR_COLUMNS_DISPLAYED: string = "SET_AR_COLUMNS_DISPLAYED";
export const SET_AR_DATA: string = "SET_AR_DATA";
export const SET_SORT_BY: string = "SET_SORT_BY";
export const SET_AR_COMMENT_BRANCH: string = "SET_AR_COMMENT_BRANCH";
export const SET_AR_COMMENT_DIALOG: string = "SET_AR_COMMENT_DIALOG";
export const SET_AR_EXPORT_DIALOG: string = "SET_AR_EXPORT_DIALOG";
