import { Select } from "@mantine/core";
import TableBarChart from "./TableBarChart";
import { useDisclosure } from "@mantine/hooks";
import { CustomDatePicker } from "../../common/CustomDatePicker";
import { useState } from "react";

const BranchTopFocusTables = (props: any) => {
  const {
    totalClientCommentCounts,
    totalEmployeePlacedCounts,
    selectedDateRange,
    setSelectedDateRange,
    level,
  } = props;
  const [opened, { open, close }] = useDisclosure();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="flex flex-col ">
      <div className="flex justify-end">
        <div className="relative">
          <div className="my-1 ">
            <Select
              id="redeployment-date"
              className="w-[10rem] 2xl:w-[16rem]"
              size="xs"
              data={[]}
              label="Date"
              onClick={() => {
                opened ? close() : open();
                setShowTooltip(false);
              }}
              placeholder={
                selectedDateRange.startdate +
                "  to  " +
                selectedDateRange.enddate
              }
            />
            {/* {selectedDateRange.startdate !== null &&
              selectedDateRange.enddate !== null && (
                <div
                  className=" absolute right-2 top-7 bg-[#fff] rounded-md cursor-pointer"
                  onClick={() =>
                    setSelectedDateRange({
                      startdate: moment(new Date())
                        .add("-29", "days")
                        .format("yyyy-MM-DD"),
                      enddate: moment(new Date()).format("yyyy-MM-DD"),
                    })
                  }
                >
                  <CloseButton />
                </div>
              )} */}
          </div>
          {opened && (
            <div className="absolute z-40 right-0">
              <CustomDatePicker
                branchTopFocus={true}
                close={close}
                selectedDate={selectedDateRange}
                setSelectedDate={setSelectedDateRange}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 lg:flex-row justify-center lg:justify-between items-center flex-1 ">
        <div className="flex flex-col gap-2 justify-center items-center w-full lg:w-[48%]">
          <div className=" bg-[#2c90c3] text-white rounded-lg p-1 px-2 text-sm font-bold">
            Client Comment Metrics
          </div>
          <TableBarChart
            totalCounts={totalClientCommentCounts}
            tableHeader={"Total Number of Clients"}
            selectedDateRange={selectedDateRange}
            showTooltip={showTooltip}
            setShowTooltip={setShowTooltip}
            level={level}
          />
        </div>
        <div className="flex flex-col gap-2 justify-center items-center w-full lg:w-[48%] ">
          <div className=" bg-[#2c90c3] text-white rounded-lg p-1 px-2 text-sm font-bold">
            Employee Placed
          </div>
          <TableBarChart
            tableHeader={"Employee Placed"}
            totalCounts={totalEmployeePlacedCounts}
            selectedDateRange={selectedDateRange}
            level={level}
          />
        </div>
      </div>
    </div>
  );
};

export default BranchTopFocusTables;
