/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Select } from "@mantine/core";
import "../assets/scss/ClientSegmentation.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  APIFetchGpMarginSegments,
  APIFetchMonthlyClientSegmentation,
  APIFetchMonthlyClientSegmentationDetails,
  APIFetchMonthlyGpSegments,
  APIFetchQuarterlyClientSegmentation,
  APIFetchQuarterlyClientSegmentationDetails,
  APIFetchYearlyClientSegmentation,
  APIFetchYearlyClientSegmentationDetails,
  APIGetClientSegmentADABranches,
  APIGetClientSegmentRegions,
} from "../api/client-segmentation";
import {
  formatAmount,
  formatPercentValue,
  formatPercentage,
  sortByKey,
} from "../utils/helper/cellFormatter";
import { APIGetCurrentCalendarDetails } from "../api/filter";
import { groupByKey } from "../utils/group";
import { APIFetchYearlyGpSegments } from "../api/client-segmentation";
import { APIFetchQuarterlyGpSegments } from "../api/client-segmentation";
import { ContentLoader } from "../components/common/Loader";
import HomeButton from "../components/common/HomeButton";

export const ClientSegmentation = (props: any) => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const yearOptions: any = [];
  for (let year = currentYear; year > currentYear - 3; year--) {
    yearOptions.push(year.toString());
  }
  const monthsOptions: any = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const quarterOptions: any = { 1: "Q1", 2: "Q2", 3: "Q3", 4: "Q4" };
  const [selectedArea, setSelectedArea] = useState("All");
  const [selectedSubarea, setSelectedSubarea] = useState("All");
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [selectedDistrict, setSelectedDistrict] = useState("All");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedTimeline, setSelectedTimeline] = useState("Monthly");
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedQuarter, setSelectedQuarter] = useState(1);
  const [gpSegments, setGpSegments] = useState([]);
  const [gpMarginSegments, setGpMarginSegments] = useState([]);
  const [clientSegmentation, setClientSegmentation] = useState([
    { quadrant: "Growth Strategy", id: "growth-strategy", data: [] },
    { quadrant: "Review Value", id: "review-value", data: [] },
    { quadrant: "Protect Strategy", id: "protect-strategy", data: [] },
    { quadrant: "Quality Strategy", id: "quality-strategy", data: [] },
  ]);
  const [areaList, setAreaList] = useState(["All"]);
  const [subareaList, setSubareaList] = useState(["All"]);
  const [regionList, setRegionList] = useState(["All"]);
  const [districtList, setDistrictList] = useState(["All"]);
  const [branchList, setBranchList] = useState(["All"]);
  const [location, setLocation] = useState<any>([]);
  const [defaultLocation, setDefaultLocation] = useState<any>({
    areas: [],
    subareas: [],
    regions: [],
    districts: [],
    branches: [],
  });
  const [heirarchy, setHierarchy] = useState<any>({});
  const [selectedQuadrant, setSelectedQuadrant] = useState<any>("");
  const [selectedMarginSegment, setSelectedMarginSegment] = useState<any>(null);
  const [selectedGpSegment, setSelectedGpSegment] = useState<any>(null);
  let [clientDetailsList, setClientDetailsList] = useState<any>([]);
  const [clientCount, setClientCount] = useState<any>({});
  const [clientSegmentsLoading, setClientSegmentsLoading] = useState(false);
  const [clientDetailsLoading, setClientDetailsLoading] = useState(false);
  const [previousQuarters, setPreviousQuarters] = useState<any>({});
  const [showDistrictSlicer, setShowDistrictSlicer] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState(
    props.adaOnly ? "ADA" : "Company"
  );
  const setAreas = (areas: any) => {
    setAreaList(["All", ...areas]);
  };
  const setSubareas = (subareas: any) => {
    setSubareaList(["All", ...subareas]);
  };
  const setRegions = (regions: any) => {
    setRegionList(["All", ...regions]);
  };
  const setDistricts = (districts: any) => {
    setDistrictList(["All", ...districts]);
  };
  const setBranches = (branches: any) => {
    setBranchList([
      "All",
      ...branches
        .filter((branch: any) => branch !== "null")
        .sort((a: any, b: any) => {
          return a > b ? 1 : a < b ? -1 : 0;
        }),
    ]);
  };
  useEffect(() => {
    APIGetCurrentCalendarDetails().then((res) => {
      setSelectedYear(res.data.CalendarYear.toString());
      setSelectedMonth(res.data.CalendarMonthNumber);
      setSelectedQuarter(res.data.CalendarQuarter);
      setPreviousQuarters({
        q0: res.data.LastQuarterKey,
        q1: res.data.PrevQuarterKey,
        q2: res.data.LastPrevQuarterKey,
      });
    });
    APIFetchGpMarginSegments().then((res) => {
      setGpMarginSegments(res.data);
    });
  }, []);

  useEffect(() => {
    selectedLevel === "ADA"
      ? APIGetClientSegmentADABranches().then((res) => {
          setBranches(res.data.map((d: any) => d.Branch));
        })
      : APIGetClientSegmentRegions().then((res) => {
          setLocation(res.data);
          const data = res.data.map((v: any) => ({
            ...v,
            Areaname:
              v["Areaname"] && v["Areaname"] !== ""
                ? v["Areaname"]
                : "Ungrouped",
            "Sub Area Name":
              v["Sub Area Name"] && v["Sub Area Name"] !== ""
                ? v["Sub Area Name"]
                : "Ungrouped",
            Region:
              v["Region"] && v["Region"] !== "" ? v["Region"] : "Ungrouped",
            District:
              v["District"] && v["District"] !== ""
                ? v["District"]
                : "Ungrouped",
          }));
          defaultLocation.areas = Object.keys(
            groupByKey(data, "Areaname")
          ).filter((v) => v !== "Ungrouped");
          defaultLocation.subareas = Object.keys(
            groupByKey(data, ["Sub Area Name"])
          ).filter((v) => v !== "Ungrouped");
          defaultLocation.regions = Object.keys(
            groupByKey(data, "Region")
          ).filter((v) => v !== "Ungrouped");
          defaultLocation.districts = Object.keys(
            groupByKey(data, "District")
          ).filter((v) => v !== "Ungrouped");
          defaultLocation.branches = Object.keys(groupByKey(data, "Branch"));
          let locationHier: any = {};
          const topHier = groupByKey(data, "Areaname");
          Object.keys(topHier).forEach((v1: any) => {
            let temp = groupByKey(topHier[v1], "Sub Area Name");
            let subHier = {};
            for (let v2 in temp) {
              let reg = groupByKey(temp[v2], "Region");
              let subSubHier = {};
              for (let v3 in reg) {
                subSubHier = {
                  ...subSubHier,
                  [v3]: Object.values(reg[v3]).map((v: any) => v.Branch),
                };
              }
              subHier = {
                ...subHier,
                [v2]: subSubHier,
              };
            }
            locationHier = {
              ...locationHier,
              [v1]: subHier,
            };
          });
          setHierarchy(locationHier);
          const a: any = [],
            s: any = [],
            r: any = [],
            b: any = [];
          Object.keys(heirarchy).map((area: any) => {
            if (area && area !== "Ungrouped") a.push(area);
            Object.keys(heirarchy[area]).map((subarea: any) => {
              if (subarea && subarea !== "Ungrouped") s.push(subarea);
              Object.keys(heirarchy[area][subarea]).map((region: any) => {
                if (region && region !== "Ungrouped") r.push(region);
                heirarchy[area][subarea][region].map((branch: any) => {
                  if (branch) b.push(branch);
                });
              });
            });
          });
          setDefaultLocation(defaultLocation);
          setAreas(defaultLocation.areas);
          setSubareas(defaultLocation.subareas);
          setRegions(defaultLocation.regions);
          setDistricts(defaultLocation.districts);
          setBranches(defaultLocation.branches);
        });
  }, [selectedLevel]);

  useEffect(() => {
    fetchGpSegments();
  }, [selectedTimeline]);

  const fetchGpSegments = () => {
    if (selectedTimeline === "Yearly") {
      APIFetchYearlyGpSegments().then((res) => {
        setGpSegments(res.data);
      });
    } else if (selectedTimeline === "Monthly") {
      APIFetchMonthlyGpSegments().then((res) => {
        setGpSegments(res.data);
      });
    } else {
      APIFetchQuarterlyGpSegments().then((res) => {
        setGpSegments(res.data);
      });
    }
  };

  useEffect(() => {
    fetchClientData();
    if (selectedGpSegment || selectedMarginSegment) {
      resetData();
    } else {
      fetchClientDetails();
    }
  }, [
    selectedTimeline,
    selectedYear,
    selectedMonth,
    selectedQuarter,
    selectedArea,
    selectedSubarea,
    selectedRegion,
    selectedDistrict,
    selectedBranch,
    previousQuarters,
    selectedLevel,
  ]);

  useEffect(() => {
    fetchClientDetails();
  }, [selectedQuadrant, selectedGpSegment, selectedMarginSegment]);

  const getSelectedLocation = () => {
    return selectedBranch === "All"
      ? selectedDistrict === "All"
        ? selectedRegion === "All"
          ? selectedSubarea === "All"
            ? selectedArea === "All"
              ? null
              : selectedArea
            : selectedSubarea
          : selectedRegion
        : selectedDistrict
      : selectedBranch;
  };

  const fetchClientData = async () => {
    setClientSegmentsLoading(true);
    const selectedLocation = getSelectedLocation();
    let res: any = {};
    if (selectedTimeline === "Yearly") {
      res = await APIFetchYearlyClientSegmentation(
        selectedYear,
        selectedLocation,
        selectedLevel
      );
    } else if (selectedTimeline === "Monthly") {
      res = await APIFetchMonthlyClientSegmentation(
        selectedYear,
        selectedMonth,
        selectedLocation,
        selectedLevel
      );
    } else {
      res = await APIFetchQuarterlyClientSegmentation(
        selectedYear,
        selectedQuarter,
        selectedLocation,
        selectedLevel
      );
    }
    clientSegmentation[0].data = sortByKey(
      res.data.filter(
        (c: any) => c.GPSegmentID >= 3 && c.GPMarginSegmentID <= 2
      ),
      { type: "GPSegmentID", isReverse: true }
    );
    clientSegmentation[1].data = sortByKey(
      res.data.filter(
        (c: any) => c.GPSegmentID >= 3 && c.GPMarginSegmentID >= 3
      ),
      { type: "GPSegmentID", isReverse: true }
    );
    clientSegmentation[2].data = sortByKey(
      res.data.filter(
        (c: any) => c.GPSegmentID <= 2 && c.GPMarginSegmentID <= 2
      ),
      { type: "GPSegmentID", isReverse: true }
    );
    clientSegmentation[3].data = sortByKey(
      res.data.filter(
        (c: any) => c.GPSegmentID <= 2 && c.GPMarginSegmentID >= 3
      ),
      { type: "GPSegmentID", isReverse: true }
    );
    setClientSegmentation([...clientSegmentation]);
    const totalCount = res.data.reduce(
      (total: any, currentValue: any) => {
        total["all"] += currentValue.CustomerNumber;
        if (currentValue.GPMarginSegmentID === 1)
          total["margin"]["1"] += currentValue.CustomerNumber;
        if (currentValue.GPMarginSegmentID === 2)
          total["margin"]["2"] += currentValue.CustomerNumber;
        if (currentValue.GPMarginSegmentID === 3)
          total["margin"]["3"] += currentValue.CustomerNumber;
        if (currentValue.GPMarginSegmentID === 4)
          total["margin"]["4"] += currentValue.CustomerNumber;
        if (currentValue.GPSegmentID === 1)
          total["gp"]["1"] += currentValue.CustomerNumber;
        if (currentValue.GPSegmentID === 2)
          total["gp"]["2"] += currentValue.CustomerNumber;
        if (currentValue.GPSegmentID === 3)
          total["gp"]["3"] += currentValue.CustomerNumber;
        if (currentValue.GPSegmentID === 4)
          total["gp"]["4"] += currentValue.CustomerNumber;
        return total;
      },
      {
        all: 0,
        margin: { 1: 0, 2: 0, 3: 0, 4: 0 },
        gp: { 1: 0, 2: 0, 3: 0, 4: 0 },
      }
    );
    setClientCount(totalCount);
    setClientSegmentsLoading(false);
  };

  const fetchClientDetails = async () => {
    if (previousQuarters.q0 && previousQuarters.q1 && previousQuarters.q2) {
      setClientDetailsLoading(true);
      const selectedLocation = getSelectedLocation();
      let res: any = {};
      if (selectedTimeline === "Yearly") {
        res = await APIFetchYearlyClientSegmentationDetails(
          selectedYear,
          selectedLocation,
          selectedGpSegment,
          selectedMarginSegment,
          previousQuarters,
          selectedLevel
        );
      } else if (selectedTimeline === "Monthly") {
        res = await APIFetchMonthlyClientSegmentationDetails(
          selectedYear,
          selectedMonth,
          selectedLocation,
          selectedGpSegment,
          selectedMarginSegment,
          previousQuarters,
          selectedLevel
        );
      } else {
        res = await APIFetchQuarterlyClientSegmentationDetails(
          selectedYear,
          selectedQuarter,
          selectedLocation,
          selectedGpSegment,
          selectedMarginSegment,
          previousQuarters,
          selectedLevel
        );
      }
      clientDetailsList = res.data;
      setClientDetailsList(clientDetailsList);
      setClientDetailsLoading(false);
    }
  };

  const changeMonth = (value: any) => {
    const selected: any = Object.keys(monthsOptions).find(
      (a) => monthsOptions[a] === value
    );
    setSelectedMonth(selected);
  };

  const changeQuarter = (value: any) => {
    const selected: any = Object.keys(quarterOptions).find(
      (a) => quarterOptions[a] === value
    );
    setSelectedQuarter(selected);
  };

  const changeAreaSelection = (value: any) => {
    setSelectedArea(value);
    setSelectedSubarea("All");
    setSelectedRegion("All");
    setSelectedDistrict("All");
    setSelectedBranch("All");
    if (value === "All") {
      setSubareas(defaultLocation.subareas);
      setRegions(defaultLocation.regions);
      setDistricts(defaultLocation.districts);
      setBranches(defaultLocation.branches);
    } else {
      const subareas: any = [];
      const regions: any = [];
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l.Areaname === value) {
          if (!subareas.includes(l["Sub Area Name"]))
            subareas.push(l["Sub Area Name"]);
          if (!regions.includes(l.Region)) regions.push(l.Region);
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setSubareas(subareas.filter((d: any) => !!d));
      setRegions(regions.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeSubareaSelection = (value: any) => {
    setSelectedSubarea(value);
    setSelectedRegion("All");
    setSelectedDistrict("All");
    setSelectedBranch("All");
    if (value === "All") {
      // const s: any = [],
      //   r: any = [],
      //   b: any = [];

      // Object.keys(heirarchy[selectedArea]).map((subarea: any) => {
      //   if (subarea && subarea !== "Ungrouped") s.push(subarea);
      //   Object.keys(heirarchy[selectedArea][subarea]).map((region: any) => {
      //     if (region && region !== "Ungrouped") r.push(region);
      //     heirarchy[selectedArea][subarea][region].map((branch: any) => {
      //       if (branch) b.push(branch);
      //     });
      //   });
      // });
      // setSubareas(s);
      // setRegions(r);
      // setBranches(b);
      setSelectedArea("All");
      setRegions(defaultLocation.regions);
      setDistricts(defaultLocation.districts);
      setBranches(defaultLocation.branches);
    } else {
      const selectedLocation = location.find(
        (l: any) => l["Sub Area Name"] === value
      );
      const regions: any = [];
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l["Sub Area Name"] === value) {
          if (!regions.includes(l.Region)) regions.push(l.Region);
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setSelectedArea(selectedLocation?.Areaname ?? "All");
      setRegions(regions.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeRegionSelection = (value: any) => {
    setSelectedRegion(value);
    setSelectedDistrict("All");
    setSelectedBranch("All");
    if (value === "All") {
      setSelectedArea("All");
      setSelectedSubarea("All");
      const r: any = [],
        b: any = [];

      Object.keys(heirarchy[selectedArea][selectedSubarea]).map(
        (region: any) => {
          if (region && region !== "Ungrouped") r.push(region);
          heirarchy[selectedArea][selectedSubarea][region].map(
            (branch: any) => {
              if (branch) b.push(branch);
            }
          );
        }
      );
      setRegions(r);
      setBranches(b);
      setDistricts(defaultLocation.districts);
    } else {
      const selectedLocation = location.find((l: any) => l.Region === value);
      const districts: any = [];
      const branches: any = [];
      location.map((l: any) => {
        if (l.Region === value) {
          if (!districts.includes(l.District)) districts.push(l.District);
          branches.push(l.Branch);
        }
      });
      setSelectedArea(selectedLocation?.Areaname ?? "All");
      setSelectedSubarea(selectedLocation?.["Sub Area Name"] ?? "All");
      setBranches(branches.filter((d: any) => !!d));
      setDistricts(districts.filter((d: any) => !!d));
      districts && districts.filter((d: any) => d !== null).length !== 0
        ? setShowDistrictSlicer(true)
        : setShowDistrictSlicer(false);
    }
  };

  const changeDistrictSelection = (value: any) => {
    setSelectedDistrict(value);
    setSelectedBranch("All");
    if (value === "All") {
      setSelectedArea("All");
      setSelectedSubarea("All");
      setSelectedRegion("All");
      setBranches(defaultLocation.branches);
    } else {
      const selectedLocation = location.find((l: any) => l.District === value);
      const branches: any = [];
      location.map((l: any) => {
        if (l.District === value) {
          branches.push(l.Branch);
        }
      });
      setSelectedArea(selectedLocation?.Areaname ?? "All");
      setSelectedSubarea(selectedLocation["Sub Area Name"] ?? "All");
      setSelectedRegion(selectedLocation?.Region ?? "All");
      setBranches(branches.filter((d: any) => !!d));
    }
  };

  const changeBranchSelection = (value: any) => {
    if (selectedLevel === "Company") {
      if (value !== "All") {
        const selectedLocation = location.find((l: any) => l.Branch === value);
        setSelectedArea(
          selectedLocation?.Areaname && selectedLocation.Areaname !== ""
            ? selectedLocation?.Areaname
            : "All"
        );
        setSelectedSubarea(selectedLocation["Sub Area Name"] ?? "All");
        setSelectedRegion(selectedLocation?.Region ?? "All");
        setSelectedDistrict(selectedLocation?.District ?? "All");
        if (selectedLocation?.District && selectedLocation.District !== null) {
          setSelectedDistrict(selectedLocation?.District);
          setShowDistrictSlicer(true);
        } else if (selectedLocation.District === null) {
          setShowDistrictSlicer(false);
        } else {
          setSelectedDistrict("All");
          setShowDistrictSlicer(true);
        }
      } else {
        setSelectedArea("All");
        setSelectedSubarea("All");
        setSelectedRegion("All");
        setSelectedDistrict("All");
      }
    }

    setSelectedBranch(value);
  };

  const selectClient = (quadrantId: any, data: any) => {
    setSelectedQuadrant(quadrantId);
    setSelectedMarginSegment(data.GPMarginSegmentID);
    setSelectedGpSegment(data.GPSegmentID);
  };

  const getGpMarginSegment = () => {
    const seg: any = gpMarginSegments.find(
      (segment: any) => segment.GPMarginSegmentID === selectedMarginSegment
    );
    return seg ? "Segment " + seg.SegmentNumber : "All";
  };

  const getGpSegment = () => {
    const seg: any = gpSegments.find(
      (segment: any) => segment.GPSegmentID === selectedGpSegment
    );
    return seg ? seg.SegmentName : "All";
  };

  const resetData = () => {
    setSelectedQuadrant("");
    setSelectedMarginSegment(null);
    setSelectedGpSegment(null);
  };

  const resetFilters = () => {
    resetData();
    setSelectedArea("All");
    setSelectedSubarea("All");
    setSelectedRegion("All");
    setSelectedBranch("All");
    setSelectedTimeline("Monthly");
    setSelectedYear("2023");
    setSelectedMonth(1);
    setSelectedQuarter(1);
  };

  return (
    <div className="h-[100vh] relative overflow-y-hidden">
      <HomeButton adaOnly={props.adaOnly} resetFilters={resetFilters} />
      <div className="bg-client px-4 lg:px-10  pb-14 lg:pb-8 h-full">
        <div className="text-center font-semibold text-lg py-1 text-slate-600">
          Client Segmentation Square Grid
        </div>
        {/* <div className=" w-full flex justify-center items-center gap-4 ">
          {companyLevels.map((v: any) => {
            return (
              <div
                className={` rounded-lg p-2 px-4 text-xs ${
                  props.isAscendAdaAccess ? "cursor-pointer" : "w-[220px] "
                } text-center  shadow-md mb-2 ${
                  selectedLevel === v.level
                    ? "bg-[#3EA333] text-white"
                    : "bg-white hover:bg-[#ebf0f2]"
                }`}
                onClick={() => {
                  setSelectedLevel(v.level);
                  setSelectedBranch("All");
                  setSelectedArea("All");
                  setSelectedSubarea("All");
                  setSelectedRegion("All");
                  setSelectedDistrict("All");
                }}
              >
                {v.name}
              </div>
            );
          })}
        </div> */}
        <div className="h-[96%] bg-client-light flex overflow-auto">
          <div className="h-full segments min-w-[70px]">
            <div className="segment-top relative pt-1">
              {gpMarginSegments &&
                gpMarginSegments.map((segment: any) => {
                  return (
                    <div className="segment">
                      <div>
                        <span>Segment {segment.SegmentNumber}</span>
                        <span>{segment.SegmentDefn}</span>
                        <span>{segment.SegmentName}</span>
                        <span className="text-[10px] mt-1 xl:mt-10 font-semibold">
                          {formatPercentage(
                            (
                              clientCount.margin?.[segment.GPMarginSegmentID] /
                              clientCount.all
                            ).toString()
                          )}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="grid-view w-[55%] min-w-[500px]">
            <div className="filters flex text-xs gap-1">
              {selectedLevel !== "ADA" && (
                <>
                  <div className="border border-slate-400 xl:pt-2 w-[13%] pl-2">
                    <Select
                      size="xs"
                      variant="unstyled"
                      className="text-xs"
                      label={
                        <span className="text-xs font-semibold">Area</span>
                      }
                      data={areaList}
                      value={selectedArea}
                      onChange={changeAreaSelection}
                    />
                  </div>
                  <div className="border border-slate-400 xl:pt-2 w-[13%] pl-2">
                    <Select
                      variant="unstyled"
                      size="xs"
                      className="text-xs"
                      label={
                        <span className="text-xs font-semibold">Subarea</span>
                      }
                      data={subareaList}
                      value={selectedSubarea}
                      onChange={changeSubareaSelection}
                    />
                  </div>
                  <div className="border border-slate-400 xl:pt-2 w-[20%] pl-2">
                    <Select
                      variant="unstyled"
                      size="xs"
                      className="text-xs"
                      label={
                        <span className="text-xs font-semibold">Region</span>
                      }
                      data={regionList}
                      value={selectedRegion}
                      onChange={changeRegionSelection}
                    />
                  </div>
                  {showDistrictSlicer && (
                    <div className="border border-slate-400 xl:pt-2 w-[15%] pl-2">
                      <Select
                        variant="unstyled"
                        size="xs"
                        className="text-xs"
                        label={
                          <span className="text-xs font-semibold">
                            Area Branch
                          </span>
                        }
                        data={districtList}
                        value={selectedDistrict}
                        onChange={changeDistrictSelection}
                      />
                    </div>
                  )}
                </>
              )}

              <div className="border border-slate-400 xl:pt-2 w-[18%] pl-2">
                <Select
                  size="xs"
                  variant="unstyled"
                  className="text-xs"
                  label={<span className="text-xs font-semibold">Branch</span>}
                  data={branchList}
                  value={selectedBranch}
                  onChange={changeBranchSelection}
                />
              </div>
              <div className="border border-slate-400 xl:pt-2 w-[16%] pl-2">
                <Select
                  size="xs"
                  variant="unstyled"
                  className="text-xs"
                  label={
                    <span className="text-xs font-semibold">Timeline</span>
                  }
                  data={["Quarterly", "Monthly"]}
                  value={selectedTimeline}
                  onChange={(value: any) => {
                    setSelectedTimeline(value);
                  }}
                />
              </div>
              <div className="border border-slate-400 xl:pt-2 w-[16%] pl-2">
                <Select
                  size="xs"
                  variant="unstyled"
                  className="text-xs"
                  label={<span className="text-xs font-semibold">Year</span>}
                  data={yearOptions}
                  value={selectedYear}
                  onChange={(value: any) => {
                    setSelectedYear(value);
                  }}
                />
              </div>
              {selectedTimeline === "Monthly" && (
                <div className="border border-slate-400 xl:pt-2 w-[16%] pl-2">
                  <Select
                    size="xs"
                    variant="unstyled"
                    className="text-xs"
                    label={<span className="text-xs font-semibold">Month</span>}
                    data={Object.values(monthsOptions)}
                    value={monthsOptions[selectedMonth]}
                    onChange={changeMonth}
                  />
                </div>
              )}
              {selectedTimeline === "Quarterly" && (
                <div className="border border-slate-400 xl:pt-2 w-[16%] pl-2">
                  <Select
                    size="xs"
                    variant="unstyled"
                    className="text-xs"
                    label={
                      <span className="text-xs font-semibold">Quarter</span>
                    }
                    data={Object.values(quarterOptions)}
                    value={quarterOptions[selectedQuarter]}
                    onChange={changeQuarter}
                  />
                </div>
              )}
            </div>
            <div className="border border-slate-400 h-[80%] p-1">
              <div className="headers flex justify-between h-[8%]">
                <div className="grid-header w-[18%]">Margin Segment</div>
                {gpSegments &&
                  gpSegments.map((segment: any, i) => {
                    return (
                      <div
                        className={`grid-header ${
                          i % 2 === 0 ? "w-[23%]" : "w-[18%]"
                        }`}
                      >
                        {segment.SegmentName}
                      </div>
                    );
                  })}
              </div>
              <div className="flex w-full h-[92%] relative">
                <ContentLoader loading={clientSegmentsLoading} />
                <div className="data-table w-[18%]">
                  <div className="data-title border-bottom border-b-2 border-[#444]">
                    <span className="title font-bold">
                      # of Customers in S4
                    </span>
                    <span className="title">Revenue</span>
                    <span className="title">GrossProfit</span>
                    <span className="title">GP%</span>
                    <span className="title font-bold">
                      # of Customers in S3
                    </span>
                    <span className="title">Revenue</span>
                    <span className="title">GrossProfit</span>
                    <span className="title">GP%</span>
                  </div>
                  <div className="data-title border-top border-t-2 border-[#444]">
                    <span className="title font-bold">
                      # of Customers in S2
                    </span>
                    <span className="title">Revenue</span>
                    <span className="title">GrossProfit</span>
                    <span className="title">GP%</span>
                    <span className="title font-bold">
                      # of Customers in S1
                    </span>
                    <span className="title">Revenue</span>
                    <span className="title">GrossProfit</span>
                    <span className="title">GP%</span>
                  </div>
                </div>
                <div className="data-table w-[82%]">
                  {clientSegmentation &&
                    clientSegmentation.map((client: any) => {
                      return (
                        <div className={`grid-data growth`} id={client.id}>
                          <div className="square-title-conatiner">
                            <div className="square-title">
                              {client.quadrant}
                            </div>
                          </div>
                          <div className="client-data">
                            {client.data &&
                              client.data.map((data: any) => {
                                return (
                                  <div className="data">
                                    <span
                                      className={`font-semibold 1-4 cursor-pointer ${
                                        selectedMarginSegment ===
                                          data.GPMarginSegmentID &&
                                        selectedGpSegment === data.GPSegmentID
                                          ? "text-red"
                                          : "text-[#046CFF]"
                                      }`}
                                      onClick={() => {
                                        selectClient(client.id, data);
                                      }}
                                    >
                                      {data.CustomerNumber}
                                    </span>
                                    <span
                                      className={`${
                                        !selectedQuadrant ||
                                        selectedQuadrant === client.id
                                          ? "opacity-100"
                                          : "opacity-50"
                                      }`}
                                    >
                                      {formatAmount(data.Revenue)}
                                    </span>
                                    <span
                                      className={`${
                                        !selectedQuadrant ||
                                        selectedQuadrant === client.id
                                          ? "opacity-100"
                                          : "opacity-50"
                                      }`}
                                    >
                                      {formatAmount(data.GrossProfit)}
                                    </span>
                                    <span
                                      className={`${
                                        !selectedQuadrant ||
                                        selectedQuadrant === client.id
                                          ? "opacity-100"
                                          : "opacity-50"
                                      }`}
                                    >
                                      {formatPercentValue(
                                        data.GrossProfitPercent
                                      )}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="segments-legend">
                <div className="segments-left">
                  {gpSegments &&
                    gpSegments.map((segment: any) => {
                      return (
                        <div
                          className="segment"
                          style={{ background: segment.SegmentColor }}
                        >
                          <span className="text-bold text-[9px] xl:text-sm">
                            Segment {segment.SegmentNumber}
                          </span>
                          <span className="text-[9px] xl:text-[11px]">
                            {segment.SegmentDefn}
                          </span>
                          <span className="text-[9px] mt-0 2xl:mt-3 font-semibold">
                            {formatPercentage(
                              (
                                clientCount.gp?.[segment.GPSegmentID] /
                                clientCount.all
                              ).toString()
                            )}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="table-data-container w-full min-w-[300px]">
            <div className="header">
              <div className="text-[8px] xl:text-[11px]">
                <span>Selected GP Margin Segment:</span>
                <span className="segment-name xl:text-[16px]">
                  {getGpMarginSegment()}
                </span>
              </div>
              <div className="text-[8px] xl:text-[11px]">
                <span>Selected GP Segment:</span>
                <span className="segment-name xl:text-[16px]">
                  {getGpSegment()}
                </span>
              </div>
            </div>
            <div className="data-table relative">
              <ContentLoader loading={clientDetailsLoading} />
              <table className="w-full">
                <thead className="text-xs">
                  <tr className="bg-[#1AAA9B] w-full h-8">
                    <th className="border-[#31AFA0] border-2 w-[15%]">
                      Branch
                    </th>
                    <th className="border-[#31AFA0] border-2">Client Name</th>
                    <th className="border-[#31AFA0] border-2 w-[15%]">
                      Revenue
                    </th>
                    <th className="border-[#31AFA0] border-2 w-[15%]">
                      Gross Profit
                    </th>
                    <th className="border-[#31AFA0] border-2 w-[10%]">GP%</th>
                  </tr>
                </thead>
                <tbody className="text-[10px]">
                  {clientDetailsList &&
                    clientDetailsList.map((detail: any) => {
                      return (
                        <tr
                          style={
                            detail.color ? { background: detail.color } : {}
                          }
                        >
                          <td>{detail.Branch}</td>
                          <td>{detail.ClientName}</td>
                          <td className="text-right">
                            {formatAmount(detail.Revenue)}
                          </td>
                          <td className="text-right">
                            {formatAmount(detail.GrossProfit)}
                          </td>
                          <td className="text-right">
                            {formatPercentValue(detail.GrossProfitPercentage)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
