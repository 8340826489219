/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useDispatch, useSelector } from "react-redux";
import { setArExportDialog } from "../../../store/ArAging/actions";
import { Loader } from "../../common/Loader";
import { sortByKey } from "../../../utils/helper/cellFormatter";
import { groupByKey } from "../../../utils/group";
import { handleExport } from "../../../utils/helper/dataExport";
import { setLoading } from "../../../store/Filter/actions";
import { filterByActiveHeader, headers } from "./utils/displayColumns";
import { getSummedArData } from "../../../utils/helper/arAgingUtils";

export const ArExportDialog = ({ multiSelect }: any) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state["filterReducer"].loading);
  const arData = useSelector((state: any) => state.arAgingReducer.arData);
  const viewType = useSelector((state: any) => state.arAgingReducer.viewType);
  const sortVal = useSelector((state: any) => state.arAgingReducer.sortBy);

  const closeDialog = () => {
    dispatch(setArExportDialog(false));
  };

  const exportDataDialog = (type: string) => {
    dispatch(setLoading(true));
    const rawGroupedData = Object.values(
      groupByKey(sortByKey(arData, sortVal), "Organization")
    );
    let sortedVal: any = [];
    if (viewType === "Client Summary") {
      const clientSummaryData = rawGroupedData.map((valArr) => {
        const subtotal = filterByActiveHeader(
          viewType,
          [getSummedArData(valArr)],
          multiSelect
        );
        console.log(subtotal);
        return subtotal;
      });
      sortedVal = clientSummaryData.flat();
    } else {
      sortedVal = rawGroupedData.flat();
    }
    const displayHeaders = headers.filter((header) => {
      return isClientSummary(viewType, header.label) === "visible";
    });
    const exportData = sortedVal.map((obj: any) => {
      const filteredObject: any = {};
      displayHeaders.forEach((header) => {
        const propertyValue = obj[header.value];
        if (propertyValue !== undefined) {
          filteredObject[header.value] = propertyValue;
        }
      });
      return filteredObject;
    });
    handleExport(
      displayHeaders.map((h) => h.label),
      filterByActiveHeader(viewType, exportData, multiSelect),
      type,
      viewType
    );
    dispatch(setLoading(false));
  };

  const clientSummaryHidden = [
    "Invoice Number",
    "Invoice Date",
    "Due Date",
    "Invoice Amount",
    "Payment Amount",
  ];

  const isClientSummary = (activeHeader: string, header: string) => {
    if (activeHeader?.trim() === "Client Summary" && header === "Client Name") {
      return "visible";
    } else if (
      activeHeader?.trim() === "Client Summary" &&
      clientSummaryHidden.includes(header)
    ) {
      return "hidden";
    } else {
      return "visible";
    }
  };

  return (
    <section className={"ar-comment-section"}>
      <div className="ar-comment-backdrop" onClick={closeDialog}></div>
      {loading ? (
        <Loader />
      ) : (
        <div className="ar-export-dialog-card">
          <div className="ar-title">
            <span className="branch-name">Export data from the table</span>
          </div>
          <div className="ar-export-dialog-content">
            <button onClick={() => exportDataDialog("csv")}>
              Export data as CSV
            </button>
            <button onClick={() => exportDataDialog("xlsx")}>
              Export data as Xlsx
            </button>
          </div>
        </div>
      )}
    </section>
  );
};
