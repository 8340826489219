/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIGetCalendarWeeks = () => {
  return GetRequest(`filter/weeks`);
};
export const APIGetCalendarWeeksLastYear = () => {
  return GetRequest(`filter/weeks/last-year`);
};
export const APIGetCalendarWeeks3Months = () => {
  return GetRequest(`filter/weeks/3-months`);
};

export const APIGetCalendarWeekDatesByYear = (startyear: any, endyear: any) => {
  return GetRequest("filter/weeks/all", { params: { startyear, endyear } });
};

export const APIGetAllDistinctRegions = () => {
  return GetRequest("filter/regions/distinct");
};
export const APIGetAllDistinctDistricts = () => {
  return GetRequest("filter/districts/distinct");
};

export const APIGetAllRegions = () => {
  return GetRequest("filter/regions");
};

export const APIGetAllRegionsExceptQcsa = () => {
  return GetRequest("filter/regions-without-qcsa");
};

export const APIGetLocationOrder = () => {
  return GetRequest("filter/location-order");
};

export const APIGetCurrentCalendarDetails = () => {
  return GetRequest("filter/calendar-details");
};

export const APIGetCalendarMonths = () => {
  return GetRequest("filter/months");
};

export const APIGetCalendarQuarters = () => {
  return GetRequest("filter/quarters");
};

export const APIGetFinancialLocations = (name: any, level: any, year: any) => {
  return GetRequest(
    `filter/financial-locations/${year}?locationName=${name}&locationLevel=${level}`
  );
};

export const APIGetFinancialLocationsWip = (
  name: any,
  level: any,
  year: any
) => {
  return GetRequest(
    `filter/financial-locations-wip/${year}?locationName=${name}&locationLevel=${level}`
  );
};

// export const APIGetFinancialLocationsADA = (
//   name: any,
//   level: any,
//   year: any
// ) => {
//   return GetRequest(
//     `filter/financial-locations-ada-wip/${year}?locationName=${name}&locationLevel=${level}`
//   );
// };

export const APIGetAllBranches = () => {
  return GetRequest("filter/branches");
};

export const APIGetAllADABranches = () => {
  return GetRequest("filter/ada-branches");
};
