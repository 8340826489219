/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { utils, writeFile } from "xlsx";

export const handleExport = (
  headers: any,
  data: any,
  type: string,
  reportName: string
) => {
  const wb = utils.book_new();
  const ws = utils.json_to_sheet([]);
  utils.sheet_add_aoa(ws, [headers]);
  utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
  utils.book_append_sheet(wb, ws, "Report");

  if (type === "xlsx") {
    writeFile(wb, reportName + ".xlsx");
  } else {
    writeFile(wb, reportName + ".csv");
  }
};
