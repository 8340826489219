/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  GetRequest,
  PostRequest,
  SageGetRequest,
  SagePostRequest,
} from "../plugins/https";

export const APIGetFinancialBranches = () => {
  return SageGetRequest(`company/structure/branches`, {});
};

export const APIGetFinancialYears = () => {
  return GetRequest("/financial-report/years");
};

export const APIGetFinancialData = (branch: string, year: string) => {
  return PostRequest("/financial-report", { branch, year });
};

export const APIRefreshDataFinancialYears = (branch: string, year: string) => {
  return PostRequest("/financial-report/refresh-data", { branch, year });
};

export const APIGetSageFinancialDataPreview = (data: any) => {
  return SagePostRequest("report/preview", {
    year: data.year,
    month: data.month,
  });
};

export const APIPublishSageReport = () => {
  return SagePostRequest("report/publish", {}, {});
};

//financial data new
export const APIGetSageFinancialData = (data: any) => {
  return SageGetRequest(
    `v2/report?branch=${data.branch}&year=${data.year}&month=${data.month}`
  );
};

// financial data old
export const APIGetSageFinancialDataWipTest = (data: any) => {
  return SageGetRequest(
    `report?branch=${data.branch}&year=${data.year}&month=${data.month}`
  );
};
//financial data local url test
export const APIGetSageFinancialDataTest = (data: any) => {
  return GetRequest(
    `http://192.168.31.11:5005/api/v2/report?branch=${data.branch}&year=${data.year}&month=${data.month}`
  );
};

//publish data
export const APIPublishSageReportOption = (
  publish_type: any,
  year: any,
  month?: any,
  wip?: boolean,
  test?: boolean
) => {
  return test
    ? PostRequest(
        "http://192.168.31.11:5005/api/v2/report/publish",
        { publish_type, year, month },
        {}
      )
    : wip
    ? SagePostRequest("beta/report/publish", { publish_type, year, month }, {})
    : SagePostRequest("v2/report/publish", { publish_type, year, month }, {});
};

//account-item old
export const APIFetchItemDetailsWipTest = (
  year: any,
  branch: any,
  item_name: any,
  category: any
) => {
  return SageGetRequest(
    `beta/report/account-item?year=${year}&branch=${branch}&account_item=${encodeURIComponent(
      item_name
    )}&category=${category}`
  );
};

//account-item new
export const APIFetchItemDetails = (
  year: any,
  branch: any,
  item_name: any,
  category: any
) => {
  return SageGetRequest(
    `v2/report/account-item?year=${year}&branch=${branch}&account_item=${encodeURIComponent(
      item_name
    )}&category=${category}`
  );
};
//account-item local url test
export const APIFetchItemDetailsTest = (
  year: any,
  branch: any,
  item_name: any,
  category: any
) => {
  return SageGetRequest(
    `http://192.168.31.11:5005/api/v2/report/account-item?year=${year}&branch=${branch}&account_item=${encodeURIComponent(
      item_name
    )}&category=${category}`
  );
};

//line item old
export const APIFetchItemLedgerDetailsWipTest = (
  year: any,
  month: any,
  branch: any,
  item_name: any,
  account_no: any
) => {
  return SageGetRequest(
    `beta/report/line-item?year=${year}&month=${month}&branch=${branch}&account_item=${encodeURIComponent(
      item_name
    )}&account_no=${account_no}`
  );
};

//line item local url test
export const APIFetchItemLedgerDetailsTest = (
  year: any,
  month: any,
  branch: any,
  item_name: any,
  account_no: any
) => {
  return SageGetRequest(
    `http://192.168.31.11:5005/api/v2/report/line-item?year=${year}&month=${month}&branch=${branch}&account_item=${encodeURIComponent(
      item_name
    )}&account_no=${account_no}`
  );
};

//line item new
export const APIFetchItemLedgerDetails = (
  year: any,
  month: any,
  branch: any,
  item_name: any,
  account_no: any
) => {
  return SageGetRequest(
    `v2/report/line-item?year=${year}&month=${month}&branch=${branch}&account_item=${encodeURIComponent(
      item_name
    )}&account_no=${account_no}`
  );
};

export const APIPublishSageReportOptionWipTest = (
  publish_type: any,
  year: any,
  month?: any
) => {
  return SagePostRequest(
    " v2/report/publish",
    {
      publish_type,
      year,
      month,
    },
    {}
  );
};

export const APIGetSageFinancialDataPost = (data: any) => {
  return SageGetRequest("report/preview?branch=" + data.branch);
};

export const APIGetSageFinancialDataPublish = () => {
  return SagePostRequest("report/publish", {});
};
export const APIGetAllBranches = () => {
  return SageGetRequest(`company/structure`, {});
};

export const APIExportSageData = (type = "excel") => {
  return SageGetRequest("report/export?type=" + type, {});
};

export const APIExportSageWipData = (type = "excel", year: any) => {
  return SageGetRequest("report/export?type=" + type + "&year=" + year, {});
};
export const APIExportSageWipTestData = (type = "excel", year: any) => {
  return SageGetRequest("v2/report/export?type=" + type + "&year=" + year, {});
};
