/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { getWkSeries } from "../../utils/helper/dateTime";
import * as actions from "./actionTypes";

export const initialState = {
  selectedAreaType: "Branch",
  weekEndingDates: [],
  weekSelected: "",
  regionSelected: "All",
  districtSelected: "All",
  loading: false,
  alertDialog: false,
  firstHeaders: [],
  secondHeaders: [],
  thirdHeaders: [],
  allRegions: [],
  hierarchy: [],
  regionsList: [],
  areaList: [],
  subAreaList: [],
  branchesList: [],
  locationOrder: null,
  weekLabels: [getWkSeries()],
  allWeekLabels: [],
  userHasNotesAccess: true,
  userLocations: [],
};

interface IAction {
  type: String;
  payload: any;
}

export const filterReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actions.SET_WEEKLY_REPORT:
      return {
        ...state,
        selectedAreaType: action.payload,
      };
    case actions.SET_CALENDAR_WEEK_DATA:
      return {
        ...state,
        weekEndingDates: action.payload,
      };
    case actions.SET_CALENDAR_WEEK_LABELS:
      return {
        ...state,
        weekLabels: action.payload,
      };
    case actions.SET_ALL_CALENDAR_WEEK_LABELS:
      return {
        ...state,
        allWeekLabels: action.payload,
      };
    case actions.SET_SORT_COLUMN:
      return {
        ...state,
        selectedSort: action.payload,
      };
    case actions.SET_WEEK:
      return {
        ...state,
        weekSelected: action.payload,
      };
    case actions.SET_REGION:
      return {
        ...state,
        regionSelected: action.payload,
      };
    case actions.SET_DISTRICT:
      return {
        ...state,
        districtSelected: action.payload,
      };
    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.SET_ALERT_DIALOG:
      return {
        ...state,
        alertDialog: action.payload,
      };
    case actions.SET_HEADERS:
      return {
        ...state,
        firstHeaders: action.payload.firstHeaders,
        secondHeaders: action.payload.secondHeaders,
        thirdHeaders: action.payload.thirdHeaders,
      };
    case actions.SET_REGIONS:
      return {
        ...state,
        allRegions: action.payload.allRegions,
        hierarchy: action.payload.hierarchy,
        regionsList: action.payload.regionsList,
        districtList: action.payload.districtList,
        areaList: action.payload.areaList,
        subAreaList: action.payload.subAreaList,
        branchesList: action.payload.branchesList,
      };
    case actions.SET_LOCATION_ORDER:
      return {
        ...state,
        locationOrder: action.payload,
      };
    case actions.SET_USER_HAS_NOTES_ACCESS:
      return {
        ...state,
        userHasNotesAccess: action.payload,
      };
    case actions.SET_USER_LOCATIONS:
      return {
        ...state,
        userLocations: action.payload,
      };
    default:
      return state;
  }
};
