/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const getMaxRoundedValue = (value: number) => {
  value += 10000;
  const exp = value.toExponential();
  const [num, exponent] = exp.split("e");
  if (Number(exponent) <= 3) {
    return Number(Math.ceil(parseFloat(num)) + "e" + exponent);
  }
  return Number((parseFloat(num) + 0.1).toFixed(1) + "e" + exponent);
};

export const getMinRoundedValue = (value: number) => {
  value -= 10000;
  const exp = value.toExponential();
  const [num, exponent] = exp.split("e");
  if (Number(exponent) <= 3) {
    return Number(Math.floor(parseFloat(num)) + "e" + exponent);
  }
  return Number((parseFloat(num) - 0.1).toFixed(1) + "e" + exponent);
};
