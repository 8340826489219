/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { useState } from "react";
import moment from "moment";
import { Button } from "@mantine/core";

export const CustomDatePicker = (props: any) => {
  const defaultDates = {
    startDate: new Date(props.selectedDate.startdate),
    endDate: new Date(props.selectedDate.enddate),
    key: "selection",
  };
  const handleSelect = (ranges: any) => {
    setSelectedRange(ranges.selection);
  };
  const [selectionRange, setSelectedRange] = useState(defaultDates);

  const setDates = () => {
    const startdate = moment(selectionRange.startDate).format("yyyy-MM-DD");
    const enddate = moment(selectionRange.endDate).format("yyyy-MM-DD");
    props.setSelectedDate({ startdate, enddate });
    props.close();
  };
  const setDefaultDates = () => {
    setSelectedRange(defaultDates);
    props.close();
  };

  const handlePreset = (preset: any) => {
    let startDate, endDate;

    switch (preset) {
      case "last3Months":
        startDate = moment().subtract(3, "months").startOf("month").toDate();
        endDate = moment().subtract(1, "months").endOf("month").toDate();
        break;
      case "lastYear":
        startDate = moment().subtract(1, "years").startOf("year").toDate();
        endDate = moment().subtract(1, "years").endOf("year").toDate();
        break;
      default:
        startDate = moment().toDate();
        endDate = moment().toDate();
        break;
    }

    setSelectedRange({ startDate, endDate, key: "selection" });
  };

  return (
    <div className={`border flex flex-col bg-white`}>
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
        maxDate={new Date()}
        shownDate={new Date()}
        // staticRanges={[]}
        // inputRanges={[]}
        // months={2}
        direction="horizontal"
        moveRangeOnFirstSelection={false}
        startDatePlaceholder="Start Date"
        endDatePlaceholder="End Date"
        dateDisplayFormat="yyyy-MM-dd"
        editableDateInputs={true}
      />

      <div className="flex  flex-col border-r-[1px] items-start text-xs w-[226px] border-[#eee]">
        <button
          className="py-3  border-y-[1px] w-full text-left px-5 border-[#eee] hover:bg-[#eff2f7]"
          onClick={() => handlePreset("last3Months")}
        >
          Last 3 Months
        </button>
        <button
          className="py-3   w-full text-left px-5 hover:bg-[#eff2f7] "
          onClick={() => handlePreset("lastYear")}
        >
          Last Year
        </button>
      </div>
      <div className="self-end p-3 flex gap-5 border-t-[1px] border-[#eee] w-full justify-end">
        <Button onClick={setDates}>Apply</Button>
        <Button color="gray" onClick={setDefaultDates}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
