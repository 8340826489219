import { Table } from "@mantine/core";
import { useSelector } from "react-redux";

const RedeploymentTable = (props: any) => {
  const {
    sortConfig,
    setSortConfig,
    getIcon,
    sortedData,
    maxEmployeesReAssigned,
    maxEmployeesOffAssignment,
    maxRedeployedPercentage,
    tableData,
    totalData,
    headers,
  } = props;
  const loading = useSelector((state: any) => state.filterReducer.loading);

  return (
    <Table
      fontSize="xs"
      verticalSpacing={1}
      horizontalSpacing={1}
      withColumnBorders
      className="bg-white"
    >
      <thead className="sticky top-0 shadow-sm h-8 z-20 bg-tertiary">
        <tr>
          {headers.map((header: any) => {
            return (
              <th
                key={header.value}
                style={{ color: "#FFFFFF", paddingLeft: "5px" }}
                className="min-w-[120px] md:min-w-[200px]"
              >
                <div className="flex justify-between items-center">
                  {header.label}
                  <i
                    className="material-icons cursor-pointer"
                    style={{ fontSize: "20px" }}
                    onClick={() =>
                      setSortConfig({
                        key: header.label,
                        direction:
                          sortConfig === null || sortConfig.direction === "desc"
                            ? "asc"
                            : "desc",
                      })
                    }
                  >
                    {getIcon(header.label)}
                  </i>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {!loading &&
          (sortedData.length > 0 ? (
            sortedData.map((data: any) => {
              return (
                <tr>
                  <td style={{ paddingLeft: "5px" }}>{data.Branch}</td>
                  <td className="text-center">
                    <div
                      className="bg-[#a8d5e0] relative h-5"
                      style={{
                        width:
                          maxEmployeesOffAssignment === 0
                            ? 0
                            : (data["# of Employees that came off Assignment"] /
                                maxEmployeesOffAssignment) *
                              300,
                      }}
                    >
                      <div className="absolute left-10 text-xs">
                        {data["# of Employees that came off Assignment"] === 0
                          ? "None"
                          : data["# of Employees that came off Assignment"]}
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div
                      className="bg-[#a8d5e0] relative h-5"
                      style={{
                        width:
                          maxEmployeesReAssigned === 0
                            ? 0
                            : (data["# of Employees that were Re-Assigned"] /
                                maxEmployeesReAssigned) *
                              300,
                      }}
                    >
                      <div className="absolute left-10 text-xs">
                        {data["# of Employees that were Re-Assigned"] === 0
                          ? "None"
                          : data["# of Employees that were Re-Assigned"]}
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <div
                      className="bg-[#a8d5e0] relative h-5 -z-1"
                      style={{
                        width:
                          maxRedeployedPercentage === 0
                            ? 0
                            : (data["Redeployed %"] / maxRedeployedPercentage) *
                              100,
                      }}
                    >
                      <div className="absolute left-10 text-xs">
                        {data["Redeployed %"] === null
                          ? "None"
                          : (data["Redeployed %"] === 0
                              ? data["Redeployed %"]
                              : data["Redeployed %"]?.toFixed(2)) + "%"}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <td colSpan={4}>
              <div className="py-40 flex items-center justify-center text-sm">
                No data available
              </div>
            </td>
          ))}
      </tbody>
      {!loading && tableData.length > 0 ? (
        <tfoot className="sticky h-8 bottom-0 bg-stone-100">
          <tr>
            <th>Total</th>
            <th style={{ textAlign: "center" }}>{totalData.AssignedTotal}</th>
            <th style={{ textAlign: "center" }}>{totalData.ReassignedTotal}</th>
            <th style={{ textAlign: "center" }}>-</th>
          </tr>
        </tfoot>
      ) : (
        <></>
      )}
    </Table>
  );
};

export default RedeploymentTable;
