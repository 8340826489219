/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";

import { useEffect, useState } from "react";

export const graphConfig = {
  // graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  graphMeEndpoint: "https://graph.microsoft.com/beta/me",
  graphGroupEndpoint:
    "https://graph.microsoft.com/v1.0/me/memberOf/$/microsoft.graph.group?$select=id",
  customSecurityAttributes: "https://graph.microsoft.com/beta/users/",
};

export async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphGroupEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function callCustomSecurityAttributes(
  accessToken: string,
  id: string
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    graphConfig.customSecurityAttributes +
      id +
      "?$select=customSecurityAttributes",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export const Profile = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState({} as any);
  const [token, setToken] = useState("");
  const name = accounts[0] && accounts[0].name;

  useEffect(() => {
    callCustomSecurityAttributes(token, graphData.id).then((response) =>
      console.log(response)
    );
  }, [graphData]);
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callMsGraph(response.accessToken).then((response2) => {
          setToken(response.accessToken);
          setGraphData(response2);
        });
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          callMsGraph(response.accessToken).then((response) =>
            setGraphData(response)
          );
        });
      });
  }

  return (
    // <button onClick={RequestProfileData}> Click me</button>
    <iframe
      width="800"
      height="600"
      // frameborder="0"
      allow="clipboard-write;camera;geolocation;fullscreen"
      src="https://gritfeatsolutions.budibase.app/embed/test"
    ></iframe>
  );
};
