/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";

function handleLogin(instance: any) {
  // Sign in using redirects
  instance
    .loginRedirect(loginRequest)
    .then((res: any) => {
      console.log({ res: res });
    })
    .catch((e: any) => {
      console.error(e);
    });
  // instance.loginPopup(loginRequest).catch((e:any) => {
  //     console.error(e);
  // });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  useEffect(() => {
    try {
      handleLogin(instance);
    } catch (e) {
      console.log(e);
    }
  });
  return <div>LOADING...</div>;
};
